import React from "react";
import {AlternativeCorrect} from "./AlternativeCorrect";
import {AlternativeWrong} from "./AlternativeWrong";
import {AlternativeNeutral} from "./AlternativeNeutral";
import {AlternativeReveal} from "./AlternativeReveal";


export const Alternative = ({
    alternative,
    selectedAnswer,
    onAnswerSelect,
    text,
    solved,
    whiteBorder=false
}) => {
    const isSelectedAnswer = selectedAnswer === alternative;

    const handleClick = () => onAnswerSelect(alternative)


    if (solved) {
        if (isSelectedAnswer) {
            return (
                <AlternativeCorrect
                    alternative={alternative}
                    onClick={handleClick}
                    whiteBorder={whiteBorder}
                >
                    {text}
                </AlternativeCorrect>
            )
        } else {
            return (
                <AlternativeReveal
                    alternative={alternative}
                    onClick={handleClick}
                    whiteBorder={whiteBorder}
                >
                    {text}
                </AlternativeReveal>
            )
        }
    } else {
        if (isSelectedAnswer) {
            return (
                <AlternativeWrong
                    alternative={alternative}
                    onClick={handleClick}
                    whiteBorder={whiteBorder}
                >
                    {text}
                </AlternativeWrong>
            )
        } else {
            return (
                <AlternativeNeutral
                    alternative={alternative}
                    onClick={handleClick}
                    disabled={selectedAnswer !== null}
                    whiteBorder={whiteBorder}
                >
                    {text}
                </AlternativeNeutral>
            )
        }
    }
}