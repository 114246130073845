import React, {useCallback, useRef} from "react";
import {createUseStyles} from "react-jss";
import {FocusPicker} from "image-focus";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {debounce, IconButton} from "@mui/material";
import {useClickOutside} from "./useClickOutside";
import {Close} from "@mui/icons-material";

const useStyles = createUseStyles(({ colors }) => ({
  imageFocusPicker: {
    top: 0,
    left: 0,
    display: "flex",
    position: "absolute !important",
    flexDirection: "column",
    background: "white",
    padding: "10px",
    zIndex: 1500,
    boxShadow: "-2px 3px 12px 0px rgba(0,0,0,0.75)",
    "& h3": {
      margin: "10px 0",
      color: colors.black,
    },
  },
  imageFocusImage: {},
  imageFocusPickerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const ImageFocusPicker = ({
  imageUrl,
  x = 0,
  y = 0,
  onFocusChanged,
  debounceTime = 200,
  className = "",
  onClose,
}) => {
  const t = useScrollytellTranslation();
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const imageRef = useCallback(
    (node) => {
      if (node !== null) {
        const debouncedOnFocusChanged = debounce(onFocusChanged, debounceTime);
        new FocusPicker(node, {
          onChange: debouncedOnFocusChanged,
          focus: { x, y },
        });
      }
    },
    [x, y]
  );
  useClickOutside(wrapperRef, () => onClose());

  return (
    <div
      className={`${classes.imageFocusPicker} ${className}`}
      ref={wrapperRef}
    >
      <div className={classes.imageFocusPickerHeader}>
        <h3>{t("imageFocusPickerTitle", "Sett bildefokus")}</h3>
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </div>
      <div>
        <img
          alt="Focus picker"
          className={classes.imageFocusImage}
          ref={imageRef}
          src={`https://ems.dimu.org/image/${imageUrl}`}
        />
      </div>
    </div>
  );
};
