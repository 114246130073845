import {createUseStyles} from "react-jss";
import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {ColorLensOutlined, RoundedCornerOutlined, SquareOutlined, WidthNormal, Layers} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React, {useState} from "react";
import {useScrollyTellProps, useScrollytellTranslation} from "../Admin/scrollytellContext";
import {ColorPicker} from "../common/colorPicker/ColorPicker";
import {WidthSelector} from "./WidthSelector";
import {BackdropSettings} from "./BackdropSettings";

const useStyles = createUseStyles(({ colors }) => ({
    subContainerPanel: {
        position: "absolute",
        top: "4px",
        left: "4px",
        backgroundColor: colors.adminBlue,
        color: colors.white,
        borderRadius: "2px",
        zIndex: 10,
    },
}))
export const SubContainerPanel = ({backdrop, maxWidth, onChange, show, roundedCorners=true, backgroundColor="#FFFFFF"}) => {
    const classes = useStyles()
    const t = useScrollytellTranslation()
    const [showColorPicker, setShowColorPicker] = useState(false)
    const {story} = useScrollyTellProps()

    const handleChangeBackgroundColor = color => onChange('backgroundColor', color)

    const handleToggleBorderRadius = () => onChange('roundedCorners', !roundedCorners)

    const handleChangeMaxWidth = newWidth => onChange('maxWidth', newWidth)

    const handleChangeBackdrop = newBackdrop => onChange('backdrop', newBackdrop)

    if(show) {
        return (
            <div
                className={classes.subContainerPanel}
            >
                <AdminIconBar>
                    <AdminIconBarItem
                        Icon={ColorLensOutlined}
                        onClick={() => setShowColorPicker(true)}
                        tooltip={t(
                            "editContainerPanel.setBackgroundColorTooltip",
                            "Bakgrunnsfarge"
                        )}
                    />
                    <AdminIconBarItem
                        Icon={roundedCorners ? SquareOutlined : RoundedCornerOutlined}
                        onClick={handleToggleBorderRadius}
                        tooltip={t(
                            "editContainerPanel.setBorderRadiusTooltip",
                            "veksle hjørneavrunding"
                        )}
                    />
                    <WidthSelector
                        value={maxWidth}
                        onChange={handleChangeMaxWidth}
                    />
                    <BackdropSettings
                        transparency={backdrop?.transparency}
                        backgroundColor={backdrop?.backgroundColor}
                        onChange={handleChangeBackdrop}
                    />
                </AdminIconBar>
                <ColorPicker
                    open={showColorPicker}
                    color={backgroundColor}
                    onSave={handleChangeBackgroundColor}
                    onClose={() => setShowColorPicker(false)}
                    templateColors={story?.settings?.colors}
                />
            </div>
        )
    } else {
        return null
    }


}