export const LockContainer = ({hasLock, children}) => {
    if(hasLock) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}