import React from "react";
import {createUseStyles} from "react-jss";
import {ModelviewerProvider, WithModelviewerMicroFrontend} from "@ekultur/modelviewer-microfrontend";

const useStyles = createUseStyles({
  container: {},
});


export const WithModelViewer = ({ classname = "", children }) => {
  const classes = useStyles();

  return (
    <ModelviewerProvider>
      <WithModelviewerMicroFrontend>
        <div className={`${classes.container} ${classname}`}>{children}</div>
      </WithModelviewerMicroFrontend>
    </ModelviewerProvider>
  );
};
