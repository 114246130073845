import {BlockSpacer} from "./BlockSpacer";
import {Block} from './Block'
import {AdminSubBlock} from "./AdminSubBlock";

export const SubBlocks = ({blocks, isAdminMode, onUpdate}) => {
    const numberOfBlocks = blocks?.length;
    const sectionId = "subSection"
    const containerId = "subContainer"
    const handleUpdate = (blockId, updatedBlock) => {
        onUpdate(
            blocks.map((block, idx) => {
                if (idx !== blockId) {
                    return block
                }
                if (updatedBlock === undefined) {
                    return {}
                }
                return { ...block, ...updatedBlock };
            })
        )
    }

    const handleRemove = blockId => {
        onUpdate(blocks.filter((_block, i) => i !== blockId))
    }

    const handleAdd = copyBlock => {
        onUpdate([...blocks, copyBlock])
    }

    return blocks?.map((block, i) => (
            <BlockSpacer
                key={`blockspacer-${i}`}
                first={0===i}
                last={1 + i === numberOfBlocks}
            >
                <AdminSubBlock
                    block={block}
                    blockId={i}
                    sectionId={sectionId}
                    containerId={containerId}
                    isAdminMode={isAdminMode}
                    onRemove={handleRemove}
                    onAdd={handleAdd}
                    onUpdate={handleUpdate}
                >
                    <Block
                        block={block}
                        blockId={i}
                        sectionId={sectionId}
                        containerId={containerId}
                    />
                </AdminSubBlock>
            </BlockSpacer>
        )
    )
}