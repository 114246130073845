import React from "react";
import {createUseStyles} from "react-jss";
import {getBackgroundPositionByFocusPoint} from "../helpers/helpers";
import {BackgroundImage} from "./BackgroundImage";

const useStyles = createUseStyles({
    singleBackground: {
        position: "sticky",
        top: 0,
        // backgroundImage: ({ backgroundImage, gradient, isAdminMode }) =>
        //     getBackgroundImageString(backgroundImage, gradient, isAdminMode),
        backgroundColor: ({ backgroundColor }) =>
            backgroundColor ? backgroundColor : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: ({ minHeight }) => minHeight,
        width: "100%",
        height: ({ height }) => height,
        backgroundPositionX: ({backgroundPositionX}) => backgroundPositionX + "%",
        backgroundPositionY: ({backgroundPositionY}) => backgroundPositionY + "%",
    },
});

export const SingleBackground = ({backgroundImage, gradient, isAdminMode, backgroundColor, height, minHeight, backgroundFocus}) => {
    const {
        backgroundPositionX,
        backgroundPositionY
    } = getBackgroundPositionByFocusPoint(backgroundFocus?.x, backgroundFocus?.y)
    const classes = useStyles({
        gradient,
        backgroundColor,
        backgroundImage: backgroundImage,
        height,
        isAdminMode,
        minHeight,
        backgroundPositionX,
        backgroundPositionY
    });

    return (
        <div
            className={classes.singleBackground}
        >
            <BackgroundImage
                backgroundImage={backgroundImage}
                positionX={backgroundPositionX}
                positionY={backgroundPositionY}
                gradient={gradient}
            />
        </div>
    )
}