import React from "react";
import {theme, Typography} from "@ekultur/vm";
import {createUseStyles} from "react-jss";
import {Camera} from "lucide-react";
import {HorizontalAlign} from "../helpers/enums";
import {useContrastApproved} from "../helpers/useContrast";
import {ResponsiveImage} from "../image/ResponsiveImage";
import {useSection} from "../Section/useSection";
import {useContainer} from "../Container/useContainer";
import {useIsActiveBlock} from "../Block/useIsActiveBlock";

const useStyles = createUseStyles(({colors}) => ({
    figure: {
        display: "inline-flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundColor: ({backgroundColor}) => backgroundColor,
        margin: ({margin}) => margin || 0,
        padding: ({padding}) => padding || 0,
        "&.active": {},
    },
    caption: {
        justifyContent: ({textAlign}) => {
            if (textAlign === HorizontalAlign.RIGHT) {
                return "flex-end";
            } else if (textAlign === HorizontalAlign.CENTER) {
                return "center";
            } else {
                return "flex-start";
            }
        },
        color: ({contrastApproved}) => contrastApproved ? colors.white : colors.black,
        display: "flex",
        marginTop: "17px",
    },
    captionIcon: {
        width: "12px",
        margin: "0 14px",
    },
    image: {
        height: "100%",
        width: "100%",
        objectFit: "cover",
    },
}));

export const ImageBlock = ({sectionId, containerId, blockId, block, className = ""}) => {
    const {
        margin,
        padding,
        imagePath,
        altText,
        caption,
        showCaptionIcon,
        textAlign,
        size,
    } = block;

    const section = useSection(sectionId)
    const container = useContainer({
        sectionId: sectionId,
        containerId: containerId
    })
    const containerColor = container?.backgroundColor
    const sectionColor = section?.backgroundColor
    const isActive = useIsActiveBlock({
        sectionId: sectionId,
        containerId: containerId,
        blockId: blockId
    })

    const contrastApproved = useContrastApproved(
        containerColor !== undefined
            ? containerColor.substring(0, 7)
            : sectionColor !== undefined
                ? sectionColor.substring(0, 7)
                : theme.colors.white,
        theme.colors.white
    )

    const classes = useStyles({
        contrastApproved,
        margin,
        padding,
        textAlign,
    });

    return (
        <figure
            className={`${classes.figure} ${isActive ? "active" : ""} ${className}`}
        >
            {imagePath && (
                <ResponsiveImage
                    mediaId={imagePath}
                    alt={altText}
                    className={classes.image}
                />
            )}
            {caption && (
                <Typography
                    tag="figcaption"
                    size={size || "base"}
                    className={classes.caption}
                >
                    {showCaptionIcon && <Camera className={classes.captionIcon}/>}
                    {caption}
                </Typography>
            )}
        </figure>
    )
}
