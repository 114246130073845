import {SpeedDialAction} from "@mui/material";
import React from "react";

export const AddBlockAction = ({blockType, Icon, tooltipTitle, onClick, ...rest}) => {
    const handleClick = () => {
        onClick(blockType)
    }
    return (
        <SpeedDialAction
            icon={Icon}
            tooltipTitle={tooltipTitle}
            onClick={handleClick}
            {...rest}
        />
    )
}