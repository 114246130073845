import {Fragment} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    expanded: {
        background: "rgba(0, 0, 0, 0.75)",
        height: "100vh",
        width: "100vw",
        zIndex: 1100,
        position: "absolute",
        top: 0,
        left: 0,
        transition: "opacity 0.1s ease-in-out, visibility 0.1s ease-in-out",
        visibility: ({expanded}) => expanded ? "visible" : "hidden",
        opacity: ({expanded}) => expanded ? 1 : 0,
    },
    nav: ({expanded}) => ({
        position: "absolute",
        left: "2%",
        top: "50%",
        zIndex: 1100,
        background: expanded ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.75)",
        transition: expanded ? "" : "background-color 0.3s ease-in-out 0.2s",
        borderRadius: "999px",
        padding: "16px 0px",
        transform: "translate(0, -50%)",
        '&:hover': {
            cursor: "pointer"
        }
    })
})
export const ExpandableMenu = ({expanded, onOpen, onClose, children}) => {
    const classes = useStyles({
        expanded: expanded
    })

    return (
        <>
            <div className={classes.expanded}></div>
            <div
                className={classes.nav}
                onMouseEnter={onOpen}
                onPointerLeave={onClose}
            >
                <div>
                    {children}
                </div>
            </div>
        </>
    )
}