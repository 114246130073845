import React, {useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {Box, SpeedDial, SpeedDialIcon,} from "@mui/material";
import {AddBlockAction} from "./AddBlockAction";
import {useBlockOptions} from "./useBlockOptions";
import {getEmptyBlockByType} from "./helpers";

const useStyles = createUseStyles(({colors}) => ({
    addBlockPanel: {
        position: "absolute",
        bottom: "4px",
        right: "4px",
        backgroundColor: colors.adminBlue,
        color: colors.white,
        borderRadius: "2px",
        zIndex: 10,
    },
}));

export const AddBlockPanel = ({onAdd, show=true, tag="basic"}) => {
    const [open, setOpen] = useState(false)
    const fabRef = useRef(null);

    const classes = useStyles()

    const options = useBlockOptions()
        .filter(option => option.tags?.includes(tag))


    const handleOpen = (_event, reason) => {
        if("toggle" === reason) {
            setOpen(true)
        }
    }

    const handleClick = () => {
        if(open) {
            setOpen(false)
        }
    }

    let fabSx = {
        position: "absolute",
        bottom: 0,
        right: 0,
        borderRadius: "999px",
        padding: 1,
        transition: "background-color 0.5s ease",
        backgroundColor: "rgba(0,0,0,0)",
    }

    if(open) {
        fabSx = {
            ...fabSx,
            backgroundColor: "rgba(0,0,0,0.35)",
        }
    }

    const handleAdd = type => {
        onAdd(getEmptyBlockByType(type))
    }

    if(show) {
        return (
            <div className={classes.addBlockPanel}>
                <Box
                    sx={{
                        transform: "translateZ(0px)",
                        flexGrow: 1,
                    }}
                >
                    <SpeedDial
                        onClick={handleClick}
                        open={open}
                        onOpen={handleOpen}
                        ref={fabRef}
                        ariaLabel="Add block"
                        sx={fabSx}
                        direction={"left"}
                        FabProps={{
                            sx: {
                                backgroundColor: "#4484F1"
                            },
                        }}
                        icon={
                            <SpeedDialIcon
                                sx={{
                                    height: 56,
                                    width: 56,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            />
                        }
                    >
                        {options.map(option => (
                            <AddBlockAction
                                key={option.type}
                                blockType={option.type}
                                icon={option.Icon}
                                tooltipTitle={option.tooltip}
                                onClick={handleAdd}
                            />
                        ))}
                    </SpeedDial>
                </Box>
            </div>
        )
    } else {
        return null
    }

}
