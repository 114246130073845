import React from "react";
import { createUseStyles } from "react-jss";
import { Saturation, ColorWrap } from "react-color/lib/components/common";
import { CirclePointer } from "./CirclePointer";
import { ColorControls } from "./ColorControls";
import { ColorFields } from "./ColorFields";
import { TemplateColors } from "./TemplateColors";

const useStyles = createUseStyles({
  customColorPicker: {
    display: "flex",
    flexDirection: "column",
    width: "225px",
    backgroundColor: "white",
  },
  saturationPicker: {
    position: "relative",
    width: "100%",
    paddingBottom: "55%",
  },
});

const CustomColorPicker = ({
  onChange,
  rgb,
  hsl,
  hsv,
  hex,
  renderers,
  templateColors,
    colorFields=true,
    allowAlpha=true
}) => {
  const classes = useStyles({ rgb });

  const renderColorFields = () => {
      if(colorFields) {
          return <ColorFields rgb={rgb} hsl={hsl} hex={hex} onChange={onChange} />
      } else {
          return null
      }
  }

  return (
    <div className={classes.customColorPicker}>
      <div className={classes.saturationPicker}>
        <Saturation
          pointer={CirclePointer}
          hsl={hsl}
          hsv={hsv}
          onChange={onChange}
        />
      </div>
      <TemplateColors templateColors={templateColors} onChange={onChange} />
      <ColorControls
        hsl={hsl}
        rgb={rgb}
        renderers={renderers}
        onChange={onChange}
        allowAlpha={allowAlpha}
      />
        {renderColorFields()}
    </div>
  );
};

export default ColorWrap(CustomColorPicker);
