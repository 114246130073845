import React from "react";
import {createUseStyles} from "react-jss";
import {OneOfMultiBackgrounds} from "./OneOfMultiBackgrounds";

const useStyles = createUseStyles(({ colors }) => ({
    multiBackground: {
        backgroundColor: colors.black,
        position: "sticky",
        top: 0,
        height: ({contentHeight}) => `${contentHeight}px`,
    },
}));

export const MultiBackgrounds = ({backgroundImage, currentStep, gradient, contentHeight}) => {
    const classes = useStyles({
        contentHeight: contentHeight
    })

    return (
        <div className={classes.multiBackground}>
            {backgroundImage?.map((background, i) => (
                <OneOfMultiBackgrounds
                    key={i}
                    gradient={gradient}
                    background={background}
                    active={i === currentStep}
                    opacity={i === 0 && currentStep === null ? 1 : 0}
                    zIndex={100 - i}
                />
            ))}
        </div>
    )
}