import {Ballot} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const StickySectionButton = ({templateId, onClick}) => {
    const t = useScrollytellTranslation();
    const handleClick = () => onClick("stickySectionSidebar")

    if(templateId === 6) {
        return (
            <AdminIconBarItem
                Icon={Ballot}
                onClick={handleClick}
                tooltip={t(
                    "editSectionPanel.stickyStepTooltip",
                    "Legg til steg med bakgrunn"
                )}
                tooltipPosition="top"
            />
        )
    } else {
        return null
    }
}