import React, {useRef} from "react";
import {createUseStyles} from "react-jss";
import {VideoLibrary} from "@mui/icons-material";

import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import {AdminSwitchBarItem} from "../icon-bar/AdminSwitchBarItem";
import {ScrollLockTooltip} from "./ScrollLockTooltip";

const useStyles = createUseStyles({
    subMenu: {
        top: "10px",
        position: "absolute",
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
        backgroundColor: "white",
        background: "#FFFFFF",
        boxShadow:
            "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
    },
    subMenuItem: {
        "& svg": {
            fill: "#00000099",
        },

        "&:hover svg": {
            fill: "#3F51B5",
        },
        "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto",
            "& svg": {
                fill: "#00000033",
            },
        },
    },
});

export const VideoMenu = ({block, onUpdateProperty, handleSetVideo}) => {
    const t = useScrollytellTranslation();
    const videoMenuRef = useRef();
    const classes = useStyles();


    return (
        <AdminIconBar ref={videoMenuRef} className={classes.subMenu}>
            <AdminIconBarItem
                Icon={VideoLibrary}
                onClick={() => handleSetVideo()}
                tooltip={t("VideoBlockSelectVideoTooltip", "Velg video")}
                classname={classes.subMenuItem}
            />
            <AdminSwitchBarItem
                label={t("VideoBlockAutoplayLabel", "Autoplay")}
                checked={block.autoPlay}
                onChange={onUpdateProperty}
                property={"autoPlay"}
                startDivider={true}
            />
            <AdminSwitchBarItem
                label={t("VideoBlockLoopLabel", "Loop")}
                checked={!!block.loop}
                onChange={onUpdateProperty}
                property={"loop"}
                startDivider={true}
            />
            <AdminSwitchBarItem
                label={t("VideoBlockControlsLabel", "Kontrollpanel")}
                checked={block.showControls}
                onChange={onUpdateProperty}
                property={"showControls"}
                startDivider={true}
            />
            <AdminSwitchBarItem
                label={t("VideoBlockScrollLockLabel", "Scroll-lås")}
                checked={block.locked}
                onChange={onUpdateProperty}
                property={"locked"}
                startDivider={true}
                Tooltip={<ScrollLockTooltip />}
            />
        </AdminIconBar>
    );
};
