import React from "react";

const markerStyles = {
    position: "fixed",
    left: 0,
    width: "100%",
    height: 0,
    borderTop: "2px dashed black",
    zIndex: 9999,
};
const offsetTextStyles = {
    fontSize: "12px",
    fontFamily: "monospace",
    margin: 0,
    padding: 6,
};

export const Debugger = ({ offset }) => (
    <div style={{ ...markerStyles, top: `${offset * 100}%` }}>
        <p style={offsetTextStyles}>trigger: {offset}</p>
    </div>
);
