import React from "react";

import {App} from "./App/App";
import {Demo} from "./Demo/Demo";
import reportWebVitals from "./reportWebVitals";
import "./App/i18n";
import {createRoot} from "react-dom/client";
import {ProgressProvider} from "./progress/progressContext";

const roots  = {}

window.kitScrollytell = {
  renderDemo: (args) => {
    const container = document.getElementById(args.containerId)
    if(!roots[args.containerId]) {
      roots[args.containerId] = createRoot(container)
    }
    roots[args.containerId].render(
        <Demo {...args} />
    )
  },
  renderScrollytell: (args) => {
    const container = document.getElementById(args.containerId)
    if(!roots[args.containerId]) {
      roots[args.containerId] = createRoot(container)
    }
    roots[args.containerId].render(
        <ProgressProvider>
          <App {...args} />
        </ProgressProvider>
    )

  },

  unmountScrollytell: (containerId) => {
    if(roots[containerId]) {
      roots[containerId].unmount()
      roots[containerId] = null
    }
  },
};

reportWebVitals();
