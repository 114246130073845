import {Dialog, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import React, {useState} from "react";

export const CodewordDialog = ({open, onClose, secret=""}) => {
    const [secretValue, setSecretValue] = useState(secret)

    const handleChangeSecretValue = event => setSecretValue(event.target.value)

    const handleClose = () => {
        onClose(secretValue)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>
                Kodeord
            </DialogTitle>
            <DialogContent>
                <Stack
                    spacing={2}
                    sx={{
                        pt: 2,
                        width: "100%"
                    }}
                >
                    <TextField
                        label={"Kodeord for å scrolle videre"}
                        value={secretValue}
                        onChange={handleChangeSecretValue}
                        autoFocus={true}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}