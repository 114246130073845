import {Box, Button, IconButton, Popover, Stack, Typography} from "@mui/material";
import React from "react";
import {ReadMoreTextField} from "./ReadMoreTextField";
import {ReadMoreIconSelect} from "./ReadMoreIconSelect";
import {ReadMoreColorField} from "./ReadMoreColorField";
import {Close, Edit} from "@mui/icons-material";
import {ReadMoreTypeRadio} from "./ReadMoreTypeRadio";
import {CONTENT_MODAL_OPENED, POPOVER_CLOSED, useBlockDispatch, useBlockState} from "./blockContext";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const ReadMoreSettings = ({onUpdateProperty, onUpdate, blockState}) => {
    const t = useScrollytellTranslation()
    const {anchorEl} = useBlockState()
    const blockDispatch = useBlockDispatch()

    const {
        contentType,
        buttonIcon,
        buttonText,
        buttonColor,
        url
    } = blockState
    const handleClose = () => {
        onUpdate()
        blockDispatch({type: POPOVER_CLOSED})
    }

    const handleStartEditing = () => {
        handleClose()
        blockDispatch({type: CONTENT_MODAL_OPENED})
    }

    return (
        <Popover
            id={"read-more-button-popover"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
        >
            <Box
                sx={{
                    m: 2
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h6"}
                    >
                        Knapp
                    </Typography>
                    <IconButton
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <Stack
                    spacing={2}
                    alignItems={"flex-start"}
                    sx={{
                        pt: 2,
                        width: "100%"
                    }}
                >
                    <ReadMoreIconSelect
                        label={t('readMore.buttonIconLabel', "Ikon")}
                        property={"icon"}
                        value={buttonIcon}
                        onChange={onUpdateProperty}
                    />
                    <ReadMoreTextField
                        label={t("readMore.buttonTextLabel", "Tekst i knapp")}
                        property={"buttonText"}
                        value={buttonText}
                        autoFocus={true}
                        onChange={onUpdateProperty}
                    />
                    <ReadMoreColorField
                        label={t("readMore.buttonColorLabel", "Knappens bakgrunnsfarge")}
                        property={"buttonColor"}
                        value={buttonColor}
                        autoFocus={false}
                        onChange={onUpdateProperty}
                    />
                    <ReadMoreTypeRadio
                        label={t('readMore.typeLabel', "Knappen skal åpne")}
                        property={"contentType"}
                        value={contentType}
                        autoFocus={false}
                        onChange={onUpdateProperty}
                    />
                    <ReadMoreTextField
                        label={t("readMore.urlLabel", "Ekstern adresse")}
                        property={"url"}
                        value={url}
                        autoFocus={true}
                        onChange={onUpdateProperty}
                        disabled={"dialog" === contentType}
                        show={"link" === contentType}
                    />
                    <Button
                        startIcon={<Edit />}
                        onClick={handleStartEditing}
                        disabled={"link" === contentType}
                        variant={"contained"}
                    >
                        {t('readMore.editPopoverLabel', "Rediger popover-innhold")}
                    </Button>
                </Stack>
            </Box>
        </Popover>
    )
}