import {
  SectionGridSize,
  SectionTemplates,
  SectionTypes,
} from "../helpers/enums";

export const sectionTemplates = [
  {
    templateId: 1,
    title: "Tittel seksjon",
    preview: "013AjtNohUsL",
    type: SectionTemplates.TITLE_SECTION,
    section: {
      templateId: 1,
      type: "content",
      gradient: {
        direction: "to bottom",
        fromPercent: 0,
        toPercent: 100,
        fromColor: "#00000080",
        toColor: "#000000b3",
      },
      backgroundImage: "013AjtbpHVyf",
      verticalAlign: "center",
      containers: [
        {
          horizontalAlign: "center",
          startColumn: 4,
          endColumn: 7,
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
              text: '{"blocks":[{"key":"9hk81","text":"Skriv din tekst her","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"SIZE_XL7"},{"offset":0,"length":19,"style":"color-#ffffff"},{"offset":0,"length":19,"style":"SIZE_XL7"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
            },
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
              text: '{"blocks":[{"key":"226mp","text":"Skriv din tekst her","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"color-#ffffff"},{"offset":0,"length":19,"style":"SIZE_XL4"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
            },
          ],
        },
      ],
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 2,
    title: "Brødtekst seksjon",
    preview: "013AjtNohUsM",
    type: SectionTemplates.BODYTEXT_SECTION,
    section: {
      templateId: 2,
      type: "content",
      minHeight: "100vh",
      containers: [
        {
          horizontalAlign: "center",
          startColumn: 5,
          endColumn: 6,
          blocks: [
            {
              type: "text",
              placeholder:
                "Klikk her for å skrive. Bruk enter-tasten for å lage avsnitt. For å legge til andre innholdsblokker, som for eksempel bilder, må du klikke på beholderen som omslutter denne tekstblokken.",
            },
          ],
        },
      ],
      verticalAlign: "center",
      backgroundColor: "#ffffffff",
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 3,
    title: "Sikksakk seksjon",
    preview: "013AjtNohUsN",
    type: SectionTemplates.ZIGZAG_SECTION,
    section: {
      templateId: 3,
      type: SectionTypes.CONTENT,
      gridSize: SectionGridSize.TWELVE_COLUMNS,
      containers: [
        {
          horizontalAlign: "center",
          startColumn: 2,
          endColumn: 8,
          blocks: [
            {
              type: "image",
            },
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
        },
        {
          horizontalAlign: "center",
          startColumn: 6,
          endColumn: 12,
          blocks: [
            {
              type: "image",
            },
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
        },
      ],
      minHeight: "150vh",
      verticalAlign: "center",
      backgroundColor: "#ffffffff",
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 4,
    title: "Scrollevideo",
    preview: "013AjtNohUsQ",
    type: SectionTemplates.SCROLLVIDEO_SECTION,
    section: {
      templateId: 4,
      type: "content",
      minHeight: "100vh",
      backgroundColor: "#ffffffff",
      containers: [
        {
          horizontalAlign: "left",
          startColumn: 3,
          endColumn: 8,
          blocks: [
            {
              playbackConstant: 800,
              type: "videoScroll",
              format: "video/mp4",
              videoUrl: "",
            },
          ],
        },
      ],
      verticalAlign: "center",
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 5,
    title: "Sticky seksjon (En bakgrunn)",
    preview: "013AjtNohUsP",
    type: SectionTemplates.STICKY_SECTION,
    section: {
      templateId: 5,
      type: "sticky",
      gridSize: "nineColumns",
      backgroundImage: "013AjtbpHVyf",
      containers: [
        {
          startColumn: 5,
          endColumn: 6,
          horizontalAlign: "center",
          stepPosition: "bottom",
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
          backgroundColor: "#ffffffb3",
          borderRadius: true,
        },
        {
          startColumn: 5,
          endColumn: 6,
          horizontalAlign: "center",
          stepPosition: "bottom",
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
          backgroundColor: "#ffffffb3",
          borderRadius: true,
        },
      ],
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 6,
    title: "Sticky seksjon (Flere bakgrunner)",
    preview: "013AjtNohUsP",
    type: SectionTemplates.STICKY_SECTION_MULTI,
    section: {
      templateId: 6,
      type: "sticky",
      backgroundImage: [undefined],
      gridSize: SectionGridSize.NINE_COLUMNS,
      containers: [
        {
          startColumn: 5,
          endColumn: 6,
          horizontalAlign: "center",
          stepPosition: "bottom",
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
          backgroundColor: "#ffffffb3",
          borderRadius: true,
        },
      ],
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
  {
    templateId: 7,
    title: "Scrollekart",
    preview: "019EE9DxF4uPm",
    type: SectionTemplates.SCROLL_MAP,
    section: {
      templateId: 7,
      gridSize: "twelveColumns",
      type: "stickyMap",
      minHeight: "500dvh",
      backgroundColor: "#ffffffff",
      zoom: 8,
      containers: [
        {
          startColumn: 2,
          endColumn: 6,
          horizontalAlign: "center",
          stepPosition: "bottom",
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
          backgroundColor: "#ffffffb3",
          borderRadius: true,
          removable: true
        },
        {
          startColumn: 2,
          endColumn: 6,
          horizontalAlign: "center",
          stepPosition: "bottom",
          blocks: [
            {
              type: "text",
              placeholder: "Klikk her for å skrive",
            },
          ],
          backgroundColor: "#ffffffb3",
          borderRadius: true,
          removable: true
        },
      ],
      verticalAlign: "center",
      lock: {
        horizontalAlign: "center",
        startColumn: 5,
        endColumn: 6,
        lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
        unlockedLabel: "Du kan scrolle videre",
        backgroundColor: "#FFFFFF",
      }
    },
  },
];

export const getTemplateTitleById = (id) => {
  const template = sectionTemplates.find((t) => t.templateId === id);

  if (template) {
    return template.title;
  }
};
