import React from "react";
import {theme} from "@ekultur/vm";
import {ThemeProvider} from "react-jss";
import {Story} from "../Story/Story";
import {Admin} from "../Admin/Admin";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ScrollytellProvider} from "../Admin/scrollytellContext";
import {useSetupLocks} from "../progress/useSetupLocks";
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import {allFonts} from "@ekultur/fonts";

const muiTheme = createTheme({
    breakpoints: {
        values: {
            scrollySm: 444,
            scrollyMd: 700,
            scrollyLg: 900,
            scrollyXl: 1200,
            scrolly2Xl: 1536
        }
    }
})

export const adminTheme = {
    ...theme,
    colors: {
        ...theme.colors,
        adminBlue: "#4484F1",
        adminGray: "#9E9E9E",
        primaryColor: "#1B8751",
    },
};

export const App = ({
                        data,
                        adminMode,
                        onClose,
                        onMediaSelect,
                        onChange,
                        onTriggerSettings,
                        sidePanel,
                    }) => {

    useSetupLocks(data)

    const adminOrStory = () => {
        if (adminMode) {
            return <Admin sidePanel={sidePanel}/>
        } else if (data) {
            return (
                <Story
                    data={data}
                    onClose={onClose}
                />
            )
        } else {
            return null
        }
    }

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={adminTheme}>
                <DndProvider backend={HTML5Backend}>
                    <ScrollytellProvider
                        story={{
                            ...data,
                            settings: {
                                ...data?.settings || {},
                                availableFonts: data?.settings?.availableFonts
                                    || [...new Set(allFonts.map(f => f.family))]
                                        .sort((a, b) => a.localeCompare(b)),
                            }
                        }}
                        onMediaSelect={onMediaSelect}
                        onChange={onChange}
                        onTriggerSettings={onTriggerSettings}
                    >
                        {adminOrStory()}
                    </ScrollytellProvider>
                </DndProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    )
}
