import {BlockTypes} from "../helpers/enums";
import {ReadMoreBlock} from "../read-more/ReadMoreBlock";
import React, {useState} from "react";
import {AdminTextBlock} from "../TextBlock/AdminTextBlock";
import {AdminImageBlock} from "../ImageBlock/AdminImageBlock";
import {EditVideoBlock} from "../VideoBlock/EditVideoBlock";
import {EditVideoScrollBlock} from "../VideoScrollBlock/EditVideoScrollBlock";
import {EditQuizBlock} from "../QuizBlock/EditQuizBlock";
import {EditThreeDBlock} from "../ThreeDBlock/EditThreeDBlock";
import {addBlock, removeBlock, updateBlock} from "../Admin/service";
import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {useIsActiveBlock} from "./useIsActiveBlock";
import {EditCodewordBlock} from "../codeword/EditCodewordBlock";
import {CopyBlockButton} from "./CopyBlockButton";
import {RemoveBlockButton} from "./RemoveBlockButton";
import {SelectableBlock} from "./SelectableBlock";
import useDeepCompareEffect from "use-deep-compare-effect";
import {BlockProvider} from "../read-more/blockContext";
import {ReadMoreContent} from "../read-more/ReadMoreContent";
import {ReadMoreSettings} from "../read-more/ReadMoreSettings";
import {useBackgroundColor} from "../Container/useBackgroundColor";

export const AdminBlock = ({sectionId, containerId, blockId, block, isAdminMode, children}) => { 
    const backgroundColor = useBackgroundColor({
        sectionId: sectionId,
        containerId: containerId
    })
    const [blockState, setBlockState] = useState({
        data: block,
        modified: false
    })
    const {onChange, story} = useScrollyTellProps()
    const isActiveBlock = useIsActiveBlock({
        sectionId: sectionId,
        containerId: containerId,
        blockId: blockId
    })

    useDeepCompareEffect(() => {
        setBlockState({
            data: block,
            modified: false
        });
    }, [block])

    const handleUpdate = updatedBlock => {
        const updatedStory = updateBlock(story, sectionId, containerId, blockId, updatedBlock)
        onChange(updatedStory)
    }

    const handleCopyBlock = copyBlock => {
        const updatedStory = addBlock(story, sectionId, containerId, block);
        onChange(updatedStory)
    }

    const handleRemoveBlock = () => {
        const updatedStory = removeBlock(story, sectionId, containerId, blockId)
        onChange(updatedStory)
    }

    const handleUpdateState = (property, value) => {
        setBlockState({
            data: {
                ...blockState.data,
                [property]: value,
            },
            modified: true
        });
    }

    const handleCommitState = () => {
        if (blockState.modified) {
            handleUpdate(blockState.data)
        }

    }

    const blockByType = {
        [BlockTypes.TEXT]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                onBlur={handleCommitState}
            >
                <AdminTextBlock
                    text={block.text}
                    placeholder={blockState.data.placeholder}
                    isActiveBlock={isActiveBlock}
                    onPropertyUpdate={handleUpdateState}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </AdminTextBlock>
            </SelectableBlock>
        ),
        [BlockTypes.IMAGE]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
            >
                <AdminImageBlock
                    sectionId={sectionId}
                    containerId={containerId}
                    block={block}
                    onUpdate={handleUpdate}
                    isActiveBlock={isActiveBlock}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </AdminImageBlock>
            </SelectableBlock>
        ),
        [BlockTypes.VIDEO]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
            >
                <EditVideoBlock
                    block={block}
                    onUpdate={handleUpdate}
                    isActiveBlock={isActiveBlock}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </EditVideoBlock>
            </SelectableBlock>
        ),
        [BlockTypes.VIDEO_SCROLL]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
            >
                <EditVideoScrollBlock
                    block={block}
                    onUpdate={handleUpdate}
                    isActiveBlock={isActiveBlock}
                    sectionId={sectionId}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </EditVideoScrollBlock>
            </SelectableBlock>
        ),
        [BlockTypes.QUIZ]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                onBlur={handleCommitState}
            >
                <EditQuizBlock
                    block={blockState.data}
                    isActiveBlock={isActiveBlock}
                    onPropertyUpdate={handleUpdateState}
                    containerBackground={backgroundColor}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </EditQuizBlock>
            </SelectableBlock>
        ),
        [BlockTypes.THREE_D]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
            >
                <EditThreeDBlock
                    sectionId={sectionId}
                    containerId={containerId}
                    blockId={blockId}
                    block={block}
                    onUpdate={handleUpdate}
                    isActiveBlock={isActiveBlock}
                >
                    <CopyBlockButton
                        sectionId={sectionId}
                        containerId={containerId}
                        block={block}
                        onCopy={handleCopyBlock}
                    />
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </EditThreeDBlock>
            </SelectableBlock>
        ),
        [BlockTypes.READ_MORE]: (
            <BlockProvider>
                <SelectableBlock
                    sectionId={sectionId}
                    containerId={containerId}
                    blockId={blockId}
                >
                    <ReadMoreBlock
                        blockState={blockState.data}
                        isActiveBlock={isActiveBlock}
                        isAdminMode={true}
                    >
                        <RemoveBlockButton
                            blockId={blockId}
                            onRemove={handleRemoveBlock}
                        />
                        <CopyBlockButton
                            sectionId={sectionId}
                            containerId={containerId}
                            block={block}
                            onCopy={handleCopyBlock}
                        />
                    </ReadMoreBlock>
                </SelectableBlock>
                <ReadMoreContent
                    blockState={blockState.data}
                    isAdminMode={isAdminMode}
                    onUpdate={handleUpdate}
                />
                <ReadMoreSettings
                    onUpdateProperty={handleUpdateState}
                    onUpdate={handleCommitState}
                    blockState={blockState.data}
                />
            </BlockProvider>
        ),
        [BlockTypes.CODEWORD]: (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
            >
                <EditCodewordBlock
                    sectionId={sectionId}
                    containerId={containerId}
                    blockId={blockId}
                    block={block}
                    onRemove={handleRemoveBlock}
                >
                    <RemoveBlockButton
                        blockId={blockId}
                        onRemove={handleRemoveBlock}
                    />
                </EditCodewordBlock>
            </SelectableBlock>
        )
    }

    if (isAdminMode) {
        return blockByType[block.type]
    } else {
        return (
            <>
                {children}
            </>
        )
    }

}