import React from "react";
import { createUseStyles } from "react-jss";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { addContainer } from "../Admin/service";
import {useContentHeight} from "../map/useContentHeight";
import {SectionGridSize} from "../helpers/enums";

const useStyles = createUseStyles({
  addContainer: {
    position: "absolute",
    top: 0,
    right: "40px",
    height: "calc(100% - 40px)",
    display: "flex",
    zIndex: 15,
    pointerEvents: "none",
  },
  wrapper: {
    position: "sticky",
    top: 0,
    height: ({contentHeight}) => `calc(${contentHeight - 40}px)`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  fab: {
    pointerEvents: "all",
  },
});

export const AddContainerButton = ({gridSize, sectionId}) => {
  const t = useScrollytellTranslation();
  const { story, onChange } = useScrollyTellProps();
  const contentHeight = useContentHeight()
  const classes = useStyles({
    contentHeight: contentHeight
  });

  const add = () => {
    onChange(
      addContainer(
        story,
        {
          startColumn: gridSize === SectionGridSize.TWELVE_COLUMNS ? 2 : 5,
          endColumn: 6,
          blocks: [],
          removable: true
        },
        sectionId
      )
    );
  };

  return (
    <div className={classes.addContainer}>
      <div className={classes.wrapper}>
        <Fab
          className={classes.fab}
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={() => add()}
        >
          <Add sx={{ mr: 1 }} />
          {t("addContainerButton", "Container")}
        </Fab>
      </div>
    </div>
  );
};
