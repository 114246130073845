export const initializeStory = (story) => ({...story, sections: []});

export const addSection = (story, section) => ({
    ...story,
    sections: [...story.sections, section],
});
export const deleteSection = (story, sectionId) => ({
    ...story,
    sections: story.sections
        .filter((s, i) => sectionId !== i)
        .map((s, i) => ({...s, order: i + 1})),
});
export const updateSection = (story, sectionId, section) => {
    const exists = story.sections[sectionId];
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((s, i) =>
                i !== sectionId ? s : {...s, ...section}
            ),
        };
    } else {
        throw new Error(`Section with sectionId: ${sectionId} does not exist`);
    }
};
export const updateSectionOrder = (story, fromIndex, toIndex) => {
    const move = (from, to, array) => {
        const thisArray = [...array];
        thisArray.splice(to, 0, thisArray.splice(from, 1)[0]);
        return thisArray;
    };
    const sortedSections = story.sections.sort((a, b) => a.order - b.order);
    const updatedSections = move(fromIndex, toIndex, sortedSections).map(
        (s, i) => ({...s, order: i + 1})
    );
    return {...story, sections: updatedSections};
};

export const addContainer = (story, container, sectionId) => ({
    ...story,
    sections: story.sections.map((s, i) => i !== sectionId
        ? s
        : {
            ...s,
            containers: [...s.containers, container]
        }
    ),
});
export const updateContainer = (story, sectionId, containerId, container) => {
    const exists =
        story.sections[sectionId]?.containers[containerId] !== undefined;
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((s, i) =>
                i !== sectionId
                    ? s
                    : {
                        ...s,
                        containers: s.containers.map((c, j) => {
                            if (j !== containerId) {
                                return c;
                            }
                            if (container === undefined) {
                                return {};
                            }
                            return {...c, ...container};
                        }),
                    }
            ),
        };
    } else {
        throw new Error(
            `Block with sectionId: ${sectionId}, containerId: ${containerId} does not exist`
        );
    }
};
export const removeContainer = (story, sectionId, containerId) => {
    const exists =
        story.sections[sectionId]?.containers[containerId] !== undefined;
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((s, i) =>
                i !== sectionId
                    ? s
                    : {
                        ...s,
                        containers:
                            s.containers.length === 1
                                ? []
                                : [...s.containers.filter((c, j) => j !== containerId)],
                    }
            ),
        };
    } else {
        throw new Error(
            `container with sectionId: ${sectionId}, containerId: ${containerId}, does not exist`
        );
    }
};

export const addBlock = (story, sectionId, containerId, block) => {
    const exists =
        story.sections[sectionId]?.containers[containerId] !== undefined;
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((s, i) =>
                i !== sectionId
                    ? s
                    : {
                        ...s,
                        containers: s.containers.map((c, j) =>
                            j !== containerId
                                ? c
                                : {
                                    ...c,
                                    blocks: [...c.blocks, {...block}],
                                }
                        ),
                    }
            ),
        };
    } else {
        throw new Error(
            `Block with sectionId: ${sectionId}, containerId: ${containerId} does not exist`
        );
    }
};


export const updateBlock = (story, sectionId, containerId, blockId, block) => {
    const exists =
        story.sections[sectionId]?.containers[containerId]?.blocks[blockId] !==
        undefined;
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((s, i) =>
                i !== sectionId
                    ? s
                    : {
                        ...s,
                        containers: s.containers.map((c, j) =>
                            j !== containerId
                                ? c
                                : {
                                    ...c,
                                    blocks: [
                                        ...c.blocks.map((b, k) => {
                                            if (k !== blockId) {
                                                return b;
                                            }
                                            if (block === undefined) {
                                                return {};
                                            }
                                            return {...b, ...block};
                                        }),
                                    ],
                                }
                        ),
                    }
            ),
        };
    } else {
        throw new Error(
            `Block with sectionId: ${sectionId}, containerId: ${containerId}, blockId: ${blockId}, does not exist`
        );
    }
};
export const removeBlock = (story, sectionId, containerId, blockId) => {
    const exists =
        story.sections[sectionId]?.containers[containerId]?.blocks[blockId] !==
        undefined;
    if (exists) {
        return {
            ...story,
            sections: story.sections.map((section, i) =>
                i !== sectionId
                    ? section
                    : {
                        ...section,
                        containers: section.containers.map((container, j) =>
                            j !== containerId
                                ? container
                                : {
                                    ...container,
                                    blocks: container.blocks.filter(
                                        (block, k) => k !== blockId
                                    ),
                                }
                        ),
                    }
            ),
        };
    } else {
        throw new Error(
            `Block with sectionId: ${sectionId}, containerId: ${containerId}, blockId: ${blockId}, does not exist`
        );
    }
};
