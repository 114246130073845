import {createUseStyles} from "react-jss";
import {VerticalAlign} from "../helpers/enums";
import {useBreakpoints} from "../layout/useBreakpoints";
import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";
import {useEffect, useState} from "react";

const useStyles = createUseStyles({
    stickyWrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        pointerEvents: "none",
        gridColumn: ({ startColumn, endColumn }) => `${startColumn} / ${endColumn}`,
        justifyContent: ({verticalAlign}) => {
            if (verticalAlign === VerticalAlign.BOTTOM) {
                return "flex-end";
            } else if (verticalAlign === VerticalAlign.CENTER) {
                return "center";
            } else {
                return "flex-start";
            }
        },
    },
});

export const ScrollerWrapper = ({startColumn, endColumn, verticalAlign, containersCount, isThumbnail, isMap=false, contentHeight, children}) => {
    const [height, setHeight] = useState("100%")
    const breakpoints = useBreakpoints()
    const matchLargeDown = useMediaQuery(down(breakpoints.large))
    const classes = useStyles({
        verticalAlign: verticalAlign,
        startColumn: matchLargeDown ? 5 : startColumn,
        endColumn: matchLargeDown ? 6 : endColumn,
    })

    useEffect(
        () => {
            if(isThumbnail) {
                setHeight("100%")
            } else if(isMap) {
                setHeight(containersCount < 5 ? `${(contentHeight * 5) / (containersCount || 1)}px` : `${contentHeight}px`)
            } else {
                setHeight(containersCount === 1 ? `${contentHeight * 1.5}px` : `${contentHeight}px`)
            }
            return () => setHeight("100%")
        },
        [containersCount, isThumbnail, contentHeight, isMap]
    )

    return (
        <div
            className={classes.stickyWrapper}
            style={{
                height: height
            }}
        >
            {children}
        </div>
    )
}