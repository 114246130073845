import useDeepCompareEffect from "use-deep-compare-effect";
import {LOCKS_SETUP, useProgressDispatch} from "./progressContext";

export const useSetupLocks = data => {
    const progressDispatch = useProgressDispatch()

    useDeepCompareEffect(
        () => {
            if(data) {
                let locks = []
                data.sections.sort((a, b) => a.order - b.order)
                    .forEach((section, sectionIndex) => {
                        section.containers.forEach((container, containerIndex) => {
                            container.blocks.forEach((block, blockIndex) => {
                                if(block.locked === true) {
                                    locks.push({
                                        sectionId: sectionIndex,
                                        containerId: containerIndex,
                                        blockId: blockIndex
                                    })
                                }
                            })
                        })
                    })

                progressDispatch({
                    type: LOCKS_SETUP,
                    payload: locks
                })
            }

        }, [data]
    )

}