import {TextField} from "@mui/material";
import React from "react";

export const ReadMoreTextField = ({show=true,autoFocus=false, label, property, value, onChange, disabled=false}) => {
    const handleChange = event => {
        onChange(property, event.target.value)
    }

    if(show) {
        return (
            <TextField
                label={label}
                value={value}
                onChange={handleChange}
                autoFocus={autoFocus}
                disabled={disabled}
            />
        )
    } else {
        return null
    }

}