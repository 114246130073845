import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {ColorLens} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";

export const BackgroundColorButton = ({templateId, onClick}) => {
    const t = useScrollytellTranslation();
    const handleClick = () => onClick("colorMenu")

    if(templateId !== 7) {
        return (
            <AdminIconBarItem
                Icon={ColorLens}
                onClick={handleClick}
                tooltip={t(
                    "editSectionPanel.backgroundColorTooltip",
                    "Bakgrunnsfarge valg"
                )}
            />
        )
    } else {
        return null
    }

}