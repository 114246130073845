import React, { useRef } from "react";
import { useClickOutside } from "../common/useClickOutside";
import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { createUseStyles } from "react-jss";
import { Box, Slider, Typography } from "@mui/material";
import { useScrollytellTranslation } from "../Admin/scrollytellContext";

const useStyles = createUseStyles({
  subMenu: {
    top: "-5px",
    left: "-2px",
    position: "absolute",
    display: "inline-flex",
    flexDirection: "column",
    //justifyContent: "center",
    //alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    zIndex: 10,
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
  positionPickers: {},
  position: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rotationPickers: {},
  rotation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  scalePicker: {},
  scale: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
});

export const ModelMenu = ({ scene, onChange, onClose }) => {
  const modelMenuRef = useRef();
  const t = useScrollytellTranslation();
  useClickOutside(modelMenuRef, () => onClose());

  const classes = useStyles();

  const updateModel = (key, value) => {
    const updatedScene = { ...scene, model: { ...scene.model, [key]: value } };
    return onChange(updatedScene);
  };

  const updatePosition = (value) => {
    const updatedScene = {
      ...scene,
      position: [value[0], value[1], value[2] - 0.1],
      model: { ...scene.model, position: value },
    };
    return onChange(updatedScene);
  };

  return (
    <AdminIconBar ref={modelMenuRef} className={classes.subMenu}>
      <div className={classes.positionPickers}>
        <Typography sx={{ marginRight: "8px" }}>
          {t("threeDBlockSetPositionLabel", "Posisjon")}
        </Typography>
        <div className={classes.position}>
          <Typography sx={{ marginRight: "8px" }}>
            {t("threeDBlockSetXPositionLabel", "X")}
          </Typography>
          <Box sx={{ display: "flex", width: "270px" }}>
            <Slider
              getAriaLabel={() => "Position x"}
              value={scene?.model?.position[0]}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updatePosition([
                  newValue,
                  scene?.model?.position[1],
                  scene?.model?.position[2],
                ]);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
        <div className={classes.position}>
          <Typography sx={{ marginRight: "8px" }}>
            {t("threeDBlockSetYPositionLabel", "Y")}
          </Typography>
          <Box sx={{ display: "flex", width: "270px" }}>
            <Slider
              getAriaLabel={() => "Position y"}
              value={scene?.model?.position[1]}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updatePosition([
                  scene?.model?.position[0],
                  newValue,
                  scene?.model?.position[2],
                ]);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
      </div>
      <div className={classes.rotationPickers}>
        <Typography sx={{ marginRight: "8px" }}>
          {t("threeDBlockSetRotationLabel", "Rotasjon")}
        </Typography>
        <div className={classes.rotation}>
          <Typography sx={{ marginRight: "8px" }}>
            {t("threeDBlockSetXRotationLabel", "X")}
          </Typography>
          <Box sx={{ display: "flex", width: "270px" }}>
            <Slider
              getAriaLabel={() => "Rotation x"}
              value={scene?.model?.rotation[0]}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updateModel("rotation", [
                  newValue,
                  scene?.model?.rotation[1],
                  scene?.model?.rotation[2],
                ]);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
        <div className={classes.rotation}>
          <Typography sx={{ marginRight: "8px" }}>
            {t("threeDBlockSetYRotationLabel", "Y")}
          </Typography>
          <Box sx={{ display: "flex", width: "270px" }}>
            <Slider
              getAriaLabel={() => "Rotation y"}
              value={scene?.model?.rotation[1]}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updateModel("rotation", [
                  scene?.model?.rotation[0],
                  newValue,
                  scene?.model?.rotation[2],
                ]);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
        <div className={classes.rotation}>
          <Typography sx={{ marginRight: "8px" }}>
            {t("threeDBlockSetZRotationLabel", "Z")}
          </Typography>
          <Box sx={{ display: "flex", width: "270px" }}>
            <Slider
              getAriaLabel={() => "Rotation z"}
              value={scene?.model?.rotation[2]}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updateModel("rotation", [
                  scene?.model?.rotation[0],
                  scene?.model?.rotation[1],
                  newValue,
                ]);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
      </div>
      <div className={classes.scalePicker}>
        <Typography sx={{ marginRight: "8px" }}>
          {t("threeDBlockSetScaleLabel", "Skaler")}
        </Typography>
        <div className={classes.scale}>
          <Box sx={{ display: "flex", width: "300px" }}>
            <Slider
              getAriaLabel={() => "Scale"}
              value={scene?.model?.scale}
              step={0.1}
              min={-5}
              max={5}
              onChange={(e, newValue) => {
                updateModel("scale", newValue);
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
      </div>
    </AdminIconBar>
  );
};
