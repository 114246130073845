import {createUseStyles} from "react-jss";
import {useScrollytellState} from "../Admin/scrollytellContext";

const useStyles = createUseStyles({
    mapContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
});

export const MapContainer = ({children}) => {
    const classes = useStyles();
    const {editDirections} = useScrollytellState()

    return (
        <div
            className={classes.mapContainer}
            style={{
                zIndex: editDirections ? 10 : 'auto'
            }}
        >
            {children}
        </div>
    )
}