import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        all: "unset",
        display: "block",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "15px",
        paddingRight: "10px"
    },
    rectangle: {

        height: "3px",
        backgroundColor: "#D9D9D9",
        opacity: '0.5',
        borderRadius: "8px",
        transition: "width 0.3s",
        transitionTimingFunction: "ease-in-out",
        width: ({width}) => width,
    },
})


export const ProgressRectangleAfterLock = ({expanded, onClick}) => {
    const classes = useStyles({
        width: "4px",
    })

    return (
        <button
            className={classes.root}
            disabled={expanded}
            onClick={onClick}
        >
            <div
                className={classes.rectangle}
            >

            </div>
        </button>
    )
}