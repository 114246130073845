import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { SectionGridSize } from "../../helpers/enums";
import { GridColumn } from "./GridColumn";
import { updateContainer } from "../../Admin/service";
import { useScrollyTellProps } from "../../Admin/scrollytellContext";

const useStyles = createUseStyles({
  gridHelper: {
    position: "absolute",
    display: "grid",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    gridGap: "inherit",
    pointerEvents: "none",
    zIndex: ({ canDrop }) => (canDrop ? 1000 : 1),
  },
  nineColumns: {
    gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
    "@media screen and (min-width: 800px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 700px 1fr 1fr 1fr 1fr",
    },
    "@media screen and(min-width: 650px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 600px 1fr 1fr 1fr 1fr",
    },
  },
  twelveColumns: {
    gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
    "@media screen and (min-width: 1024px)": {
      gridTemplateColumns: "repeat(12, 1fr)",
    },
  },
});

export const GridHelper = ({ sectionId, gridSize, backgroundColor }) => {
  const [canDrop, setCanDrop] = useState(false);
  const { onChange, story } = useScrollyTellProps();
  const columns = gridSize === SectionGridSize.NINE_COLUMNS ? 9 : 12;
  const classes = useStyles({ canDrop });
  const items = [...Array(columns)];

  const onDrop = (index, { containerId, prevValue }, property) => {
    if (property === "startColumn" && index + 1 < prevValue.endColumn) {
      onChange(
        updateContainer(story, sectionId, containerId, {
          [property]: index + 1,
        })
      );
    }
    if (property === "endColumn" && index + 2 > prevValue.startColumn) {
      onChange(
        updateContainer(story, sectionId, containerId, {
          [property]: index + 2,
        })
      );
    }
  };

  return (
    <div className={`${classes.gridHelper} ${classes[gridSize]}`}>
      {items.map((c, i) => (
        <GridColumn
          key={`gridColumn-${i + 1}`}
          backgroundColor={backgroundColor}
          onDrop={(item, monitor) => onDrop(i, item, monitor.getItemType())}
          onCanDropChange={setCanDrop}
        />
      ))}
    </div>
  );
};
