import React from "react";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px",
  },
  list: {
    padding: "8px 0",
    margin: 0,
    listStyle: "none",
    "& > li": {
      padding: "12px 16px",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

export const DemoLinks = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div>
        <h2>Demo'er</h2>
        <ul className={styles.list}>
          <li>
            <Link to="/scrolly/1">Scrolly 1</Link>
          </li>
          <li>
            <Link to="/scrolly/2">Scrolly 2</Link>
          </li>
          <li>
            <Link to="/scrolly/3">Scrolly 3</Link>
          </li>
          <li>
            <Link to="/scrolly/5">Scrolle kart</Link>
          </li>
          <li>
            <Link to="/admin">Admin Demo</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
