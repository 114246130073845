import {useEffect, useState} from "react";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Draw, Modify, MouseWheelZoom, Snap} from "ol/interaction";

const editStyle = {
    'fill-color': 'rgba(24, 20, 230, 1)',
    'stroke-color': 'white',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': 'rgba(24, 20, 230, 1)',
}

export const useEditLayer = ({map, edit, from, to}) => {
    const [interactions, setInteractions] = useState({})
    const [vectorSource, setVectorSource] = useState(null)

    useEffect(() => {
        if(map) {
            const savedFeatures = []
            if(from) {
                const fromPoint = new Feature({
                    geometry: new Point(fromLonLat(from))
                })
                savedFeatures.push(fromPoint)
            }

            if(to) {
                const toPoint = new Feature({
                    geometry: new Point(fromLonLat(to))
                })
                savedFeatures.push(toPoint)
            }

            const source = new VectorSource({
                features: savedFeatures
            })

            const mouseWheelZoom = new MouseWheelZoom();
            map.addInteraction(mouseWheelZoom)
            mouseWheelZoom.setActive(false)

            const modify = new Modify({source: source})
            map.addInteraction(modify)
            modify.setActive(false)

            const draw = new Draw({
                source: source,
                type: "Point",
            })
            map.addInteraction(draw)
            draw.setActive(false)

            const snap = new Snap({source: source})
            map.addInteraction(snap)
            snap.setActive(false)

            setInteractions({
                mouseWheelZoom: mouseWheelZoom,
                modify: modify,
                draw: draw,
                snap: snap
            })

            setVectorSource(source)
        }
    }, [map]);

    useEffect(
        () => {
            const vector = new VectorLayer({
                source: vectorSource,
                style: edit ? editStyle : null,
            })

            map?.addLayer(vector)
            return () => {
                map?.removeLayer(vector)
            }
        }, [map, edit]
    )

    return interactions
}