import React from "react";
import { Delete } from "@mui/icons-material";

import { createUseStyles } from "react-jss";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { removeContainer } from "../Admin/service";
import { Tooltip } from "@mui/material";

const useStyles = createUseStyles(({ colors }) => ({
  removeButton: {
    backgroundColor: colors.adminBlue,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    width: "24px",
    height: "24px",
    "&:hover": { color: `${colors.white}aa` },
    cursor: "pointer",
    position: "absolute",
    top: "-13px",
    right: "-11px",
    zIndex: 10,
  },
}));

export const RemoveContainerButton = ({ sectionId, containerId }) => {
  const classes = useStyles();
  const { onChange, story } = useScrollyTellProps();
  const t = useScrollytellTranslation();
  return (
    <div className={classes.removeButton}>
      <Tooltip title={t("removeContainerButtonTooltip", "Fjern container")}>
        <Delete
          onClick={() => {
            onChange(removeContainer(story, sectionId, containerId));
          }}
          sx={{
            width: "14px",
          }}
        />
      </Tooltip>
    </div>
  );
};
