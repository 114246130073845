import {createUseStyles} from "react-jss";
import {useEffect, useState} from "react";
import {BLOCK_UNLOCKED, useProgressDispatch} from "../progress/progressContext";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    input: {
        padding: "0px 24px",
        gap: "10px",
        alignSelf: "stretch",
        borderRadius: "999px",
        background: "rgba(255, 255, 255, 0.95)",
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 3px 0px inset, rgba(0, 0, 0, 0.075) 0px 2px 12px 0px inset",
        height: "55px",
        minWidth: "218px",
        appearance: "none",
        outline: "none",
        textAlign: "center",
        fontSize: "16px",
        fontFamily: "Roboto",
        lineHeight: "150%",
        border: 0
    }
})

export const CodewordBlock = ({sectionId, containerId, blockId, secret, placeholder, onClick = null}) => {
    const classes = useStyles()
    const [value, setValue] = useState("")
    const progressDispatch = useProgressDispatch()

    const handleChange = event => setValue(event.target.value)

    useEffect(
        () => {
            if (value && secret.toLowerCase() === value.toLowerCase()) {
                progressDispatch({
                    type: BLOCK_UNLOCKED,
                    payload: {
                        sectionId: sectionId,
                        containerId: containerId,
                        blockId: blockId
                    }
                })
            }
        }, [value]
    )


    const handleClick = () => onClick?.()

    return (
        <div
            className={classes.root}
        >
            <input
                className={classes.input}
                type={"text"}
                placeholder={placeholder || "Skriv kodeordet"}
                autoComplete={"off"}
                maxLength={20}
                value={value}
                onChange={handleChange}
                onClick={handleClick}
            >
            </input>
        </div>
    )
}

