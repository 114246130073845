import React from "react";
import {createUseStyles} from "react-jss";
import {RichTextField} from "../common/richTextField/RichTextField";

const useStyle = createUseStyles({
    textBlock: {
        width: "100%",
        margin: ({margin}) => margin,
        padding: ({padding}) => padding,
        fontFamily: ({font}) => font,
    },
});

export const TextBlock = ({block}) => {
    const {margin, padding, font, text} = block;
    const classes = useStyle({
        margin,
        padding,
        font,
    });

    return (
        <div className={classes.textBlock}>
            {text && (
                <RichTextField showToolbar={false} value={text} readOnly={true}/>
            )}
        </div>
    )
};
