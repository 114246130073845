import React, {useCallback, useEffect, useRef, useState,} from "react";
import {createUseStyles} from "react-jss";
import {useInView} from "react-intersection-observer";
import {HorizontalAlign, VerticalAlign} from "../helpers/enums";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch, useScrollytellState,} from "../Admin/scrollytellContext";
import {useBreakpoints} from "../layout/useBreakpoints";
import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";
import {useIsActiveContainer} from "./useIsActiveContainer";

const useStyles = createUseStyles(({colors}) => ({
    container: {
        backgroundColor: ({backgroundColor}) => backgroundColor,
        borderRadius: ({borderRadius}) => (borderRadius ? "8px" : "0px"),
        gridColumn: ({startColumn, endColumn}) => `${startColumn} / ${endColumn}`,
        minHeight: ({minHeight}) => minHeight,
        maxHeight: ({maxHeight}) => maxHeight,
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        pointerEvents: "auto",
        zIndex: 2,
        height: ({height}) => (height ? height : "fit-content"),
        alignItems: ({horizontalAlign}) => {
            if (horizontalAlign === HorizontalAlign.RIGHT) {
                return "flex-end";
            } else if (horizontalAlign === HorizontalAlign.CENTER) {
                return "center";
            } else {
                return "flex-start";
            }
        },
        justifyContent: ({verticalAlign}) => {
            if (verticalAlign === VerticalAlign.BOTTOM) {
                return "flex-end";
            } else if (verticalAlign === VerticalAlign.CENTER) {
                return "center";
            } else {
                return "flex-start";
            }
        },
        border: ({isAdminMode, isActiveContainer}) =>
            isAdminMode
                ? isActiveContainer
                    ? `2px solid ${colors.adminBlue}`
                    : `2px dashed ${colors.adminGray}`
                : "",
        "&.hover": {
            border: ({isAdminMode, isActiveContainer}) =>
                isAdminMode &&
                `2px ${isActiveContainer ? "solid" : "dashed"} ${colors.adminBlue}`,
        },
    },
}));

export const Container = ({
    sectionId,
    containerId,
    isAdminMode,
    backgroundColor,
    startColumn = 5,
    endColumn = 6,
    horizontalAlign = HorizontalAlign.CENTER,
    verticalAlign = VerticalAlign.TOP,
    borderRadius,
    onChange = () => {},
    hasBlocks,
    isThumbnail=false,
    children
}) => {
    const ref = useRef();
    const [hover, setHover] = useState(false);
    const dispatch = useScrollytellDispatch();
    const {inViewRef, inView} = useInView({
        threshold: 0,
    });
    const breakpoints = useBreakpoints()
    const matchLargeDown = useMediaQuery(down(breakpoints.large))

    const isActiveContainer = useIsActiveContainer({
        sectionId: sectionId,
        containerId: containerId,
    })

    const classes = useStyles({
        startColumn: matchLargeDown ? 5 : startColumn,
        endColumn: matchLargeDown ? 6 : endColumn,
        horizontalAlign,
        verticalAlign,
        backgroundColor,
        isAdminMode,
        isActiveContainer,
        borderRadius,
        maxHeight: isThumbnail ? "100%" : "none",
        minHeight: hasBlocks ? 0 : "3.5rem"
    });

    useEffect(() => {
        if (inView) {
            onChange(inView);
        }
    }, [inView, onChange]);

    const setRefs = useCallback(
        (node) => {
            ref.current = node;
            if (inViewRef) {
                inViewRef(node);
            }
        },
        [inViewRef]
    );

    const handleClick = (event) => {
        if (!isActiveContainer && event.target === ref.current) {
            dispatch({
                type: SET_ACTIVE_ELEMENT,
                activeSection: sectionId,
                activeContainer: containerId,
            });
        }
    }

    const handleMouseOver = (event) => {
        event.stopPropagation();
        setHover(true);
    }

    const handleMouseOut = (event) => {
        event.stopPropagation();
        setHover(false);
    }

    return (
        <div
            id={`container-${sectionId}-${containerId}`}
            className={`${classes.container} ${hover ? "hover" : ""}`}
            onClick={handleClick}
            ref={setRefs}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            {children}
        </div>
    );
};
