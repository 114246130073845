import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {ImageOutlined} from "@mui/icons-material";
import {createUseStyles} from "react-jss";
import {Camera} from "lucide-react";
import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {ImageMenu} from "./ImageMenu";
import {getBackgroundImageString} from "../helpers/helpers";
import {ResponsiveImage} from "../image/ResponsiveImage";
import {AdminActions} from "../Block/AdminActions";

export const EditImageBlock = ({block, contrastApproved, onUpdate, isActiveBlock, children}) => {
    const t = useScrollytellTranslation();
    const {onMediaSelect} = useScrollyTellProps();

    const classes = useStyles({
        isActiveBlock,
        contrastApproved,
    });

    const updateProperty = (property, value) => onUpdate({
        ...block,
        [property]: value,
    })

    const setImage = () => {
        if (onMediaSelect) {
            onMediaSelect("image", (dmsId) => {
                updateProperty('imagePath', dmsId)
            });
        } else {
            console.warn("onMediaSelect is undefined");
            updateProperty("imagePath", "019EE6jCchh6X")
        }
    };

    const handleUpdateCaption = event => updateProperty("caption", event.target.value);

    return (
        <>
            {block.imagePath && (
                <ResponsiveImage
                    mediaId={block.imagePath}
                    alt={block.altText}
                    className={classes.img}
                />
            )}
            <label
                className={classes.imageUpload}
                style={{position: block.imagePath ? "absolute" : "initial"}}
                htmlFor="imageBlockUpload"
                onClick={() => setImage()}
            >
                {!block.imagePath && (
                    <div
                        className={classes.imagePlaceholder}
                        style={{
                            backgroundImage: getBackgroundImageString(
                                placeholderBackground,
                                undefined,
                                true
                            ),
                        }}
                    >
                        <Tooltip title={t("editImageBlock.uploadImage", "Velg et bilde")}>
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                sx={{
                                    backgroundColor: "#FAFAFA",
                                }}
                            >
                                <ImageOutlined/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </label>
            {block.imagePath && block.caption !== undefined && (
                <div className={classes.imageBlockCaption}>
                    <TextField
                        className={classes.imageBlockCaptionText}
                        value={block.caption || ""}
                        onChange={handleUpdateCaption}
                        label="Caption"
                        InputProps={
                            block.showCaptionIcon
                                ? {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Camera className={classes.captionIcon}/>
                                        </InputAdornment>
                                    ),
                                }
                                : undefined
                        }
                    />
                </div>
            )}
            <AdminActions
                show={isActiveBlock}
            >
                {children}
                <ImageMenu
                    block={block}
                    onUpdateProperty={updateProperty}
                    handleSetImage={() => setImage()}
                />
            </AdminActions>
        </>
    );
};

const useStyles = createUseStyles(({colors}) => ({
    img: {
        maxWidth: "100%"
    },
    imageUpload: {
        top: "2px",
        left: "calc(100% - 52px)",
        cursor: "pointer",
    },
    imagePlaceholder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#A6A6A661",
        height: "500px",
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
    },
    imageBlockEditIconButton: {
        height: "48px !important",
        width: "48px !important",
        minWidth: "48px !important",
        backgroundColor: colors.white + " !important",
        boxShadow: `0px 1px 2px ${colors.black}0F, 0px 1px 3px ${colors.black}1A !important`,
        border: `1px solid ${colors.black}0D`,
        borderRadius: "4px !important",
        opacity: 0.8,
        cursor: "pointer",
    },
    imageBlockEditIconText: {
        fontSize: "14px",
        fontStyle: "italic",
    },
    imageBlockCaption: {
        padding: "16px",
    },
    imageBlockEditButton: {
        height: "48px",
    },
    imageBlockCaptionText: {
        width: "100%",
        "& input, & label": {
            color: ({contrastApproved}) =>
                contrastApproved ? colors.white : colors.black,
        },
    },
    captionIcon: {
        width: "12px",
        margin: "0 14px",
        color: ({contrastApproved}) =>
            contrastApproved ? colors.white : colors.black,
    },
}));

const placeholderBackground = "013AjtNnCUXw";
