import {createUseStyles} from "react-jss";
import {useProgressState} from "./progressContext";
import {SectionTitle} from "./SectionTitle";
import {useIsTouchDevice} from "../App/useIsTouchDevice";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    },
    button: ({padding, expanded}) => ({
        all: "unset",
        display: "block",
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: "10px",
        paddingRight: "10px",
        width: expanded ? "68px" : "auto"
    }),
    rectangle: {
        height: "3px",
        backgroundColor: "#D9D9D9",
        opacity: '0.5',
        borderRadius: "8px",
        transition: "width 0.3s",
        transitionTimingFunction: "ease-in-out",
        width: ({width}) => width,
    },
    active: {
        opacity: 1
    },
})

const calculateExpandedWidth = (currentSectionId, sectionId) => {
    const distance = Math.abs(currentSectionId - sectionId)
    const width = Math.max(75 - (distance * 5), 25)
    return `${width}px`
}

export const ProgressRectangle = ({
    expanded,
    sectionId,
    width = "14px",
    hoveredSectionId,
    onHover,
    onClick,
    title,
}) => {
    const {currentSectionId} = useProgressState()
    const currentOrHoverSectionId = hoveredSectionId === null ? currentSectionId : hoveredSectionId
    const isActive = currentOrHoverSectionId === sectionId
    const expandedWidth = calculateExpandedWidth(currentOrHoverSectionId, sectionId)
    const isTouchDevice = useIsTouchDevice()

    const classes = useStyles({
        width: expanded ? expandedWidth : width,
        padding: isTouchDevice && expanded ? "8px" : "4px",
        expanded: expanded
    })

    const handleHover = event => {
        if(expanded) {
            event.stopPropagation()
            onHover(sectionId)
        }
    }
    const handleClick = event => {
        event.stopPropagation()
        onClick(sectionId)
    }

    return (
        <div className={classes.root}>
            <button
                className={classes.button}
                onPointerEnter={handleHover}
                onFocus={handleHover}
                onClick={handleClick}
            >
                <div
                    className={`${classes.rectangle} ${isActive ? classes.active : ""}`}
                >
                </div>
            </button>
            <SectionTitle
                show={(isActive || isTouchDevice) && expanded}
            >
                {title}
            </SectionTitle>
        </div>
    )
}