import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    blockSpacer: ({marginTop, marginBottom}) => ({
        width: "100%",
        marginTop: marginTop,
        marginBottom: marginBottom
    })
})
export const BlockSpacer = ({first, last, children}) => {
    const classes = useStyles({
        marginTop: first ? 0 : "0.5rem",
        marginBottom: last ? 0 : "0.5rem"
    })
    return (
        <div
            className={classes.blockSpacer}
        >
            {children}
        </div>
    )
}