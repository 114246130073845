import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AdminDemo} from "./AdminDemo";
import {ScrollyDemo} from "./ScrollyDemo";
import {DemoLinks} from "./DemoLinks";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        height: "100vh",
        overflowY: "auto",
    },
});

export const Demo = () => {
    const classes = useStyles()
    return (
        <div
            className={classes.root}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<DemoLinks/>}/>
                    <Route path="admin" element={<AdminDemo/>}/>
                    <Route path="scrolly/:id" element={<ScrollyDemo/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
