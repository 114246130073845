import {createUseStyles, useTheme} from "react-jss";
import {useIsActiveBlock} from "./useIsActiveBlock";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch} from "../Admin/scrollytellContext";
import {ButtonBase} from "@mui/material";

const useStyles = createUseStyles(({colors}) => ({
    buttonBase: ({color, textAlign, margin, padding, font, isActiveBlock}) => ({
        width: "100%",
        color: color || colors.black,
        textAlign: textAlign,
        margin: margin,
        padding:  padding,
        fontFamily: font,
        "&:hover": {
            border: `2px ${isActiveBlock ? "solid" : "dashed"} ${colors.adminBlue}`,
        }
    })
}))

export const SelectableBlock = ({sectionId, containerId, blockId, onBlur=null, children}) => {
    const dispatch = useScrollytellDispatch()
    const {colors} = useTheme()
    const isActiveBlock = useIsActiveBlock({
        sectionId: sectionId,
        blockId: blockId,
        containerId: containerId
    })
    const classes = useStyles({
        isActiveBlock: isActiveBlock
    })

    const handleClick = event => {
        if(!isActiveBlock) {
            event.stopPropagation()
            dispatch({
                type: SET_ACTIVE_ELEMENT,
                activeBlock: blockId,
                activeSection: sectionId,
                activeContainer: containerId,
            })
        }
    }

    return (
        <ButtonBase
            id={`block-${sectionId}-${containerId}-${blockId}`}
            className={classes.buttonBase}
            onClick={handleClick}
            onBlur={onBlur || null}
            sx={{
                border:  isActiveBlock ? `2px solid ${colors.adminBlue}` : "2px solid transparent",
                display: "block"
            }}
        >
            {children}
        </ButtonBase>
    )
}