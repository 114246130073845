import {Lock} from "lucide-react";
import {createUseStyles} from "react-jss";
import {useProgressState} from "./progressContext";
import {SectionTitle} from "./SectionTitle";
import {useIsTouchDevice} from "../App/useIsTouchDevice";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    },
    button: ({padding, opacity, expanded}) => ({
        all: "unset",
        display: "block",
        paddingTop: padding,
        paddingLeft: "10px",
        paddingRight: "10px",
        opacity: opacity,
        width: expanded ? "68px" : "auto"
    })
})

export const ProgressLock = ({
     expanded,
     sectionId,
     hoveredSectionId,
     onHover,
     onClick,
     title
}) => {
    const isTouchDevice = useIsTouchDevice()

    const {currentSectionId} = useProgressState()
    const currentOrHoverSectionId = hoveredSectionId === null ? currentSectionId : hoveredSectionId
    const isActive = currentOrHoverSectionId === sectionId
    const classes = useStyles({
        padding: isTouchDevice && expanded ? "7px" : "3px",
        opacity: isActive ? "1" : "0.5",
        expanded: expanded
    })

    const handleHover = () => {
        if(expanded) {
            onHover(sectionId)
        }
    }
    const handleClick = event => {
        event.stopPropagation()
        onClick(sectionId)
    }

    return (
        <div className={classes.root}>
            <button
                className={`${classes.button}`}
                onMouseOver={handleHover}
                onFocus={handleHover}
                onClick={handleClick}
            >
                <Lock
                    size={14}
                    color={"#D9D9D9"}
                />
            </button>
            <SectionTitle
                show={(isActive || isTouchDevice)  && expanded}
            >
                {title}
            </SectionTitle>
        </div>

    )
}