import {getBackgroundImageString} from "../helpers/helpers";
import {Button, IconButton} from "@mui/material";
import {
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    CenterFocusStrong,
    ContentCopy, DeleteOutline,
    ImageOutlined
} from "@mui/icons-material";
import React from "react";
import {createUseStyles} from "react-jss";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

const useStyles = createUseStyles({
    step: {
        padding: "0 8px",
    },
    stepPreview: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "206px",
        height: "99px",
    },
    stepToolbar: {
        padding: "7px 0",
        display: "flex",
        justifyContent: "space-between",
    },
    stepToolbarLeft: {position: "relative"},
    stepToolbarRight: {},
});

const defaultBackgroundImage = "013AjtNnCUXw";

export const StickySectionSidebarStep = ({image, i, onScrollTo, onAddBackground, onMoveUp, onMoveDown, onSetFocusPickerIndex, onDuplicate, onDelete, isLast}) => {
    const classes = useStyles()
    const t = useScrollytellTranslation();


    const handleScrollTo = () => onScrollTo(i)

    const handleScrollAndFocus = () => {
        onScrollTo(i)
        onSetFocusPickerIndex(i)
    }

    const handleAddBackground = e => {
        e.stopPropagation()
        onAddBackground(i)
    }

    const handleMoveUp = () => onMoveUp(i)

    const handleMoveDown = () => onMoveDown(i)

    const handleDelete = () => onDelete(i)

    const handleDuplicate = () => onDuplicate(i)

    return (
        <div className={classes.step} key={`background-image-${i}`}>
            <h4>
                {`${t(
                    "stickySectionSidebar.stepTitle",
                    "Bakgrunnsbilde steg"
                )} ${i + 1}`}
            </h4>

            <div
                className={classes.stepPreview}
                style={{
                    backgroundImage: getBackgroundImageString(
                        image?.image ? image.image : defaultBackgroundImage,
                        undefined,
                        true,
                        "1024x1024"
                    ),
                    backgroundRepeat: !image?.image ? "repeat" : "no-repeat",
                    backgroundSize: !image?.image ? "contain" : "cover",
                }}
                onClick={handleScrollTo}
            >

                <Button
                    onClick={handleAddBackground}
                    sx={{
                        backgroundColor: image?.image ? "#424242" : "#3F51B5",
                        color: "white",
                    }}
                >
                    <ImageOutlined/>
                    {image?.image
                        ? t(
                            "stickySectionSidebar.updateImageButtonText",
                            "Endre bilde"
                        )
                        : t("stickySectionSidebar.selectImageButtonText", "Velg")}
                </Button>
            </div>
            <div className={classes.stepToolbar}>
                <div className={classes.stepToolbarLeft}>
                    <IconButton
                        sx={{borderRadius: 0, padding: "4px"}}
                        onClick={handleScrollAndFocus}
                    >
                        <CenterFocusStrong/>
                    </IconButton>
                    <IconButton
                        sx={{borderRadius: 0, padding: "4px"}}
                        onClick={handleMoveUp}
                        disabled={i <= 0}
                    >
                        <ArrowUpwardOutlined/>
                    </IconButton>
                    <IconButton
                        sx={{borderRadius: 0, padding: "4px"}}
                        onClick={handleMoveDown}
                        disabled={isLast}
                    >
                        <ArrowDownwardOutlined/>
                    </IconButton>
                </div>
                <div className={classes.stepToolbarRight}>
                    <IconButton
                        sx={{borderRadius: 0, padding: "4px"}}
                        onClick={handleDuplicate}
                    >
                        <ContentCopy/>
                    </IconButton>
                    <IconButton
                        sx={{borderRadius: 0, padding: "4px"}}
                        onClick={handleDelete}
                    >
                        <DeleteOutline/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}