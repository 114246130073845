import React, {forwardRef, useCallback, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {HorizontalAlign, VerticalAlign} from "../helpers/enums";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch, useScrollytellState,} from "../Admin/scrollytellContext";
import {EditStickyBlockContainer} from "./EditStickyBlockContainer";

const useStyles = createUseStyles(({ colors }) => ({
  container: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: ({ borderRadius }) => (borderRadius ? "8px" : "0px"),
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    pointerEvents: "auto",
    height: ({ height }) => height,
    alignItems: ({ horizontalAlign }) => {
      if (horizontalAlign === HorizontalAlign.RIGHT) {
        return "flex-end";
      } else if (horizontalAlign === HorizontalAlign.CENTER) {
        return "center";
      } else {
        return "flex-start";
      }
    },
    justifyContent: ({ verticalAlign }) => {
      if (verticalAlign === VerticalAlign.BOTTOM) {
        return "flex-end";
      } else if (verticalAlign === VerticalAlign.CENTER) {
        return "center";
      } else if (verticalAlign === VerticalAlign.SPACE) {
        return "space-evenly";
      } else {
        return "flex-start";
      }
    },
    border: ({ isAdminMode, isActiveContainer }) =>
      isAdminMode
        ? isActiveContainer
          ? `2px solid ${colors.adminBlue}`
          : `2px dashed ${colors.adminGray}`
        : "",
    "&.hover": {
      border: ({ isAdminMode, isActiveContainer }) =>
        isAdminMode &&
        `2px ${isActiveContainer ? "solid" : "dashed"} ${colors.adminBlue}`,
    },
  },
  emptyContainer: {
    minHeight: ({ minHeight }) => minHeight,
  },
  blockBackground: {
    padding: "50px",
    backgroundColor: "white",
    color: "#333",
  },
  textBackground: {
    display: "inline",
    backgroundColor: "white",
    boxShadow: "0px 0px 0px 4px white",
    color: "#333",
  },
  noBackground: {
    fontSize: "48px",
    color: "white",
  },
  headShake: {
    animationDuration: ".5s",
    animationTimingFunction: "cubic-bezier(0,.23,1,.71)",
    animationName: "headShake",
    transformOrgin: "center bottom",
  },
  "@keyframes headShake": {
    0: { transform: "translateX(0)" },
    "12.5%": { transform: "translateX(-6px) rotateY(-9deg) skewY(1deg)" },
    "37.5%": { transform: "translateX(5px) rotateY(4.5deg) skewY(-1deg)" },
    "62.5%": { transform: "translateX(-3px) rotateY(-2.25deg) skewY(0)" },
    "87.5%": { transform: "translateX(2px) rotateY(3deg)" },
    "100%": { transform: "translateX(0)" },
  },
}));

export const StickyBlockContainer = forwardRef(
  (
    { sectionId, containerId, containersCount, isAdminMode, container, hasBlocks, children },
    forwardRef
  ) => {
    const {
      backgroundColor,
      startColumn = 5,
      endColumn = 6,
      horizontalAlign = HorizontalAlign.CENTER,
      verticalAlign = VerticalAlign.CENTER,
      stepPosition = VerticalAlign.CENTER,
      gridSize,
      borderRadius,
    } = container;

    const [hover, setHover] = useState(false);
    const dispatch = useScrollytellDispatch();
    const { activeElement } = useScrollytellState();

    const isActiveContainer =
      activeElement[0] === sectionId &&
      activeElement[1] === containerId &&
      activeElement[2] === undefined;

    const classes = useStyles({
      stepPosition,
      startColumn,
      endColumn,
      horizontalAlign,
      verticalAlign,
      backgroundColor,
      isAdminMode,
      isActiveContainer,
      containersCount,
      borderRadius,
      minHeight: "3.5rem",
    });
    const ref = useRef(null);
    const setRefs = useCallback((node) => {
      ref.current = node;
      forwardRef(node);
    }, []);

    const handleMouseOver = event => {
      event.stopPropagation();
      setHover(true);
    }

    const handleMouseOut = event => {
      event.stopPropagation();
      setHover(false);
    }

    const handleClick = event => {
      if (!isActiveContainer && event.target === ref.current) {
        dispatch({
          type: SET_ACTIVE_ELEMENT,
          activeSection: sectionId,
          activeContainer: containerId,
        });
      }
    }

    return (
      <div
        id={`container-${sectionId}-${containerId}`}
        className={`${classes.container} ${isActiveContainer ? "active" : ""} ${
          hover ? "hover" : ""
        } ${hasBlocks ? "" : classes.emptyContainer}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        ref={setRefs}
      >
        <EditStickyBlockContainer
            sectionId={sectionId}
            containerId={containerId}
            isAdminMode={isAdminMode}
            gridSize={gridSize}
            container={container}
        />
        {children}
      </div>
    );
  }
);
