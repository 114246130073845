import {X} from "lucide-react";
import React from "react";
import {createUseStyles} from "react-jss";
import {AlternativeLetter} from "./AlternativeLetter";
import {AlternativeContainer} from "./AlternativeContainer";

const useStyle = createUseStyles(({ colors }) => ({
    wrongAnswer: {
        backgroundColor: colors.carnation,
    },
}));

export const AlternativeWrong = ({alternative, whiteBorder=false, children}) => {
    const classes = useStyle()
    return (
        <AlternativeContainer
            variantClass={classes.wrongAnswer}
            whiteBorder={whiteBorder}
        >
            <AlternativeLetter>
                {alternative.toUpperCase()}
            </AlternativeLetter>
            <X />
            {children}
        </AlternativeContainer>
    );
}