import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useFonts} from "@ekultur/react-fonts";
import {allFonts} from "@ekultur/fonts";


export const useFontsUsed = ({loadAll=false}) => {
    const {story} = useScrollyTellProps();
    const [fontsInUse, setFontsInUse] = useState([story?.settings?.defaultFont || "Work Sans"])
    useFonts((allFonts).filter(f => fontsInUse.includes(f.family) || loadAll))

    const findFonts = () => {
        const allBlocks = story?.sections.reduce((prev, section) => {
            const containers = section?.containers.reduce((prevBlocks, container) => {
                return [
                    ...prevBlocks,
                    ...container?.blocks
                ]
            }, [])

            return [...prev, ...containers]
        }, [])

        const regex = /"fontfamily-(.+?)"/gm;
        const blocksWithInlineFont = allBlocks.filter(a => a.text?.search("font-family")).map(a => a.text).toString()
        setFontsInUse([
            ...new Set([...blocksWithInlineFont.matchAll(regex)].map(m => m[1]))
        ])
    }

    useDeepCompareEffect(findFonts, [story])
}