import {useEffect, useState} from "react";
import {getDistance} from 'ol/sphere';

export const useDistance = ({to, from}) => {
    const [distance, setDistance] = useState(0)

    useEffect(() => {
        if(from && to) {
            setDistance(Math.floor((getDistance(from, to) / 1000)))
        }

    }, [to, from]);

    return distance
}