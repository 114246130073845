import React from "react";
import {createUseStyles} from "react-jss";
import {useContrastApproved} from "../helpers/useContrast";
import {theme} from "@ekultur/vm";
import {useProgressState} from "../progress/progressContext";

const useStyle = createUseStyles({
    lockBlock: {
    },
    '@keyframes fadezoom': {
        '100%': {
            opacity: 0,
            transform: 'scale(1.05)'
        }
    },
    '@keyframes jump': {
        '0%': {
            transform: "scale(1)",
            filter: "drop-shadow(0 1px 2px rgba(0, 0, 0, .15))",
        },
        '100%': {
            transform: "scale(1.15)",
            filter: "drop-shadow(0 4px 20px rgba(0, 0, 0, .1))",
        }
    },
    textBlock: {
        width: "100%",
        color: ({contrastApproved}) => contrastApproved ? theme.colors.white : theme.colors.black
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        color: ({contrastApproved}) => contrastApproved ? theme.colors.white : theme.colors.black,
        marginBottom: "10px",
    },
    unlockedIcon: {
        animation: ".4s $jump 3s ease 5 alternate"
    },
    unlockedShackle: {
        animation: "$fadezoom 0.4s 0s forwards",
    },
    unlockedPadlock: {
        animation: "$fadezoom 2s 0.5s forwards",
    }
});

export const LockBlock = ({
                              sectionId,
                              lock,
                              backgroundColor
                          }) => {
    const {locks} = useProgressState()

    const unlocked = Boolean(!locks.find(lock => lock.sectionId === sectionId))

    const contrastApproved = useContrastApproved(
        backgroundColor !== undefined
            ? backgroundColor.substring(0, 7)
            : theme.colors.white,
        theme.colors.white
    );

    const classes = useStyle({
        contrastApproved: contrastApproved
    });

    const label = {
        true: lock.unlockedLabel,
        false: lock.lockedLabel
    }

    return (
        <div
            className={classes.lockBlock}
        >
            <div className={classes.icon}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill={"none"}
                    className={unlocked ? classes.unlockedIcon : ""}
                >
                    <g className={unlocked ? classes.unlockedPadlock : ""}>
                        <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={unlocked ? classes.unlockedShackle : ""}
                        />
                        <path
                            d="M7 11V6.99997C6.99876 5.76002 7.45828 4.56384 8.28938 3.64364C9.12047 2.72344 10.2638 2.14487 11.4975 2.02026C12.7312 1.89565 13.9671 2.23387 14.9655 2.96928C15.9638 3.70469 16.6533 4.78482 16.9 5.99997"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>

                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path id="XMLID_225_"
                              d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>
                    </g>
                </svg>
            </div>
            <div className={classes.textBlock}>
                {label[unlocked]}
            </div>
        </div>
    )
};
