import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {useScrollyTellProps,} from "../Admin/scrollytellContext";
import {StickySectionSidebar} from "./StickySectionSidebar";
import {updateSection} from "../Admin/service";
import {WallpaperButton} from "../section-toolbar/WallpaperButton";
import {StickySectionButton} from "../section-toolbar/StickySectionButton";
import {BackgroundColorButton} from "../section-toolbar/BackgroundColorButton";
import {HeightButton} from "../section-toolbar/HeightButton";
import {GridSizeButton} from "../section-toolbar/GridSizeButton";
import {ShowGridButton} from "../section-toolbar/ShowGridButton";
import {VerticalAlignButton} from "../section-toolbar/VerticalAlignButton";
import {SubMenu} from "../section-toolbar/SubMenu";
import {MapZoomInButton} from "../section-toolbar/MapZoomInButton";
import {MapZoomOutButton} from "../section-toolbar/MapZoomOutButton";
import {MapDirectionsButton} from "../section-toolbar/MapDirectionsButton";

const useStyles = createUseStyles(({colors}) => ({
    editSectionPanel: {
        position: "absolute",
        display: "flex",
        top: "2px",
        left: "2px",
        pointerEvents: "none",
        height: "100%",
        zIndex: 15,
    },
    editSectionMenu: {
        backgroundColor: colors.adminBlue,
        borderRadius: "2px",
        height: "40px",
        color: colors.white,
        position: "sticky",
        top: "2px",
        left: "2px",
        pointerEvents: "all",
    },
}));

export const EditSectionPanel = ({sectionId, section}) => {
    const [currentMenu, setCurrentMenu] = useState("")
    const classes = useStyles();
    const {story, onChange} = useScrollyTellProps();

    const handleButtonClick = option => {
        if(option !== currentMenu) {
            setCurrentMenu(option)
        }
    }

    const handleCloseMenu = () => {
        setCurrentMenu("")
    }

    const handleUpdate = (property, value) => {
        onChange(
            updateSection(story, sectionId, {
                [property]: value
            })
        )
    }

    return (
        <div className={classes.editSectionPanel}>
            <AdminIconBar className={classes.editSectionMenu}>
                <MapDirectionsButton
                    templateId={section.templateId}
                />
                <MapZoomInButton
                    templateId={section.templateId}
                    zoom={section.zoom}
                    onUpdate={handleUpdate}
                />
                <MapZoomOutButton
                    templateId={section.templateId}
                    zoom={section.zoom}
                    onUpdate={handleUpdate}
                />
                <WallpaperButton
                    templateId={section.templateId}
                    onClick={handleButtonClick}
                />
                <StickySectionButton
                    templateId={section.templateId}
                    onClick={handleButtonClick}
                />
                <BackgroundColorButton
                    templateId={section.templateId}
                    onClick={handleButtonClick}
                />
                <HeightButton
                    sectionType={section.type}
                    onClick={handleButtonClick}
                />
                <GridSizeButton
                    gridSize={section.gridSize}
                    onUpdate={handleUpdate}
                />
                <ShowGridButton />
                <VerticalAlignButton
                    verticalAlign={section.verticalAlign}
                    onClick={handleButtonClick}
                />
                <SubMenu
                    section={section}
                    sectionId={sectionId}
                    currentMenu={currentMenu}
                    onCloseMenu={handleCloseMenu}
                />
                {section?.templateId === 6 && (
                    <StickySectionSidebar
                        isOpen={"stickySectionSidebar" === currentMenu}
                        onClose={handleCloseMenu}
                        section={section}
                        sectionId={sectionId}
                    />
                )}
            </AdminIconBar>
        </div>
    );
};
