import {getBackgroundPositionByFocusPoint} from "../helpers/helpers";
import React from "react";
import {createUseStyles} from "react-jss";
import {BackgroundImage} from "./BackgroundImage";

const useStyles = createUseStyles({
    background: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "opacity 400ms ease-in-out",
        opacity: 0,
        "&.active": {
            opacity: "1 !important",
        },
        backgroundPositionX: ({backgroundPositionX}) => backgroundPositionX + "%",
        backgroundPositionY: ({backgroundPositionY}) => backgroundPositionY + "%",
    },
});

export const OneOfMultiBackgrounds = ({background, gradient, active, opacity}) => {
    const {
        backgroundPositionX,
        backgroundPositionY
    } = getBackgroundPositionByFocusPoint(background?.x, background?.y);

    const classes = useStyles({
        backgroundPositionX,
        backgroundPositionY,
        gradient: gradient
    })

    const backgroundImage = background?.image

    return (
        <div
            className={`${classes.background} ${
                active ? "active" : ""
            }`}
            style={{
                opacity: opacity,
                backgroundSize: !background?.image && "auto",
                backgroundRepeat: !background?.image && "repeat",
            }}
        >
            <BackgroundImage
                backgroundImage={backgroundImage}
                positionX={backgroundPositionX}
                positionY={backgroundPositionY}
                gradient={gradient}
            />
        </div>
    )
}