import {createContext, useContext, useReducer} from "react";

const BlockStateContext = createContext(undefined)
const BlockDispatchContext = createContext(undefined)

export const CONTENT_MODAL_OPENED = 'contentModalOpened'
export const CONTENT_MODAL_CLOSED = 'contentModalClosed'

export const POPOVER_OPENED = 'popoverOpened'
export const POPOVER_CLOSED = 'popoverClosed'

const initialState = {
    showContentModal: false,
    anchorEl: null
}

const blockReducer = (state, action) => {
    console.debug({action})
    switch(action.type) {
        case CONTENT_MODAL_OPENED:
            return {
                ...state,
                showContentModal: true
            }
        case CONTENT_MODAL_CLOSED:
            return {
                ...state,
                showContentModal: false
            }
        case POPOVER_OPENED:
            return {
                ...state,
                anchorEl: action.payload
            }
        case POPOVER_CLOSED:
            return {
                ...state,
                anchorEl: null
            }
        default:
            throw new Error(`Unsupport action type ${action.type}`)
    }
}

export const BlockProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        blockReducer,
        initialState,
        undefined
    )

    return (
        <BlockStateContext.Provider value={state}>
            <BlockDispatchContext.Provider value={dispatch}>
                {children}
            </BlockDispatchContext.Provider>
        </BlockStateContext.Provider>
    )
}

export const useBlockState = () => {
    const context = useContext(BlockStateContext)
    if (undefined === context) {
        throw new Error('useBlockState must be used within a BlockProvider')
    } else {
        return context
    }

}

export const useBlockDispatch = () => {
    const context = useContext(BlockDispatchContext)
    if (undefined === context) {
        throw new Error('useBlockDispatch must be used within a BlockProvider')
    } else {
        return context
    }

}