import React from "react";
import {AlternativeLetter} from "./AlternativeLetter";
import {AlternativeContainer} from "./AlternativeContainer";
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    clickableAlternative: {
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7,
        },
    }
})

export const AlternativeNeutral = ({disabled, onClick, alternative, whiteBorder=false, children}) => {
    const classes = useStyle()
    return (
        <AlternativeContainer
            onClick={disabled ? null : onClick}
            variantClass={disabled ? "" : classes.clickableAlternative}
            whiteBorder={whiteBorder}
        >
            <AlternativeLetter>
                {alternative.toUpperCase()}
            </AlternativeLetter>
            {children}
        </AlternativeContainer>
    );
}