import {useContrastApproved} from "../helpers/useContrast";
import {theme} from "@ekultur/vm";
import {useReadMoreIcon} from "./useReadMoreIcon";
import {Button} from "@mui/material";


export const ReadMoreButton = ({
   icon="info",
   disabled = false,
   buttonColor = "#1B8751",
   onClick,
   buttonText = "Vis mer"
}) => {
    const contrastApproved = useContrastApproved(
        buttonColor !== undefined
            ? buttonColor.substring(0, 7)
            : theme.colors.white,
        theme.colors.white
    );
    const color = contrastApproved ? theme.colors.white : theme.colors.black

    const Icon = useReadMoreIcon({icon})

    const handleClick = event => onClick?.(event)

    return (
        <Button
            type={"button"}
            onClick={handleClick}
            disabled={disabled}
            sx={{
                border: "none",
                outline: "none",
                display: "inline-flex",
                borderRadius: "999px",
                backgroundColor: buttonColor,
                padding: "16px 24px",
                gap: "8px",
                color: color,
                fontSize: "18px",
                lineHeight: "18px",
                fontWeight: "700",
                alignItems: "center",
                '&:hover': {
                    cursor: "pointer",
                    filter: "brightness(110%)",
                    backgroundColor: buttonColor
                }
            }}
        >
            {Icon}
            {buttonText}
        </Button>
    )
}