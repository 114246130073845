import {BlockSpacer} from "./BlockSpacer";
import {Block} from './Block'
import {AdminBlock} from "./AdminBlock";

export const Blocks = ({blocks, sectionId, containerId, isAdminMode}) => {
    const numberOfBlocks = blocks?.length;
    return blocks?.map((block, i) => (
            <BlockSpacer
                key={`blockspacer-${i}`}
                first={0===i}
                last={1 + i === numberOfBlocks}
            >
                <AdminBlock
                    block={block}
                    blockId={i}
                    sectionId={sectionId}
                    containerId={containerId}
                    isAdminMode={isAdminMode}
                >
                    <Block
                        block={block}
                        blockId={i}
                        sectionId={sectionId}
                        containerId={containerId}
                    />
                </AdminBlock>
            </BlockSpacer>
        )
    )
}