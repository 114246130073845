import {EditImageBlock} from "./EditImageBlock";
import React from "react";
import {useContrastApproved} from "../helpers/useContrast";
import {theme} from "@ekultur/vm";
import {useSection} from "../Section/useSection";
import {useContainer} from "../Container/useContainer";

export const AdminImageBlock = ({
                                    sectionId,
                                    containerId,
                                    block,
                                    onUpdate,
                                    isActiveBlock,
                                    children
                                }) => {
    const section = useSection(sectionId)
    const container = useContainer({
        sectionId: sectionId,
        containerId: containerId
    })
    const containerColor = container?.backgroundColor || "#ffffff"
    const sectionColor = section?.backgroundColor || "#ffffff"

    const contrastApproved = useContrastApproved(
        containerColor !== undefined
            ? containerColor.substring(0, 7)
            : sectionColor !== undefined
                ? sectionColor.substring(0, 7)
                : theme.colors.white,
        theme.colors.white
    )

    return (
        <EditImageBlock
            block={block}
            contrastApproved={contrastApproved}
            onUpdate={onUpdate}
            isActiveBlock={isActiveBlock}
        >
            {children}
        </EditImageBlock>
    )
}