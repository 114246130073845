import React from "react";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@mui/icons-material";
import { createUseStyles } from "react-jss";
import { Divider, IconButton } from "@mui/material";
import { getHasInlineStyleByKey, toggleInlineStyle } from "./helpers";

const useStyles = createUseStyles(({ colors }) => ({
  inlineOptions: {
    order: 5,
    display: "flex",
  },
  iconButton: {
    "&.active": {
      backgroundColor: `${colors.black}33`,
    },
  },
}));

export const InlineOptions = ({ onChange, editorState }) => {
  const classes = useStyles();

  const options = [
    {
      type: "BOLD",
      Icon: FormatBold,
    },
    {
      type: "ITALIC",
      Icon: FormatItalic,
    },
    {
      type: "UNDERLINE",
      Icon: FormatUnderlined,
    },
  ];

  return (
    <div className={classes.inlineOptions}>
      {options.map(({ type, Icon }) => (
        <IconButton
          key={type}
          className={`${classes.iconButton} ${
            getHasInlineStyleByKey(editorState, type) ? "active" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            onChange(toggleInlineStyle(editorState, type));
          }}
        >
          <Icon sx={{ color: "#00000099" }} size="small" />
        </IconButton>
      ))}
      <Divider orientation="vertical" variant="middle" flexItem />
    </div>
  );
};
