import {Tooltip} from "@mui/material";
import {Help} from "@mui/icons-material";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const ScrollLockTooltip = () => {
    const t = useScrollytellTranslation()

    return (
        <Tooltip
            title={t("VideoBlockScrollLockTooltip", "Forhindrer brukeren fra å lese videre før videoen er ferdigspilt.")}
            sx={{
                color: "grey",
                ml: 1
            }}
        >
            <Help />
        </Tooltip>
    )
}