import {FormControl, TextField} from "@mui/material";
import React from "react";

export const EditAlternative = ({label, alternativeLetter, value = "", onChange, contrastApproved, children}) => {

    const handleChange = event => {
        onChange(alternativeLetter, event.target.value)
    }

    return (
        <FormControl
            sx={{
                display: "flex",
                flexDirection: "row",
                ml: 1
            }}
        >
            <TextField
                label={label}
                value={value}
                onChange={handleChange}
                sx={{
                    m: 1,
                    'fieldset:not(:focus)': {
                        color: contrastApproved ? "white" : "black",
                        borderColor: contrastApproved ? "white !important" : "black",
                    },
                    'input': {
                        color: contrastApproved ? "white" : "black",
                    }
                }}
                InputLabelProps={{
                    sx: {
                        color: contrastApproved ? "white !important" : "black",
                    }
                }}
            />
            {children}
        </FormControl>
    )
}