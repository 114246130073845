import {Layers} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React, {useState} from "react";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {FormControl, InputLabel, MenuItem, Popover, Select, Stack} from "@mui/material";
import {ReadMoreColorField} from "./ReadMoreColorField";


export const BackdropSettings = ({transparency="0.8", backgroundColor="#000000", onChange}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const t = useScrollytellTranslation()

    const options = [
        {
            value: "0.9",
            label: "10%"
        },
        {
            value: "0.8",
            label: "20%"
        },
        {
            value: "0.7",
            label: "30%"
        },
        {
            value: "0.6",
            label: "40%"
        }
    ]

    const handleChangeTransparency = event =>  {
        onChange({
            backgroundColor: backgroundColor,
            transparency: event.target.value
        })
    }

    const handleSelectColor = (_property, color) => {
        onChange({
            backgroundColor: color,
            transparency: transparency
        })
    }

    const handleClick = event => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)

    return (
        <>
            <AdminIconBarItem
                Icon={Layers}
                tooltip={t('editContainerPanel.backdropTooltip', 'Bakteppe')}
                onClick={handleClick}
            />
            <Popover
                id={"backdrop-settings-popover"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        p: 2
                    },
                }}

             >
                <Stack
                    direction={"column"}
                    spacing={2}
                    sx={{
                        width: "100%"
                    }}
                >
                    <FormControl
                        fullWidth={true}
                    >
                        <InputLabel
                            id={"backdrop-transparency-label"}
                        >
                            {t('editContainerPanel.transparencySelectorLabel', "Bakteppe gjennomsiktighet")}
                        </InputLabel>
                        <Select
                            labelId={"backdrop-transparency-label"}
                            label={t('editContainerPanel.transparencySelectorLabel', "Bakteppe gjennomsiktighet")}
                            id={"backdrop-transparency"}
                            value={transparency}
                            onChange={handleChangeTransparency}
                        >
                            {options.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <ReadMoreColorField
                        label={"Bakteppe farge"}
                        property={"backgroundColor"}
                        value={backgroundColor}
                        autoFocus={false}
                        onChange={handleSelectColor}
                        allowAlpha={false}
                    />
                </Stack>

            </Popover>
        </>
    )
}