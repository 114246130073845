export const getBackgroundImageString = (
  backgroundImage,
  gradient,
  isAdminMode = false,
  hardcodedDimension=null
) => {
  let cssString = "";
  if (gradient) {
    cssString += `linear-gradient(${gradient.direction}, ${gradient.fromColor} ${gradient.fromPercent}%, ${gradient.toColor} ${gradient.toPercent}%)`;
  }
  if (backgroundImage && gradient) {
    cssString += ",";
  }
  if (typeof backgroundImage === "string" && backgroundImage) {
    cssString += `url(https://ems.dimu.org/image/${backgroundImage}?dimension=${getImageDimension(hardcodedDimension)})`
  }
  return cssString !== "" ? cssString : "";
};

export const getImageDimension = hardcodedDimension => {
  if(hardcodedDimension) {
    return hardcodedDimension
  }
  const { x} = getScreenResolution();
  if(x < 1024) {
    return "1024x1024"
  } else if (x < 2048) {
    return "2048x2048"
  } else {
    return "3072"
  }
};


export const isJsonString = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

export const getScreenResolution = () => {
  const x = window.screen.width * window.devicePixelRatio;
  const y = window.screen.height * window.devicePixelRatio;
  return { x, y };
};

export const getBackgroundPositionByFocusPoint = (x, y) => {
  const backgroundPositionX = x ? ((x + 1) * 100) / 2 : 50
  const backgroundPositionY = y ? ((-y + 1) * 100) / 2 : 50
  return { backgroundPositionX, backgroundPositionY };
};

