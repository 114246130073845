import React, {useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {CenterFocusWeakOutlined, DeleteOutlined, ImageOutlined,} from "@mui/icons-material";
import {Divider} from "@mui/material";

import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {useClickOutside} from "../common/useClickOutside";
import {updateSection} from "../Admin/service";
import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {ImageFocusPicker} from "../common/ImageFocusPicker";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
    subMenu: {
        top: "41px",
        left: "2px",
        position: "absolute",
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
        backgroundColor: "white",
        background: "#FFFFFF",
        boxShadow:
            "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
    },
    subMenuItem: {
        "& svg": {
            fill: "#00000099",
        },

        "&:hover svg": {
            fill: "#3F51B5",
        },
        "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto",
            "& svg": {
                fill: "#00000033",
            },
        },
    },
    imageFocusPicker: {
        width: "350px",
    },
});

export const SectionBackgroundImageSubMenu = ({
                                                  section,
                                                  sectionId,
                                                  onClose,
                                              }) => {
    const t = useScrollytellTranslation();
    const backgroundMenuRef = useRef();
    const {story, onChange, onMediaSelect} = useScrollyTellProps();
    const [showImageFocus, setShowImageFocus] = useState(false);
    useClickOutside(backgroundMenuRef, () => onClose());
    const classes = useStyles();

    const setBackgroundImage = () => {
        if (onMediaSelect) {
            onMediaSelect("image", (dmsId) => {
                onChange(updateSection(story, sectionId, {backgroundImage: dmsId}));
            });
        } else {
            console.warn("onMediaSelect is undefined");
        }
    };

    const removeBackgroundImage = () => {
        onChange(updateSection(story, sectionId, {backgroundImage: undefined}));
    };

    const setBackgroundPosition = ({x, y}) => {
        onChange(
            updateSection(story, sectionId, {
                backgroundFocus: {x, y},
            })
        );
    };

    return (
        <AdminIconBar ref={backgroundMenuRef} className={classes.subMenu}>
            <AdminIconBarItem
                Icon={ImageOutlined}
                onClick={setBackgroundImage}
                tooltip={t(
                    "sectionBackgroundImageSubMenu.backgroundImageTooltip",
                    "Velg bakgrunnsbilde"
                )}
                classname={classes.subMenuItem}
            />
            <AdminIconBarItem
                Icon={CenterFocusWeakOutlined}
                onClick={() => setShowImageFocus(true)}
                tooltip={t(
                    "sectionBackgroundImageSubMenu.backgroundImageFocusTooltip",
                    "Sett bakgrunnsbilde fokus"
                )}
                classname={classes.subMenuItem}
            />
            <Divider orientation="vertical" variant="middle" flexItem/>
            <AdminIconBarItem
                Icon={DeleteOutlined}
                onClick={removeBackgroundImage}
                tooltip={t(
                    "sectionBackgroundImageSubMenu.backgroundImageDeleteTooltip",
                    "Fjern bakgrunnsbilde"
                )}
                disabled={!section.backgroundImage}
                classname={classes.subMenuItem}
            />
            {showImageFocus && (
                <ImageFocusPicker
                    x={section.backgroundFocus?.x}
                    y={section.backgroundFocus?.y}
                    className={classes.imageFocusPicker}
                    imageUrl={section.backgroundImage}
                    onFocusChanged={setBackgroundPosition}
                    onClose={() => setShowImageFocus(false)}
                />
            )}
        </AdminIconBar>
    );
};
