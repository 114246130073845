import React from "react";
import {createUseStyles} from "react-jss";
import {IconButton, Tooltip} from "@mui/material";
import {ThreeDRotation} from "@mui/icons-material";
import {ModelViewer} from "@ekultur/modelviewer-microfrontend";

import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {ThreeDMenu} from "./ThreeDMenu";
import {WithModelViewer} from "./WithModelViewer";
import {AdminActions} from "../Block/AdminActions";

const useStyles = createUseStyles({
    editThreeDBlock: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        height: "600px",
        "&:focus": {
            backgroundColor: "#ffffff22",
        },
    },
    loadingWrapper: {
        height: "80%",
        width: "100%",
    },
    model: {
        height: "100%",
        width: "100%",
    },
    threeDPlaceholder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#A6A6A661",
        height: "100%",
    },
    threeDUpload: {
        top: "2px",
        left: "calc(100% - 52px)",
        cursor: "pointer",
        height: "100%",
    },
})

export const EditThreeDBlock = ({sectionId, containerId, blockId, block, onUpdate, isActiveBlock, children}) => {
    const {scene, enablePan, enableZoom, showToolbar} = block;
    const t = useScrollytellTranslation();
    const {onMediaSelect} = useScrollyTellProps();

    const classes = useStyles({
        isActiveBlock,
    });

    const updateProperty = (property, value) => onUpdate({
        ...block,
        [property]: value,
    })

    const setThreeDModel = () => {
        if (onMediaSelect) {
            onMediaSelect("model", (dmsId) => {
                updateProperty(
                    'scene',
                    {
                        position: [0, 0, -2],
                        model: {
                            type: "Object",
                            url: `https://ems.dimu.org/file/${dmsId}`,
                            position: [0, 0, 0],
                            rotation: [0, 0, 0],
                            scale: 1
                        },
                    }
                )
            })
        } else {
            updateProperty(
                'scene',
                {
                    position: [0, 0, -2],
                    model: {
                        type: "Object",
                        url: `https://ems.dimu.org/file/013AitqNDuBn`,
                        position: [0, 0, 0],
                        rotation: [0, 0, 0],
                        scale: 1
                    },
                }
            )
            console.warn("onMediaSelect is undefined");
        }
    };

    const withTransparentBackground = scene => {
        if (scene) {
            if (scene.model) {
                return {
                    ...scene,
                    model: {
                        ...scene.model,
                        transparentBackground: true
                    }
                }
            } else {
                return {
                    ...scene,
                    model: {
                        transparentBackground: true
                    }

                }
            }
        } else {
            return scene
        }
    }

    const data = withTransparentBackground(scene)

    return (
        <div
            className={classes.editThreeDBlock}
        >
            {scene && (
                <WithModelViewer classname={classes.loadingWrapper}>
                    <div className={classes.model}>
                        <ModelViewer
                            data={data}
                            showToolbar={showToolbar}
                            enablePan={enablePan}
                            enableZoom={enableZoom}
                            onProgressChange={() => {
                            }}
                            containerId={`edit-modelviewer-${sectionId}-${containerId}-${blockId}`}
                        />
                    </div>
                </WithModelViewer>
            )}
            <label
                className={classes.threeDUpload}
                style={{position: block.scene ? "absolute" : "initial"}}
                htmlFor="imageBlockUpload"
                onClick={() => setThreeDModel()}
            >
                {!block.scene && (
                    <div className={classes.threeDPlaceholder}>
                        <Tooltip
                            title={t("editThreeDBlock.uploadImage", "Velg en 3D modell")}
                        >
                            <IconButton
                                aria-label="upload 3d model"
                                component="span"
                                sx={{
                                    backgroundColor: "#FAFAFA",
                                }}
                            >
                                <ThreeDRotation/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </label>
            <AdminActions
                show={isActiveBlock}
            >
                {children}
                {block.scene && (
                    <ThreeDMenu
                        block={block}
                        onUpdateProperty={updateProperty}
                        handleSetThreeDModel={() => setThreeDModel()}
                    />
                )}
            </AdminActions>
        </div>
    )
}
