import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import CustomColorPicker from "./CustomColorPicker";

const useStyles = createUseStyles({
  colorPicker: {
    position: "absolute",
    left: "55px",
    display: "flex",
    zIndex: 10000,
  },
  colorPickerButtonWrapper: {},
  colorPickerButton: {
    height: "28px",
    width: "28px",
    boxShadow: "0px 0px 0px 0.7px #E0E0E0",
    borderRadius: "39.2px",
    margin: "4px",
  },
});

export const ColorPicker = ({
  open,
  color,
  onSave,
  onClose,
  templateColors = [],
}) => {
  const [current, setCurrent] = useState(color);
  let ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose(event);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const decimalToHex = (alpha) =>
    alpha === 0 ? "00" : Math.round(255 * alpha).toString(16);

  const hexToRgba = (hex) => {
    const r = parseInt(hex.substring(1, 3), 16) || 0;
    const g = parseInt(hex.substring(3, 5), 16) || 0;
    const b = parseInt(hex.substring(5, 7), 16) || 0;
    const a = parseInt(hex.substring(7, 9), 16) || 1;
    return { r, g, b, a };
  };

  const classes = useStyles();
  return (
    open && (
      <div className={classes.colorPicker} ref={ref}>
        <CustomColorPicker
          color={current}
          onChange={(c) => {
            setCurrent(`${c.hex}${decimalToHex(c.rgb.a)}`);
          }}
          onChangeComplete={(c) => onSave(`${c.hex}${decimalToHex(c.rgb.a)}`)}
          templateColors={templateColors}
        />
      </div>
    )
  );
};

export const ColorPickerButton = ({ color, onChange, templateColors }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classes.colorPickerButtonWrapper}>
      <div
        className={classes.colorPickerButton}
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(true)}
      />
      <ColorPicker
        open={isOpen}
        onClose={() => setIsOpen(false)}
        color={color}
        onSave={onChange}
        templateColors={templateColors}
      />
    </div>
  );
};
