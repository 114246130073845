import React, {useEffect, useRef, useState} from "react";
import {ButtonBase, IconButton, Tooltip} from "@mui/material";
import {VideoLibrary} from "@mui/icons-material";
import {createUseStyles} from "react-jss";
import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {VideoPlayer} from "../common/VideoPlayer";
import {VideoMenu} from "./VideoMenu";
import {useInView} from "react-intersection-observer";
import {getBackgroundImageString} from "../helpers/helpers";
import {AdminActions} from "../Block/AdminActions";

const placeholderImage = "013AjtNogoyu";

const useStyles = createUseStyles(({colors}) => ({
    videoBlockEdit: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        "&:focus": {
            backgroundColor: "#ffffff22",
        },
    },
    videoUpload: {
        top: "2px",
        left: "calc(100% - 52px)",
        cursor: "pointer",
        width: "100%"
    },
    videoBlockPlaceholder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#A6A6A661",
        minHeight: "400px",
        width: "100%"
    },
    videoBlockEditIconButton: {
        height: "48px !important",
        width: "48px !important",
        minWidth: "48px !important",
        backgroundColor: colors.white + " !important",
        boxShadow: `0px 1px 2px ${colors.black}0F, 0px 1px 3px ${colors.black}1A !important`,
        border: `1px solid ${colors.black}0D`,
        borderRadius: "4px !important",
        opacity: 0.8,
        cursor: "pointer",
    },
    videoBlockEditIconText: {
        fontSize: "14px",
        fontStyle: "italic",
    },
    videoBlockCaption: {
        padding: "16px",
    },
    videoBlockEditButton: {
        height: "48px",
    },
}));

export const EditVideoBlock = ({block, onUpdate, isActiveBlock, children}) => {
    const t = useScrollytellTranslation();
    const {onMediaSelect} = useScrollyTellProps();
    const [isPlaying, setIsPlaying] = useState(false);
    const {ref, inView} = useInView({
        threshold: 0.9,
    });
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            if (block.autoPlay) {
                if (inView && !isPlaying) {
                    setIsPlaying(true);
                    videoRef.current.play();
                }
                if (!inView && isPlaying) {
                    setIsPlaying(false);
                    videoRef.current.pause();
                }
            }
        }
    }, [inView, block, videoRef]);

    const classes = useStyles({
        isActiveBlock,
    });

    const updateProperty = (property, value) => onUpdate({
        ...block,
        [property]: value,
    })

    const setVideo = () => {
        if (onMediaSelect) {
            onMediaSelect("video", (dmsId) => {
                updateProperty('videoUrl', dmsId)
            });
        } else {
            console.warn("onMediaSelect is undefined");
        }
    };

    const handleUpdate = () => onUpdate(block)

    return (
        <div
            className={classes.videoBlockEdit}
            ref={ref}
        >
            {block.videoUrl && (
                <VideoPlayer
                    autoPlay={block?.autoPlay}
                    ref={videoRef}
                    videoUrl={`https://ems.dimu.org/streaming/adaptive/${block.videoUrl}/index.m3u8`}
                    controls={block.showControls}
                    loop={block.loop}
                    muted
                    style={{
                        width: "100%",
                        objectFit: "contain",
                    }}
                />
            )}
            <ButtonBase
                className={classes.videoUpload}
                style={{position: block.videoUrl ? "absolute" : "initial"}}
                htmlFor="videoBlockUpload"
                onClick={setVideo}
            >
                {!block.videoUrl && (
                    <div
                        className={classes.videoBlockPlaceholder}
                        style={{
                            backgroundImage: getBackgroundImageString(
                                placeholderImage,
                                undefined,
                                true
                            ),
                            backgroundRepeat: "repeat",
                            backgroundSize: "auto",
                        }}
                    >
                        <Tooltip title={t("editVideoBlock.uploadImage", "Velg en video")}>
                            <IconButton
                                aria-label="upload video"
                                component="span"
                                sx={{backgroundColor: "#FAFAFA"}}
                            >
                                <VideoLibrary/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </ButtonBase>
            <AdminActions
                show={isActiveBlock}
            >
                {block.videoUrl && (
                    <VideoMenu
                        block={block}
                        onUpdateProperty={updateProperty}
                        handleSetVideo={() => setVideo()}
                    />
                )}
                {children}
            </AdminActions>
        </div>
    );
};
