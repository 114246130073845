import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {VerticalAlign} from "../helpers/enums";
import {VerticalAlignBottom, VerticalAlignCenter, VerticalAlignTop} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";

export const VerticalAlignButton = ({verticalAlign, onClick}) => {
    const t = useScrollytellTranslation();
    const handleClick = () => onClick("verticalAlignMenu")

    return (
        <AdminIconBarItem
            Icon={
                verticalAlign === VerticalAlign.BOTTOM
                    ? VerticalAlignBottom
                    : verticalAlign === VerticalAlign.CENTER
                        ? VerticalAlignCenter
                        : VerticalAlignTop
            }
            onClick={handleClick}
            tooltip={t(
                "editContainerPanel.setVerticalAlignTooltip",
                "Vertikal justering"
            )}
            tooltipPosition="top"
        />
    )
}