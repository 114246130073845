import React, {useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {useScrollPercentage} from "../Block/useScrollPercentage";
import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {SectionTypes} from "../helpers/enums";
import Hls from "hls.js";
import {useContentHeight} from "../map/useContentHeight";

const useStyle = createUseStyles({
    videoScroll: {
        backgroundColor: ({backgroundColor}) => backgroundColor,
        margin: ({margin}) => margin,
        padding: ({padding}) => padding,
        width: "100%",
    },
    content: {
        height: ({contentHeight}) => `${contentHeight}px`,
        width: "100%",
        position: "sticky",
        top: 0,
    },
    videoPlayer: {
        // Prevent frames on iOS
        maskImage: "-webkit-radial-gradient(white, black)",
        backfaceVisibility: "hidden"
    }
});

export const VideoScrollBlock = ({
                                     block,
                                     sectionId,
                                 }) => {
    const {
        videoUrl,
        playbackConstant = 200,
        backgroundColor,
        margin,
        padding,
    } = block;
    const {story} = useScrollyTellProps();
    const [metaLoaded, setMetaLoaded] = useState(false)
    const steps =
        story.sections[sectionId].type === SectionTypes.STICKY &&
        story.sections[sectionId].containers.length;
    const [wrapperHeight, setWrapperHeight] = useState();
    const root = document.getElementById("scrollytell-content");
    const threshold = steps > 5 ? 100 / (steps * 100) : 0.2
    const option =
        root !== null
            ? {root, threshold: threshold}
            : {threshold: threshold};
    const [wrapperRef, percentage] = useScrollPercentage(option);
    const videoRef = useRef();
    const contentHeight = useContentHeight()
    const classes = useStyle({backgroundColor, margin, padding});
    const isVisible = percentage > 0;
    const onMetadataLoaded = () => {
        setMetaLoaded(true)
    };

    useEffect(() => {
        if (metaLoaded) {
            const vid = videoRef?.current;
            if (vid) {
                let wrapperH;
                if (steps) {
                    wrapperH = `${(steps - 1) * contentHeight}px`;
                } else {
                    wrapperH = `${vid.duration * playbackConstant}px`;
                }
                setWrapperHeight(wrapperH);
            }
        }
    }, [steps, contentHeight, metaLoaded]);

    useEffect(() => {
        if (videoRef?.current) {
            videoRef?.current.setAttribute('muted', '')
        }
    }, [videoRef])

    useEffect(() => {
        if (isVisible) {
            seek();
        }
    }, [percentage]);

    const seek = () => {
        const vid = videoRef?.current;
        if (vid?.duration) {
            vid.currentTime = vid.duration * percentage;
        }
    };

    return (
        <div
            className={classes.videoScroll}
            ref={wrapperRef}
            style={{height: wrapperHeight}}
        >
            <div className={classes.content}>
                <video
                    style={{width: "100%", height: "100%"}}
                    ref={videoRef}
                    onLoadedMetadata={onMetadataLoaded}
                    className={classes.videoPlayer}
                    // iOS Hack
                    preload={Hls.isSupported() ? "auto" : "metadata"}
                    playsInline={true}
                    muted={true}
                >
                    <source
                        src={`https://ems.dimu.org/file/${videoUrl}`}
                        type="video/mp4"
                    />
                </video>

            </div>
        </div>
    )
};
