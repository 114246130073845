import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";

const useRootMargin = (offset) => {
  return `-${offset * 100}% 0px -${100 - offset * 100}% 0px`;
};

const getProgressRootMargin = (direction, offset, node, innerHeight) => {
  if (!node.current) return "0px";
  const offsetHeight = node.current.offsetHeight / innerHeight;
  if (direction === "down")
    return `${(offsetHeight - offset) * 100}% 0px ${offset * 100 - 100}% 0px`;
  return `-${offset * 100}% 0px ${
    offsetHeight * 100 - (100 - offset * 100)
  }% 0px`;
};

export const Step = ({
  children,
  data,
  handleSetLastScrollTop,
  lastScrollTop,
  onStepEnter = () => {},
  onStepLeave = () => {},
  onStepProgress = null,
  offset,
  progressThreshold,
  innerHeight,
}) => {
  const scrollTop =
    document.getElementById("scrollytell-content")?.scrollTop || 0;
  const direction = lastScrollTop < scrollTop ? "down" : "up";

  const rootMargin = useRootMargin(offset);

  const ref = useRef(null);

  const [isIntersecting, setIsIntersecting] = useState(false);
  const { ref: inViewRef, entry } = useInView({
    rootMargin,
    threshold: 0,
  });

  const progressRootMargin = useMemo(
    () => getProgressRootMargin(direction, offset, ref, innerHeight),
    [direction, offset, ref, innerHeight]
  );

  const { ref: scrollProgressRef, entry: scrollProgressEntry } = useInView({
    rootMargin: progressRootMargin,
    threshold: progressThreshold,
  });

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
      scrollProgressRef(node);
    },
    [inViewRef, scrollProgressRef]
  );

  useEffect(() => {
    if (isIntersecting) {
      const boundingBox = scrollProgressEntry?.target.getBoundingClientRect();
      const progress = Math.min(
        1,
        Math.max(
          0,
          (window.innerHeight * offset - boundingBox?.top) / boundingBox?.height
        )
      );
      onStepProgress &&
        onStepProgress({
          progress,
          data,
          element: scrollProgressEntry.target,
          entry: scrollProgressEntry,
          direction,
        });
    }
  }, [
    scrollProgressEntry,
    data,
    direction,
    isIntersecting,
    offset,
    onStepProgress,
  ]);

  useEffect(() => {
    if (entry && !entry.isIntersecting && isIntersecting) {
      onStepLeave({ element: entry.target, data, entry, direction });
      setIsIntersecting(false);
      handleSetLastScrollTop(scrollTop);
    } else if (entry && entry.isIntersecting && !isIntersecting) {
      setIsIntersecting(true);
      onStepEnter({ element: entry.target, data, entry, direction });
      handleSetLastScrollTop(scrollTop);
    }
  }, [
    entry,
    data,
    direction,
    handleSetLastScrollTop,
    isIntersecting,
    onStepLeave,
    onStepEnter,
    scrollTop,
  ]);

  return cloneElement(Children.only(children), {
    ref: setRefs,
    entry,
  });
};
