import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {useReadMoreIconAndLabels} from "./useReadMoreIconAndLabels";

export const ReadMoreIconSelect = ({autoFocus = false, label, property, value="info", onChange}) => {
    const handleChange = event => {
        onChange(property, event.target.value)
    }

    const iconsAndLabels = useReadMoreIconAndLabels()

    return (
        <FormControl
            fullWidth={true}
            autoFocus={autoFocus}
        >
            <InputLabel id="read-more-icon-select-label">{label}</InputLabel>
            <Select
                labelId="read-more-icon-select-label"
                id="read-more-icon-select"
                value={value}
                label={label}
                onChange={handleChange}
            >
                {Object.keys(iconsAndLabels).map(key => (
                    <MenuItem
                        key={key}
                        value={key}
                    >
                        <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"flex-center"}
                        >
                            {iconsAndLabels[key].icon}
                            <span>
                                {iconsAndLabels[key].label}
                            </span>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}