import React, {useState} from "react";
import {theme, Typography} from "@ekultur/vm";
import {createUseStyles} from "react-jss";
import {Alternative} from "./Alternative";
import {BLOCK_UNLOCKED, useProgressDispatch} from "../progress/progressContext";
import {RetryButton} from "./RetryButton";
import {useContainer} from "../Container/useContainer";
import {useContrastApproved} from "../helpers/useContrast";
import {useBackgroundColor} from "../Container/useBackgroundColor";

const useStyle = createUseStyles(({ colors }) => ({
  quizBlock: {
    display: "flex",
    flexDirection: "column",
    margin: ({ margin }) => margin,
    padding: ({ padding }) => padding,
    color: ({ color }) => color || colors.black,
    textAlign: ({ textAlign }) => textAlign,
    fontFamily: ({ font }) => font,
    borderRadius: ({ borderRadius }) => borderRadius,
  },
  alternatives: {
    display: "flex",
    flexDirection: "column",
    flex: 3,
  },
}));

export const QuizBlock = ({
  containerId,
  sectionId,
  blockId,
  block,
}) => {
  const {
    question,
    alternatives,
    answer,
    margin,
    padding,
    textAlign,
    font,
    borderRadius,
  } = block;

  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const progressDispatch = useProgressDispatch()
  const backgroundColor = useBackgroundColor({
    sectionId: sectionId,
    containerId: containerId
  })

  const contrastApproved = useContrastApproved(
      backgroundColor !== undefined
          ? backgroundColor.substring(0, 7)
          : theme.colors.white,
      theme.colors.white
  )

  const classes = useStyle({
    margin,
    padding,
    color: contrastApproved ? "white" : "black",
    textAlign,
    font,
    borderRadius,
  });

  const handleClick = option => {
    setSelectedAnswer(option)
    if(option === answer) {
      progressDispatch({
        type: BLOCK_UNLOCKED,
        payload: {
          sectionId: sectionId,
          containerId: containerId,
          blockId: blockId
        }
      })
    }
  }

  const handleRetry = () => setSelectedAnswer(null)
  const solved = selectedAnswer === answer

  return (
      <div className={classes.quizBlock}>
        <Typography tag="h2" size="xl" bold>
          {question}
        </Typography>
        <div className={classes.alternatives}>
          {alternatives.map(({alternative, text}) => {

            return (
                <Alternative
                    key={alternative}
                    alternative={alternative}
                    selectedAnswer={selectedAnswer}
                    onAnswerSelect={handleClick}
                    text={text}
                    solved={solved}
                    whiteBorder={contrastApproved}
                />
            );
          })}
        </div>
        <RetryButton
            onClick={handleRetry}
            show={selectedAnswer && !solved}
        />
      </div>
  )
};
