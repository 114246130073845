import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {SectionGridSize} from "../helpers/enums";
import {ViewArray, ViewColumn} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";

export const GridSizeButton = ({onUpdate, gridSize}) => {
    const t = useScrollytellTranslation();

    const handleUpdate = () => {
        onUpdate(
            'gridSize',
            gridSize === SectionGridSize.NINE_COLUMNS || gridSize === undefined
                ? SectionGridSize.TWELVE_COLUMNS
                : SectionGridSize.NINE_COLUMNS,
        )
    }

    return (
        <AdminIconBarItem
            Icon={
                gridSize === SectionGridSize.NINE_COLUMNS || gridSize === undefined
                    ? ViewColumn
                    : ViewArray
            }
            onClick={handleUpdate}
            tooltip={t("editSectionPanel.gridType", "Grid type")}
        />
    )
}