import React from "react";
import {EditContainerPanel} from "./EditContainerPanel";
import {useIsActiveContainer} from "./useIsActiveContainer";
import {useScrollyTellProps} from "../Admin/scrollytellContext";

export const EditLockContainer = ({
    sectionId,
    containerId,
    isAdminMode,
    container
}) => {
    const isActiveContainer = useIsActiveContainer({
        sectionId: sectionId,
        containerId: containerId
    })

    const { onChange, story } = useScrollyTellProps();

    const handleEdit = (property, value) => {
        let sections = story.sections
        sections[sectionId].lock = {
            ...sections[sectionId].lock,
            [property]: value
        }
        onChange({
            ...story,
            sections: sections
        })
    }

    if (isAdminMode && isActiveContainer) {
        return (
            <EditContainerPanel
                borderRadius={container?.borderRadius}
                backgroundColor={container?.backgroundColor}
                sectionId={sectionId}
                containerId={containerId}
                onEdit={handleEdit}
            />
        )
    } else {
        return null
    }
}