import {FormControl, FormControlLabel, FormLabel, RadioGroup, Radio} from "@mui/material";

export const ReadMoreTypeRadio = ({autoFocus=false, label, property, value, onChange}) => {

    const handleChange = event => {
        onChange(property, event.target.value)
    }

    return (
        <FormControl
            autoFocus={autoFocus}
        >
            <FormLabel
                id={"button-type-group-label"}
            >
                {label}
            </FormLabel>
            <RadioGroup
                aria-labelledby={"button-type-group-label"}
                name={"button-type-group"}
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel
                    value={"dialog"}
                    control={<Radio />}
                    label={"Pop-up med innhold"}
                />
                <FormControlLabel
                    value={"link"}
                    control={<Radio />}
                    label={"Ekstern lenke"}
                />
            </RadioGroup>
        </FormControl>
    )
}