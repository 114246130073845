export const demo02 = {
    appBar: {
        backgroundColor: "#171E1D",
        mode: "dark",
        exhibition: {
            name: "Ut i verden med kamera",
            description:
                "Les vår interaktive historie om og med Niels Juels' fotografier av arkitektur, bylandskap og mennesker fra sine reiser i inn- og utland.",
            url: "https://beta.virtueltmuseum.no/museum/12/exhibition/ff335e35-98ec-4e36-9c32-79428f917b69",
        },
        museum: {
            name: "Museene i Akershus",
            url: "https://beta.virtueltmuseum.no/museum/12",
        },
    },
    sections: [
        {
            type: "content",
            order: 1,
            templateId: 1,
            backgroundImage: "013Aitiph5MA",
            gradient: {
                direction: "180deg",
                fromColor: "#171E1D00",
                fromPercent: 0,
                toPercent: 100,
                toColor: "#171E1D",
            },
            backgroundColor: "#171E1D",
            minHeight: "100vh",
            verticalAlign: "center",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"acvrn","text":"Ut i verden med ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":16,"style":"color-white"},{"offset":0,"length":16,"style":"fontsize-4.5rem"},{"offset":0,"length":16,"style":"fontfamily-Georgia"},{"offset":0,"length":16,"style":"fontsize-72"},{"offset":0,"length":16,"style":"bgcolor-transparent"},{"offset":0,"length":16,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"acvrn","text":"kamera","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":6,"style":"color-white"},{"offset":0,"length":6,"style":"fontsize-4.5rem"},{"offset":0,"length":6,"style":"fontfamily-Georgia"},{"offset":0,"length":6,"style":"fontsize-72"},{"offset":0,"length":6,"style":"bgcolor-transparent"},{"offset":0,"length":6,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
        },
        {
            type: "content",
            order: 2,
            templateId: 2,
            backgroundColor: "#171E1D",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"bobt5","text":"I 1918 arvet en mann ved navn Niels Juel et gods i Hurdal i Akershus. Men han kunne ikke drive gårdsdrift der, for gårdene og markene var leid ut for mange år fremover. Hva skulle han da gjøre? ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":194,"style":"color-rgb(232,234,237)"},{"offset":0,"length":194,"style":"bgcolor-rgb(32,33,36)"},{"offset":0,"length":194,"style":"fontfamily-Georgia"},{"offset":0,"length":194,"style":"fontsize-36"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "50vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 3,
            templateId: 2,
            backgroundColor: "#171E1D",
            containers: [
                {
                    startColumn: 4,
                    endColumn: 7,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5MJ",
                            caption:
                                "Niels og Kristina Juel står i porten hjemme ved Viken Gård og ser utover Hurdalssjøen.",
                        },
                    ],
                },
            ],
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 4,
            templateId: 2,
            backgroundColor: "#171E1D",
            containers: [
                {
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"baaor","text":"Niels hadde reising i blodet, og med tid på hendene og et bærbart kamera i kofferten, ble Juel en aktiv hobbyfotograf gjennom hele sitt voksne liv.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":147,"style":"color-#ffffff"},{"offset":0,"length":147,"style":"bgcolor-transparent"},{"offset":0,"length":147,"style":"fontsize-36"},{"offset":0,"length":147,"style":"fontfamily-Georgia"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "50vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 5,
            templateId: 2,
            backgroundColor: "#171E1D",
            gradient: {
                direction: "180deg",
                fromColor: "#171E1D",
                fromPercent: 80,
                toPercent: 100,
                toColor: "#E5CBAC",
            },
            containers: [
                {
                    startColumn: 3,
                    endColumn: 8,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5Rd",
                            caption:
                                "Niels sitt fotoapparat ble laget i 1883 i Gøteborg i Sverige. Det var relativt lite for sin tid og kunne tas med på reise.",
                        },
                    ],
                },
            ],
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 6,
            templateId: 2,
            backgroundColor: "#E5CBAC",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"bf1j5","text":"Niels Juel brukte glassplate-negativer for å ta bilder.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":55,"style":"color-rgb(12,11,15)"},{"offset":0,"length":55,"style":"bgcolor-rgb(229,203,172)"},{"offset":0,"length":55,"style":"fontsize-1.125rem"},{"offset":0,"length":55,"style":"fontsize-18"},{"offset":0,"length":55,"style":"fontfamily-Georgia"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"d7dmk","text":"Platene kom i esker og kunne være tunge å ta med seg i store antall, og de måtte fremkalles i et mørkerom innen kort tid for at bildene ikke skulle bli ødelagte.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":161,"style":"color-rgb(12,11,15)"},{"offset":0,"length":161,"style":"bgcolor-rgb(229,203,172)"},{"offset":0,"length":161,"style":"fontsize-18"},{"offset":0,"length":161,"style":"fontfamily-Georgia"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"dhep9","text":"Fotoeskene til Niels Juel er en tidsmaskin til fortiden som forteller historier om hverdagsliv i Norge og reiser til utlandet på begynnelsen av 1900-tallet.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":156,"style":"color-rgb(12,11,15)"},{"offset":0,"length":156,"style":"bgcolor-rgb(229,203,172)"},{"offset":0,"length":156,"style":"fontsize-18"},{"offset":0,"length":156,"style":"fontfamily-Georgia"},{"offset":0,"length":156,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "50vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 7,
            templateId: 2,
            backgroundColor: "#E5CBAC",
            gradient: {
                direction: "180deg",
                fromColor: "#E5CBAC",
                fromPercent: 60,
                toPercent: 100,
                toColor: "#2D3A3C",
            },
            containers: [
                {
                    startColumn: 3,
                    endColumn: 8,
                    blocks: [
                        {
                            margin: "0 0 720px 0",
                            type: "image",
                            color: "#0C0B0F",
                            imagePath: "013Aitiph5Re",
                            caption:
                                "En konservator fra Follo Museum åpner en eske med glassplate-negativer.",
                        },
                    ],
                },
            ],
        },
        {
            type: "content",
            order: 8,
            templateId: 2,
            backgroundColor: "#2D3A3C",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"fu6n9","text":"Del 1","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"fontfamily-Georgia"},{"offset":0,"length":5,"style":"bgcolor-transparent"},{"offset":0,"length":5,"style":"color-#ffffff"},{"offset":0,"length":5,"style":"fontsize-36"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"7b36a","text":"I Danmark","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"fontsize-4.5rem"},{"offset":0,"length":9,"style":"fontfamily-Georgia"},{"offset":0,"length":9,"style":"bgcolor-transparent"},{"offset":0,"length":9,"style":"color-#ffffff"},{"offset":0,"length":9,"style":"fontsize-72"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            title: "Kviss med lås",
            excludeFromNav: true,
            type: "sticky",
            order: 9,
            templateId: 6,
            backgroundImage: [
                { image: "013Aitiph5RZ" },
                { image: "013Aitiph5Ra" },
                { image: "013Aitiph5Rb" },
            ],
            lock: {
                horizontalAlign: "center",
                startColumn: 5,
                endColumn: 6,
                lockedLabel: "For å scrolle videre i historien må du løse alle oppgavene",
                unlockedLabel: "Du kan scrolle videre",
                backgroundColor: "#ffffffe6"
            },
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"94v47","text":"Juel var veldig ofte i Danmark både alene og sammen med andre.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":62,"style":"color-rgb(12,11,15)"},{"offset":0,"length":62,"style":"fontsize-18"},{"offset":0,"length":62,"style":"fontfamily-Work Sans"},{"offset":0,"length":62,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"5qmiq","text":"Helt ytterst på Helsingør i Danmark besøkte han Kronborg Slott.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":63,"style":"fontsize-18"},{"offset":0,"length":63,"style":"fontfamily-Work Sans"},{"offset":0,"length":63,"style":"bgcolor-transparent"},{"offset":0,"length":63,"style":"color-#000000"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"5qmiq","text":"Helt ytterst på Helsingør i Danmark besøkte han Kronborg Slott.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":63,"style":"fontsize-18"},{"offset":0,"length":63,"style":"fontfamily-Work Sans"},{"offset":0,"length":63,"style":"bgcolor-transparent"},{"offset":0,"length":63,"style":"color-#000000"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                        {
                            type: "quiz",
                            question: "Kjenner du igjen denne statuen i København?",
                            alternatives: [
                                {
                                    alternative: "a",
                                    text: "H.C Andersens status i Indre by",
                                },
                                {
                                    alternative: "b",
                                    text: "Soren Kirkegaards statue i Bibliotekshaven",
                                },
                                {
                                    alternative: "c",
                                    text: "Fredrik Vs statue ved Amalienborg",
                                },
                            ],
                            answer: "b",
                            locked: true
                        },
                    ],
                    backgroundColor: "#ffffffe6",
                },
            ],
        },
        {
            type: "content",
            order: 10,
            templateId: 2,
            backgroundColor: "#2D3A3C",
            containers: [
                {
                    horizontalAlign: "left",
                    startColumn: 2,
                    endColumn: 9,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5MD",
                            caption:
                                "Som en ekte turist tok Niels Juel bilde av den kongelige kareten når den kjørte forbi ham i København.",
                        },
                    ],
                },
            ],
            gridSize: "twelveColumns",
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 11,
            templateId: 2,
            backgroundColor: "#2D3A3C",
            containers: [
                {
                    horizontalAlign: "right",
                    startColumn: 5,
                    endColumn: 12,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5ME",
                            caption:
                                "Dette er kanskje ikke verdens mest vellykkede turistfoto, men viser Marmorbroen i København.",
                        },
                    ],
                },
            ],
            gridSize: "twelveColumns",
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            templateId: 2,
            order: 12,
            backgroundColor: "#1F1F2E",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"dt0fa","text":"Del 2","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"fontsize-36"},{"offset":0,"length":5,"style":"bgcolor-transparent"},{"offset":0,"length":5,"style":"color-#ffffff"},{"offset":0,"length":5,"style":"fontfamily-Georgia"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"b6079","text":"I Sverige","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"color-white"},{"offset":0,"length":9,"style":"fontsize-2.25rem"},{"offset":0,"length":9,"style":"fontfamily-Georgia"},{"offset":0,"length":9,"style":"fontsize-72"},{"offset":0,"length":9,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "sticky",
            order: 13,
            templateId: 5,
            backgroundImage: "013Aitiph5MF",
            containers: [
                {
                    horizontalAlign: "center",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"9mo6m","text":"Et øyeblikksbilde fra Stockholm Sentralstasjon.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":47,"style":"color-rgb(12,11,15)"},{"offset":0,"length":47,"style":"fontsize-18"},{"offset":0,"length":47,"style":"fontfamily-Work Sans"},{"offset":0,"length":47,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    startColumn: 7,
                    endColumn: 12,
                    backgroundColor: "#ffffffb3",
                },
            ],
            gridSize: "twelveColumns",
        },
        {
            type: "sticky",
            templateId: 5,
            order: 14,
            backgroundImage: "013Aitiph5MG",
            containers: [
                {
                    horizontalAlign: "center",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"7fbth","text":"Juel passet på å besøke slottet i Stockholm.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":44,"style":"color-rgb(0,0,0)"},{"offset":0,"length":44,"style":"fontsize-medium"},{"offset":0,"length":44,"style":"fontfamily-Work Sans"},{"offset":0,"length":44,"style":"color-rgb(12,11,15)"},{"offset":0,"length":44,"style":"fontsize-1.125rem"},{"offset":0,"length":44,"style":"fontfamily-Work Sans"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    endColumn: 12,
                    startColumn: 7,
                    backgroundColor: "#ffffffb3",
                },
            ],
            gridSize: "twelveColumns",
        },
        {
            type: "sticky",
            order: 15,
            templateId: 5,
            backgroundImage: "013Aitiph5MH",
            containers: [
                {
                    horizontalAlign: "center",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"dhneg","text":"Niels Juel likte godt teater og forestillinger. Vet du hvilket bygg dette er?","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":77,"style":"color-rgb(12,11,15)"},{"offset":0,"length":77,"style":"fontsize-18"},{"offset":0,"length":77,"style":"fontfamily-Work Sans"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    startColumn: 7,
                    endColumn: 12,
                    backgroundColor: "#ffffffb3",
                },
            ],
            gridSize: "twelveColumns",
        },
        {
            type: "content",
            order: 16,
            templateId: 2,
            backgroundColor: "#1F1F2E",
            containers: [
                {
                    horizontalAlign: "left",
                    startColumn: 2,
                    endColumn: 9,
                    blocks: [
                        {
                            margin: "340px 0 180px 0",
                            type: "image",
                            color: "white",
                            imagePath: "013Aitiph5Rc",
                            caption: "Gustav Adolfs torg ved rådhuset i Gøteborg.",
                        },
                    ],
                },
            ],
            minHeight: "100vh",
            verticalAlign: "center",
            gridSize: "twelveColumns",
        },
        {
            type: "content",
            order: 17,
            templateId: 2,
            backgroundColor: "#1F1F2E",
            containers: [
                {
                    horizontalAlign: "right",
                    startColumn: 5,
                    endColumn: 12,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5ME",
                            caption:
                                "Dette er kanskje ikke verdens mest vellykkede turistfoto, men viser Marmorbroen i København.",
                        },
                    ],
                },
            ],
            gridSize: "twelveColumns",
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 18,
            templateId: 2,
            backgroundColor: "#2E2A29",
            containers: [
                {
                    horizontalAlign: "center",
                    startColumn: 5,
                    endColumn: 6,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"bfobd","text":"Del 3","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"fontfamily-Georgia"},{"offset":0,"length":5,"style":"fontsize-36"},{"offset":0,"length":5,"style":"color-#ffffff"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"ljic","text":"I Oslo","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":6,"style":"fontfamily-Georgia"},{"offset":0,"length":6,"style":"fontsize-72"},{"offset":0,"length":6,"style":"color-#ffffff"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 19,
            templateId: 2,
            backgroundColor: "#2E2A29",
            containers: [
                {
                    horizontalAlign: "left",
                    startColumn: 2,
                    endColumn: 9,
                    blocks: [
                        {
                            type: "image",
                            color: "white",
                            imagePath: "013Aitiph5MB",
                        },
                    ],
                },
                {
                    horizontalAlign: "right",
                    startColumn: 9,
                    endColumn: 13,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"dh7mm","text":"Disse to damene ute i oslomarken er dessverre ukjent, men bildet er tatt ca 1920 og det viser interessen for friluftsturer som folk hadde på den tiden.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":151,"style":"fontsize-24"},{"offset":0,"length":151,"style":"fontfamily-Work Sans"},{"offset":0,"length":151,"style":"bgcolor-transparent"},{"offset":0,"length":151,"style":"color-#ffffff"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                },
            ],
            gridSize: "twelveColumns",
            minHeight: "100vh",
            verticalAlign: "center",
        },
        {
            type: "content",
            order: 20,
            templateId: 2,
            backgroundColor: "#2E2A29",
            containers: [
                {
                    horizontalAlign: "left",
                    startColumn: 1,
                    endColumn: 5,
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"6kbo1","text":"En yngre Niels Juel studerte blant anna Fransk mens han budde i Oslo. Dette bildet er fra et av kursene hans.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":109,"style":"color-rgb(0,0,0)"},{"offset":0,"length":109,"style":"fontsize-medium"},{"offset":0,"length":109,"style":"fontfamily-Work Sans"},{"offset":0,"length":109,"style":"color-white"},{"offset":0,"length":109,"style":"fontsize-1.5rem"},{"offset":0,"length":109,"style":"fontfamily-Work Sans"},{"offset":0,"length":109,"style":"bgcolor-transparent"},{"offset":0,"length":109,"style":"fontsize-24"}],"entityRanges":[],"data":{"text-align":"start"}}],"entityMap":{}}',
                        },
                    ],
                },
                {
                    horizontalAlign: "right",
                    startColumn: 5,
                    endColumn: 12,
                    blocks: [
                        {
                            type: "image",
                            imagePath: "013Aitiph5MC",
                        },
                    ],
                },
            ],
            gridSize: "twelveColumns",
            minHeight: "100vh",
            verticalAlign: "center",
        },
    ],
}