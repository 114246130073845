import {Popover} from "@mui/material";
import CustomColorPicker from "../common/colorPicker/CustomColorPicker";
import React, {useState} from "react";

const decimalToHex = (alpha) => alpha === 0 ? "00" : Math.round(255 * alpha).toString(16)

export const PopoverColorPicker = ({color, anchorEl, templateColors=[], onSave, onClose, allowAlpha=false}) => {
    const [current, setCurrent] = useState(color)

    const handleChange = c => {
        if(allowAlpha) {
            setCurrent(`${c.hex}${decimalToHex(c.rgb.a)}`)
        } else {
            setCurrent(c.hex)
        }

    }

    const handleChangeComplete = (c) => {
        if(allowAlpha) {
            onSave(`${c.hex}${decimalToHex(c.rgb.a)}`)
        } else {
            onSave(c.hex)
        }

    }

    return (
        <Popover
            id={"read-more-color-popover"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "center",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "left"
            }}
            sx={{
                '& .MuiPopover-paper': {
                    ml: 4
                }
            }}
        >
            <CustomColorPicker
                color={current}
                onChange={handleChange}
                onChangeComplete={handleChangeComplete}
                templateColors={templateColors}
                colorFields={false}
                allowAlpha={allowAlpha}
            />
        </Popover>
    )
}