import React from "react";
import {createUseStyles} from "react-jss";
import {useScrollPercentage} from "../Block/useScrollPercentage";
import {OpenLayerMap} from "./OpenLayerMap";
import {useScrollyTellProps, useScrollytellState} from "../Admin/scrollytellContext";
import {updateSection} from "../Admin/service";
import {useSection} from "../Section/useSection";

const useStyle = createUseStyles({
    mapScroll: {
        width: "100%",
        position: "sticky",
        maxHeight: ({maxHeight}) => maxHeight,
    },
    content: {
        width: "100%",
        position: "sticky",
        top: 0,
    },
});

export const MapScroll = ({zoom, sectionId, containersCount, contentHeight, isThumbnail}) => {
    const {editDirections} = useScrollytellState()
    const threshold = containersCount > 5 ? 100 / (containersCount * 100) : 0.2
    const root = document.getElementById("scrollytell-content");
    const option =
        root !== null
            ? {root, threshold: threshold}
            : {threshold: threshold};
    const [wrapperRef, percentage] = useScrollPercentage(option);
    const classes = useStyle({
        maxHeight: isThumbnail ? "100%" : "none"
    });
    const section = useSection(sectionId)

    const {story, onChange} = useScrollyTellProps()

    const handleAdd = (newCoord) => {
        onChange(
            updateSection(story, sectionId, {
                mapCoords: {
                    from: section.mapCoords?.from ? section.mapCoords.from : newCoord,
                    to: section.mapCoords?.from ? newCoord : null
                }
            })
        )
    }

    const handleChange = newCoord => {
        onChange(
            updateSection(story, sectionId, {
                mapCoords: newCoord
            })
        )
    }

    return (
        <div
            className={classes.mapScroll}
            ref={wrapperRef}
            style={{height: containersCount > 5 ? `${containersCount * contentHeight}px` : `${5 * contentHeight}px`}}
        >
            <div
                className={classes.content}
                style={{
                    height: contentHeight
                }}
            >
                <OpenLayerMap
                    progress={percentage}
                    zoom={zoom}
                    edit={editDirections}
                    onAdd={handleAdd}
                    onChange={handleChange}
                    from={section.mapCoords?.from}
                    to={section.mapCoords?.to}
                />

            </div>
        </div>
    )
};
