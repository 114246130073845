import {Divider, FormControlLabel, Switch} from "@mui/material";
import React from "react";

export const AdminSwitchBarItem = ({label, checked, onChange, property, startDivider = false, Tooltip=null}) => {

    const handleChange = event => onChange(property, event.target.checked)

    const switchControl = (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{"aria-label": "controlled"}}
        />
    )

    const dividerSx = {
        ml: 2
    }
    const divider = () => startDivider ?
        <Divider sx={dividerSx} orientation="vertical" variant="middle" flexItem/> : null

    return (
        <>
            {divider()}
            <FormControlLabel
                control={switchControl}
                label={label}
                labelPlacement={"start"}
            />
            {Tooltip}
        </>
    )
}