import React from "react";
import {ModelViewer} from "@ekultur/modelviewer-microfrontend";
import {createUseStyles} from "react-jss";
import {WithModelViewer} from "./WithModelViewer";

const useStyles = createUseStyles({
  threeDBlock: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "100%",
    width: "100%",
    height: "600px",
  },
});

export const ThreeDBlock = ({
  sectionId,
  containerId,
  blockId,
  block,
}) => {
  const classes = useStyles({});
  const { scene, enablePan, enableZoom, showToolbar } = block;

  if(scene) {
    const withTransparentBackground = scene => {
      if(scene) {
        if(scene.model) {
          return {
            ...scene,
            model: {
              ...scene.model,
              transparentBackground: true
            }
          }
        } else {
          return {
            ...scene,
            model: {
              transparentBackground: true
            }

          }
        }
      } else {
        return scene
      }

    }

    const data = withTransparentBackground(scene)
    return (
        <WithModelViewer>
          <div className={classes.threeDBlock}>
            <ModelViewer
                data={data}
                showToolbar={showToolbar}
                enablePan={enablePan}
                enableZoom={enableZoom}
                onProgressChange={() => {}}
                containerId={`preview-modelviewer-${sectionId}-${containerId}-${blockId}`}
            />
          </div>
        </WithModelViewer>
    )
  } else {
    return null
  }
};
