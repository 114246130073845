import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { FormatColorFill, Gradient } from "@mui/icons-material";

import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { updateSection } from "../Admin/service";
import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { ColorPicker } from "../common/colorPicker/ColorPicker";
import { LinearGradientPicker } from "../common/LinearGradientPicker";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
  subMenu: {
    top: "41px",
    left: "2px",
    position: "absolute",
    display: "inline-flex",
    flexDirection: "column",
    //justifyContent: "center",
    //alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
});

export const BackgroundColorSubMenu = ({ section, sectionId, onClose }) => {
  const backgroundMenuRef = useRef();
  const { story, onChange } = useScrollyTellProps();
  const t = useScrollytellTranslation();

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showColorGradientPicker, setShowColorGradientPicker] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        backgroundMenuRef.current &&
        !backgroundMenuRef.current.contains(event.target)
      ) {
        if (!showColorPicker && !showColorGradientPicker) {
          onClose();
        }
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showColorPicker, showColorGradientPicker]);
  const classes = useStyles();
  return (
    <>
      <AdminIconBar ref={backgroundMenuRef} className={classes.subMenu}>
        <AdminIconBarItem
          Icon={Gradient}
          text={t("backgroundColorSubMenu.gradientText", "Gradient")}
          onClick={() => setShowColorGradientPicker(true)}
          tooltip={t(
            "backgroundColorSubMenu.gradientTooltip",
            "Sett bakgrunnsgradient"
          )}
          classname={classes.subMenuItem}
        />
        <AdminIconBarItem
          Icon={FormatColorFill}
          text={t(
            "backgroundColorSubMenu.backgroundColorText",
            "Bakgrunnsfarge"
          )}
          onClick={() => setShowColorPicker(true)}
          tooltip={t(
            "backgroundColorSubMenu.backgroundColorTooltip",
            "Sett bakgrunnsfarge"
          )}
          classname={classes.subMenuItem}
        />
      </AdminIconBar>
      <ColorPicker
        open={showColorPicker}
        color={section?.backgroundColor}
        onSave={(c) => {
          onChange(
            updateSection(story, sectionId, {
              backgroundColor: c,
              gradient: undefined,
            })
          );
        }}
        onClose={() => {
          setShowColorPicker(false);
          onClose();
        }}
        templateColors={story?.settings?.colors}
      />
      <LinearGradientPicker
        gradient={section?.gradient}
        open={showColorGradientPicker}
        onChange={(gradient) =>
          onChange(
            updateSection(story, sectionId, {
              gradient,
              backgroundColor: undefined,
            })
          )
        }
        onClear={() => {
          onChange(
            updateSection(story, sectionId, {
              gradient: undefined,
              backgroundColor: undefined,
            })
          );
        }}
        onClose={() => {
          setShowColorGradientPicker(false);
          onClose();
        }}
        templateColors={story?.settings?.colors}
      />
    </>
  );
};
