import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {getBackgroundImageString} from "../helpers/helpers";

const useStyles = createUseStyles({
    img: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
        position: "absolute",
        objectPosition: ({objectPosition}) => objectPosition,
    },
    imgGradient: {
        backgroundImage: ({ gradient}) =>
            getBackgroundImageString("", gradient),
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1,
        top: 0
    },
});

export const BackgroundImage = ({backgroundImage, positionX=50, positionY=50, gradient}) => {
    const [error, setError] = useState(false)
    const classes = useStyles({
        objectPosition: `${positionX}% ${positionY}%`,
        gradient: gradient
    })

    const handleImageError = () => {
        setError(true)
    }

    if(backgroundImage) {
        if(error) {
            return (
                <picture>
                    <div className={classes.imgGradient}/>
                    <img
                        className={classes.img}
                        src={`https://ems.dimu.org/image/${backgroundImage}?dimension=max`}
                        alt=""
                    />
                </picture>
            )
        } else {
            return (
                <picture>
                    <div className={classes.imgGradient}/>
                    <source
                        srcSet={`https://ems.dimu.org/image/${backgroundImage}?dimension=3072 3072w, https://ems.dimu.org/image/${backgroundImage}?dimension=2048x2048 2048w, https://ems.dimu.org/image/${backgroundImage}?dimension=1024x1024 1024w`}
                        type="image/webp"
                    />
                    <img
                        className={classes.img}
                        src={`https://ems.dimu.org/image/${backgroundImage}?dimension=1200x1200`}
                        alt=""
                        onError={handleImageError}
                    />
                </picture>
            )
        }

    } else {
        return (
            <div className={classes.imgGradient}/>
        )
    }

}