import React from "react";
import {Delete} from "@mui/icons-material";

import {createUseStyles} from "react-jss";
import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {removeBlock} from "../Admin/service";

const useStyles = createUseStyles(({colors}) => ({
    removeButton: {
        backgroundColor: colors.adminBlue,
        color: colors.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "16px",
        width: "24px",
        height: "24px",
        "&:hover": {color: `${colors.white}aa`},
        cursor: "pointer",
        position: "absolute",
        top: "-13px",
        right: "-11px",
        zIndex: 10,
    },
}));

export const RemoveBlockButton = ({blockId, onRemove}) => {
    const classes = useStyles();

    const handleRemove = () => onRemove(blockId)

    return (
        <div className={classes.removeButton}>
            <Delete
                onClick={handleRemove}
                sx={{
                    width: "14px",
                }}
            />
        </div>
    )
}
