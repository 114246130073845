import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {LoadingLogo} from "../layout/LoadingLogo";
import {LogoPlacer} from "../layout/LogoPlacer";

const ScrollytellPropsContext = createContext(undefined);
const ScrollytellStateContext = createContext(undefined);
const ScrollytellDispatchContext = createContext(undefined);
const ScrollytellTranslateContext = createContext(undefined);

export const SET_ACTIVE_ELEMENT = "setActiveElement";
export const SET_SHOW_GRID_VIEW = "setShowGridView";
export const SET_EDIT_DIRECTIONS = 'setEditDirections'

const initialState = {
  activeElement: [undefined, undefined, undefined],
  showGrid: false,
  editDirections: false
};

const scrollytellReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_ELEMENT: {
      return {
        ...state,
        activeElement: [
          action.activeSection,
          action.activeContainer,
          action.activeBlock,
        ],
      };
    }
    case SET_SHOW_GRID_VIEW: {
      return {
        ...state,
        showGrid: action.showGrid,
      };
    }
    case SET_EDIT_DIRECTIONS:
      return {
        ...state,
        editDirections: action.editDirections
      }
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
};

export const ScrollytellProvider = ({
  story,
  onChange,
  onMediaSelect,
  onTriggerSettings,
  children,
}) => {
  const [state, dispatch] = useReducer(
    scrollytellReducer,
    { ...initialState },
    undefined
  );
  const { t, ready } = useTranslation("vm-scrolly.app", {
    useSuspense: false,
  });

  if (!ready) {
    return (
        <LogoPlacer>
          <LoadingLogo />
        </LogoPlacer>
    )
  } else {
    return (
      <ScrollytellPropsContext.Provider
        value={{ onChange, story, onMediaSelect, onTriggerSettings }}
      >
        <ScrollytellStateContext.Provider value={state}>
          <ScrollytellDispatchContext.Provider value={dispatch}>
            <ScrollytellTranslateContext.Provider value={t}>
              {children}
            </ScrollytellTranslateContext.Provider>
          </ScrollytellDispatchContext.Provider>
        </ScrollytellStateContext.Provider>
      </ScrollytellPropsContext.Provider>
    );
  }
};

export const useScrollyTellProps = () => {
  const context = useContext(ScrollytellPropsContext);

  if (undefined === context) {
    throw new Error(
      "useScrollyTellProps must be used within a ScrollytellProvider"
    );
  } else {
    return context;
  }
};

export const useScrollytellState = () => {
  const context = useContext(ScrollytellStateContext);

  if (undefined === context) {
    throw new Error(
      "useScrollytellState must be used within a ScrollytellProvider"
    );
  } else {
    return context;
  }
};

export const useScrollytellDispatch = () => {
  const context = useContext(ScrollytellDispatchContext);

  if (undefined === context) {
    throw new Error(
      "useScrollytellDispatch must be used within a ScrollytellProvider"
    );
  } else {
    return context;
  }
};

export const useScrollytellTranslation = () => {
  const context = useContext(ScrollytellTranslateContext);

  if (undefined === context) {
    throw new Error(
      "useScrollytellTranslation must be used within a ScrollytellProvider"
    );
  } else {
    return context;
  }
};
