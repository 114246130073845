import React from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export const Dialog = ({ isOpen, title, description, onClose, children }) => {
  return (
    <MuiDialog
      fullWidth
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}
        >
          {title}
        </DialogTitle>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};
