import React, {useMemo, useState} from "react";
import {Debugger} from "./Debugger";
import {VerticalAlign} from "../helpers/enums";
import {ScrollerWrapper} from "./ScrollerWrapper";

const createThreshold = (theta, height) => {
  const count = Math.ceil(height / theta);
  const t = [];
  const ratio = 1 / count;
  for (let i = 0; i <= count; i += 1) {
    t.push(i * ratio);
  }
  return t;
};

export const Scroller = ({
  children,
  debug = false,
  offset,
  onStepEnter = () => {},
  onStepLeave = () => {},
  onStepProgress = null,
  threshold = 4,
  containersCount,
  isThumbnail,
    isMap=false,
    contentHeight,
  section: { verticalAlign = VerticalAlign.CENTER },
}) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleSetLastScrollTop = (scrollTop) => {
    setLastScrollTop(scrollTop);
  };

  const innerHeight = window.innerHeight;

  const progressThreshold = useMemo(
    () => createThreshold(threshold, innerHeight),
    [innerHeight, threshold]
  );

  return (
    <>
      {debug && <Debugger offset={offset} />}

      {React.Children.map(children, (child) => {
        const container = child?.props?.data.container;
        return (
          <ScrollerWrapper
              startColumn={container?.startColumn}
              endColumn={container?.endColumn}
              verticalAlign={verticalAlign}
              containersCount={containersCount}
              isThumbnail={isThumbnail}
              contentHeight={contentHeight}
              isMap={isMap}
          >
            {React.cloneElement(child, {
              offset,
              onStepEnter,
              onStepLeave,
              onStepProgress,
              lastScrollTop,
              handleSetLastScrollTop,
              progressThreshold,
              innerHeight,
            })}
          </ScrollerWrapper>
        );
      })}
    </>
  );
};
