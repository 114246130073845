import {AddBlockPanel} from "../Container/AddBlockPanel";
import {SubBlocks} from "../Block/SubBlocks";
import {useIsActiveContainer} from "../Container/useIsActiveContainer";
import {CONTENT_MODAL_CLOSED, useBlockDispatch, useBlockState} from "./blockContext";
import {ReadMoreSubBlocksContainer} from "./ReadMoreSubBlocksContainer";
import {SubContainerPanel} from "./SubContainerPanel";
import {Dialog, hexToRgb} from "@mui/material";
import {ReadMoreCloseButton} from "./ReadMoreCloseButton";
import {useEffect} from "react";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch} from "../Admin/scrollytellContext";

const backdropRgba = backdrop => {
    const color = hexToRgb(backdrop?.backgroundColor || "#000000")
    const transparency = backdrop?.transparency || "0.8"
    const rgbString = color.slice(4, color.length - 1)
    return `rgba(${rgbString}, ${transparency})`
}

export const ReadMoreContent = ({
                                    blockState,
                                    isAdminMode = false,
                                    onUpdate = null
                                }) => {
    const {showContentModal: open} = useBlockState()
    const readMoreDispatch = useBlockDispatch()
    const dispatch = useScrollytellDispatch()


    const setActiveContainer = () => {
        if(open) {
            dispatch({
                type: SET_ACTIVE_ELEMENT,
                activeSection: "subSection",
                activeContainer: "subContainer",
            })
        }
    }

    useEffect(setActiveContainer, [open]);

    const isActiveContainer = useIsActiveContainer({
        sectionId: "subSection",
        containerId: "subContainer"
    })

    const handleAdd = newBlock => {
        onUpdate({
            ...blockState,
            blocks: [...blockState.blocks, newBlock]
        })
    }

    const handleUpdate = updatedBlocks => {
        onUpdate({
            ...blockState,
            blocks: updatedBlocks
        })
    }

    const handleClose = () => {
        readMoreDispatch({type: CONTENT_MODAL_CLOSED})
    }

    const handleUpdateProperty = (property, value) => {
        onUpdate({
            ...blockState,
            [property]: value
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"body"}
            maxWidth={blockState.maxWidth || "scrollyMd"}
            fullWidth={true}
            sx={{
                "& .MuiDialog-container": {
                    backgroundColor: backdropRgba(blockState.backdrop),

                },
                "& .MuiDialog-paper": {
                    overflowY: "visible",
                    borderRadius: blockState.roundedCorners ? "16px" : "0px",
                    backgroundColor: blockState.backgroundColor || "#FFFFFF",
                },
                "& .MuiDialogContent-root": {
                    overflowY: "visible",
                    borderRadius: blockState.roundedCorners ? "16px" : "0px",
                }
            }}
        >
            <ReadMoreSubBlocksContainer
                isAdminMode={isAdminMode}
            >
                <SubContainerPanel
                    show={isActiveContainer && isAdminMode}
                    backgroundColor={blockState.backgroundColor}
                    roundedCorners={blockState.roundedCorners}
                    maxWidth={blockState.maxWidth}
                    backdrop={blockState.backdrop}
                    onChange={handleUpdateProperty}
                />
                <SubBlocks
                    blocks={blockState.blocks}
                    isAdminMode={isAdminMode}
                    onUpdate={handleUpdate}
                />
                <AddBlockPanel
                    onAdd={handleAdd}
                    show={isActiveContainer && isAdminMode}
                />
            </ReadMoreSubBlocksContainer>
            <ReadMoreCloseButton
                onClick={handleClose}
            />
        </Dialog>
    )
}