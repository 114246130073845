import React, {useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {useInView} from "react-intersection-observer";
import {theme, Typography} from "@ekultur/vm";
import {VideoPlayer} from "../common/VideoPlayer";
import {FontTypes, Sizes} from "../helpers/enums";
import {BLOCK_UNLOCKED, useProgressDispatch} from "../progress/progressContext";
import {useContrastApproved} from "../helpers/useContrast";

const useStyle = createUseStyles({
  videoBlock: {
    display: "inline-flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    margin: ({ margin }) => margin || 0,
    padding: ({ padding }) => padding || 0,
    position: "relative"
  },
  caption: {
    color: ({ color }) => color,
    font: ({ font }) => font,
    display: "flex",
    marginTop: "17px",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});

export const VideoBlock = ({
  sectionId,
  containerId,
  blockId,
  block,
  className = "",
}) => {
  const {
    videoUrl,
    autoPlay = false,
    showControls = false,
    loop = false,
    size = Sizes.BASE,
    font = FontTypes.LIBRE_BASKERVILLE,
    margin,
    padding,
    caption,
    backgroundColor,
  } = block;
  const contrastApproved = useContrastApproved(
      backgroundColor !== undefined
          ? backgroundColor.substring(0, 7)
          : theme.colors.white,
      theme.colors.white
  )
  const classes = useStyle({
    margin,
    padding,
    backgroundColor,
    font,
    color: contrastApproved ? theme.colors.white : theme.colors.black
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.9,
  });
  const videoRef = useRef();
  const progressDispatch = useProgressDispatch()

  useEffect(() => {
    if(videoRef?.current) {
      videoRef?.current.setAttribute('muted', '')
    }
  }, [videoRef])

  useEffect(() => {
    if (autoPlay) {
      if(videoRef?.current) {
        videoRef?.current.setAttribute('muted', '')
      }
      if (inView && !isPlaying) {
        setIsPlaying(true);
        videoRef.current.play();
      }
      if (!inView && isPlaying) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    }
  }, [inView]);

  const handleVideoEnded = () => {
    if(block.locked) {
      progressDispatch({
        type: BLOCK_UNLOCKED,
        payload: {
          sectionId: sectionId,
          containerId: containerId,
          blockId: blockId
        }
      })
    }
  }

  return (
      <figure className={`${classes.videoBlock} ${className}`} ref={ref}>
        <VideoPlayer
            autoPlay={autoPlay}
            videoUrl={`https://ems.dimu.org/streaming/adaptive/${videoUrl}/index.m3u8`}
            ref={videoRef}
            controls={showControls}
            loop={loop}
            muted
            style={{
              width: "100%",
              objectFit: "contain",
            }}
            onEnded={handleVideoEnded}
        />
        {caption && (
            <Typography tag="figcaption" size={size} className={classes.caption}>
              {caption}
            </Typography>
        )}
      </figure>
  )
};
