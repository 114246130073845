import {VideoPlayButton} from "./VideoPlayButton";
import {VideoUnmuteButton} from "./VideoUnmuteButton";
import {UnmuteButton} from "./UnmuteButton";

export const VideoControls = ({hasAudio, hideUnmute, onUnmute, onPlay, autoPlay, children}) => {
    if(autoPlay) {
        return (
            <VideoUnmuteButton
                show={hasAudio}
                Button={(
                    <UnmuteButton
                        onUnmute={onUnmute}
                        hide={!hasAudio || hideUnmute}
                    />
                )}
            >
                {children}
            </VideoUnmuteButton>
        )
    } else {
        return (
            <VideoPlayButton
                onPlay={onPlay}
            >
                {children}
            </VideoPlayButton>
        )
    }

}