import { useEffect, useState } from "react";
import colorContrast from "color-contrast";

export const useContrastApproved = (color1, color2, ratio = 4.5) => {
    const [isApproved, setIsApproved] = useState(false);

    useEffect(() => {
        setIsApproved(colorContrast(color1, color2) >= ratio);
    }, [color1, color2, ratio, setIsApproved, colorContrast]);

    return isApproved;
};
