import React from "react";
import {App} from "../App/App";
import {useParams} from "react-router";
import {demo01} from "./demo01";
import {demo02} from "./demo02";
import {demo03} from "./demo03";
import {ProgressProvider} from "../progress/progressContext";
import {demo05} from "./demo05";

export const ScrollyDemo = () => {
    const {id} = useParams();

    return (
        <ProgressProvider>
            <App data={scrollys[id]} adminMode={false}/>
        </ProgressProvider>
    )
};
const scrollys = {
    1: demo01,
    2: demo02,
    3: demo03,
    5: demo05
};
