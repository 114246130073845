export const ItemTypes = Object.freeze({
  SECTION: "section",
  CONTAINER: "container",
  BLOCK: "block",
});

export const BlockTypes = Object.freeze({
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  VIDEO_SCROLL: "videoScroll",
  QUIZ: "quiz",
  THREE_D: "3d",
  CODEWORD: 'codeword',
  READ_MORE: "readMore"
});
export const SectionTypes = Object.freeze({
  CONTENT: "content",
  STICKY: "sticky",
  STICKY_MAP: "stickyMap"
});
export const SectionMinHeights = Object.freeze({
  ONE_AND_HALF: "150vh",
  FULL: "100vh",
  HALF: "50vh",
  QUARTER: "25vh",
  CONTENT: "100px",
});
export const SectionGridSize = Object.freeze({
  NINE_COLUMNS: "nineColumns",
  TWELVE_COLUMNS: "twelveColumns",
});

export const TextTypes = Object.freeze({
  HEADING: "heading",
  INGRESS: "ingress",
  BODY_TEXT: "bodyText",
});
export const Sizes = Object.freeze({
  XS: "xs",
  SM: "sm",
  BASE: "base",
  LG: "lg",
  XL: "xl",
  XL2: "xl2",
  XL3: "xl3",
  XL4: "xl4",
  XL5: "xl5",
  XL6: "xl6",
  XL7: "xl7",
  XL8: "xl8",
  XL9: "xl9",
});
export const HorizontalAlign = Object.freeze({
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
});
export const VerticalAlign = Object.freeze({
  TOP: "top",
  CENTER: "center",
  BOTTOM: "bottom",
  SPACE: "space",
});
export const StickyBlockBackgroundTypes = Object.freeze({
  BLOCK_BACKGROUND: "blockBackground",
  TEXT_BACKGROUND: "textBackground",
  NO_BACKGROUND: "noBackground",
});

export const FontTypes = Object.freeze({
  JOSEFIN_SANS: "'Josefin Sans', sans-serif",
  LIBRE_BASKERVILLE: "'Libre Baskerville', serif",
});
export const GradientDirections = Object.freeze({
  HORIZONTAL: "to right",
  VERTICAL: "to bottom",
});

export const SectionTemplates = Object.freeze({
  TITLE_SECTION: 1,
  BODYTEXT_SECTION: 2,
  ZIGZAG_SECTION: 3,
  SCROLLVIDEO_SECTION: 4,
  STICKY_SECTION: 5,
  STICKY_SECTION_MULTI: 6,
  SCROLL_MAP: 7
});
