import React from "react";
import {createUseStyles} from "react-jss";
import {Logo} from "./Logo";
import {Button} from "./Button";
import {LogOut} from "lucide-react";
import {DmLogo} from "./DmLogo";

const useStyles = createUseStyles(({ colors }) => ({
  appBar: {
    height: 64,
    flex: "0 0 auto",
    background: ({ backgroundColor }) => backgroundColor || "#F6F5F8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: ({ mode }) => (mode === "dark" ? colors.white : colors.black),
    fontSize: "14ox",
    cursor: "default",
    zIndex: 20,
    position: ({ isAdminMode }) => (isAdminMode ? "sticky" : "sticky"),
    top: 0,
    width: "100%",
    fontFamily: "Akkurat"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > a": {
      margin: "0 10px",
    },
    "& > div": {
      display: "flex",
      flexDirection: "column",
      "& > a": {
        textDecoration: "none",
        color: "inherit",
        "&:first-of-type": {
          fontWeight: "bold",
        },
      },
      "@media (max-width: 700px)": {
        display: "none",
      },
    },
  },
}));

export const AppBar = ({
  backgroundColor,
  mode = "light",
  className = "",
  exhibition,
  museum,
  onClose,
  isAdminMode,
  children,
}) => {
  const styles = useStyles({
    backgroundColor: backgroundColor,
    mode: mode,
    isAdminMode: isAdminMode
  });

  return (
    <div className={`${styles.appBar} ${className}`}>
      {isAdminMode ? (
        <div className={styles.logo}>
          <a>
            <Logo showLabel={false} mode={mode} />
          </a>
          <div>
            {exhibition?.name && <span>{exhibition.name}</span>}
            {museum?.name && <span>{museum.name}</span>}
          </div>
        </div>
      ) : (
        <div className={styles.logo}>
          <a href="/">
            <DmLogo
                mode={mode}
            />
          </a>
          <div>
            {exhibition?.name && <a href={exhibition.url}>{exhibition.name}</a>}
            {museum?.name && <a href={museum.url}>{museum.name}</a>}
          </div>
        </div>
      )}
      <div className={styles.buttons}>
        {children}
        {onClose && (
          <Button
            text="Avslutt"
            icon={<LogOut />}
            onClick={onClose}
            mode={mode}
          />
        )}
      </div>
    </div>
  );
};
