import {createUseStyles} from "react-jss";
import {ReadMoreButton} from "../read-more/ReadMoreButton";

const useStyles = createUseStyles({
    unmuteContainer: {
        position: "absolute",
        top: "1rem",
        right: "1rem",
        zIndex: 1
    }
})
export const VideoUnmuteButton = ({Button, children}) => {
    const classes = useStyles()
    return (
        <>
            <div
                className={classes.unmuteContainer}
            >
                {Button}
            </div>
            {children}
        </>
    )
}