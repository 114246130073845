import React, { useRef } from "react";
import { AdminIconBar } from "../icon-bar/AdminIconBar";
import {
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from "@mui/icons-material";
import { updateSection } from "../Admin/service";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { createUseStyles } from "react-jss";
import { useClickOutside } from "../common/useClickOutside";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
  verticalAlignMenu: {
    top: "41px",
    left: "2px",
    position: "absolute",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  verticalAlignMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
});

export const VerticalAlignMenu = ({ sectionId, onClose }) => {
  const t = useScrollytellTranslation();
  const verticalAlignMenuRef = useRef();
  const { story, onChange } = useScrollyTellProps();
  const classes = useStyles();
  useClickOutside(verticalAlignMenuRef, () => {
    onClose();
  });

  const setProperty = (property, value) => {
    onChange(updateSection(story, sectionId, { [property]: value }));
  };

  return (
    <AdminIconBar
      ref={verticalAlignMenuRef}
      className={classes.verticalAlignMenu}
    >
      <AdminIconBarItem
        Icon={VerticalAlignBottom}
        onClick={() => setProperty("verticalAlign", "bottom")}
        tooltip={t("editContainerPanel.setVerticalAlign.bottomTooltip", "Bunn")}
        classname={classes.verticalAlignMenuItem}
      />
      <AdminIconBarItem
        Icon={VerticalAlignCenter}
        onClick={() => setProperty("verticalAlign", "center")}
        tooltip={t(
          "editContainerPanel.setVerticalAlign.centerTooltip",
          "Senter"
        )}
        classname={classes.verticalAlignMenuItem}
      />
      <AdminIconBarItem
        Icon={VerticalAlignTop}
        onClick={() => setProperty("verticalAlign", "top")}
        tooltip={t("editContainerPanel.setVerticalAlign.topTooltip", "Top")}
        classname={classes.verticalAlignMenuItem}
      />
    </AdminIconBar>
  );
};
