import {GridOff, GridOn} from "@mui/icons-material";
import {
    SET_SHOW_GRID_VIEW,
    useScrollytellDispatch,
    useScrollytellState,
    useScrollytellTranslation
} from "../Admin/scrollytellContext";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";

export const ShowGridButton = () => {
    const t = useScrollytellTranslation();
    const {showGrid} = useScrollytellState()
    const dispatch = useScrollytellDispatch();

    const handleClick = () => {
        dispatch({
            type: SET_SHOW_GRID_VIEW,
            showGrid: !showGrid
        });
    }

    return (
        <AdminIconBarItem
            Icon={showGrid ? GridOn : GridOff}
            onClick={handleClick}
            tooltip={t("editSectionPanel.showGrid", "Vis/skjul grid")}
        />
    )
}