import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useDrop } from "react-dnd";
import { useContrastApproved } from "../../helpers/useContrast";
import { theme } from "@ekultur/vm";

const useStyles = createUseStyles({
  gridColumn: {
    opacity: 0.5,
    borderImage:
      "repeating-linear-gradient(black 0px, black 4px, white 4px, white 8px)",
    borderImageSlice: 1,
    borderImageWidth: "0 0 0 1px",
    borderLeft: "1px solid transparent",
    pointerEvents: ({ canDrop }) => (canDrop ? "auto" : "none"),
    "&:last-child": {
      borderRight: "1px solid transparent",
    },
  },
  startColumn: {
    "&.dropZone": {
      borderImage:
        "repeating-linear-gradient(black 0px, black 4px, white 4px, white 8px)",
      borderImageSlice: 1,
      borderImageWidth: "0 0 0 1px",
      borderLeft: "1px solid transparent",
    },
    "&.hover": {
      opacity: 1,
      borderImage:
        "repeating-linear-gradient(black 0px, black 4px, white 4px, white 8px)",
      borderImageSlice: 2,
      borderImageWidth: "0 0 0 2px",
      borderLeft: "2px solid transparent",
    },
  },
  endColumn: {
    "&.dropZone": {
      borderLeft: "none",
      borderImage:
        "repeating-linear-gradient(black 0px, black 4px, white 4px, white 8px)",
      borderImageSlice: 1,
      borderImageWidth: "0 1px 0 0",
      borderRight: "1px solid transparent",
    },
    "&.hover": {
      opacity: 1,
      borderLeft: "none",
      borderImage:
        "repeating-linear-gradient(black 0px, black 4px, white 4px, white 8px)",
      borderImageSlice: 2,
      borderImageWidth: "0 2px 0 0",
      borderRight: "2px solid transparent",
    },
  },
});

export const GridColumn = ({ backgroundColor, onDrop, onCanDropChange }) => {
  const contrastApproved = useContrastApproved(
    backgroundColor !== undefined
      ? backgroundColor.substring(0, 7)
      : theme.colors.white,
    theme.colors.white
  );
  const [{ hover, canDrop, itemType }, drop] = useDrop(
    () => ({
      accept: ["startColumn", "endColumn"],
      drop: onDrop,
      collect: (monitor) => ({
        hover: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
      }),
    }),
    []
  );

  useEffect(() => {
    onCanDropChange(canDrop);
  }, [canDrop]);
  const classes = useStyles({ contrastApproved, canDrop });
  return (
    <div
      className={`${classes.gridColumn} ${classes[itemType]} ${
        canDrop ? "dropZone" : ""
      } ${hover ? "hover" : ""}`}
      ref={drop}
    />
  );
};
