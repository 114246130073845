import React from "react";
import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {CopyAll} from "@mui/icons-material";
import {createUseStyles} from "react-jss";
import {
    SET_ACTIVE_ELEMENT,
    useScrollytellDispatch,
    useScrollyTellProps,
    useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import {addContainer} from "../Admin/service";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import {useContentHeight} from "../map/useContentHeight";

const useStyles = createUseStyles(({colors}) => ({
    duplicateContainerButton: {
        position: "absolute",
        bottom: ({bottom}) => bottom,
        left: 0,
        backgroundColor: colors.adminBlue,
        color: colors.white,
        borderRadius: "2px",
        zIndex: 10,
    },
}));
export const DuplicateContainerButton = ({container, sectionId, containerId}) => {
    const t = useScrollytellTranslation();
    const dispatch = useScrollytellDispatch();
    const contentHeight = useContentHeight(`container-${sectionId}-${containerId}`)
    const classes = useStyles({
            bottom: contentHeight < 82 ? "-46px" : "0px"
    });
    const {onChange, story} = useScrollyTellProps();

    const duplicateContainer = () => {
        onChange(
            addContainer(
                story,
                {
                    ...container,
                    removable: true
                },
                sectionId
            )
        )
        setTimeout(() => {
            scrollToContainer();
        }, 10);
    };

    const scrollToContainer = () => {
        const containerId = story.sections[sectionId].containers.length;
        const content = document.getElementById("scrollytell-content");
        if (content) {
            dispatch({
                type: SET_ACTIVE_ELEMENT,
                activeSection: sectionId,
                activeContainer: containerId,
                activeBlock: undefined,
            });
            document
                .getElementById(`container-${sectionId}-${containerId}`)
                .scrollIntoView();
        }
    };

    return (
        <div className={classes.duplicateContainerButton}>
            <AdminIconBar>
                <AdminIconBarItem
                    Icon={CopyAll}
                    onClick={() => duplicateContainer()}
                    tooltip={t("duplicateContainerTooltip", "Kopier container")}
                />
            </AdminIconBar>
        </div>
    );
};
