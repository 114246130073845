import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import React from "react";

export const EditLock = ({locked, onChange, contrastApproved}) => {

    const handleCheck = event => {
        onChange("locked", event.target.checked)
    }

    return (
        <Stack
            spacing={2}
            sx={{
                mb: 2,
                ml: 1
            }}
        >
            <FormControlLabel
                checked={locked === true|| false}
                control={(
                    <Checkbox
                        sx={{
                            color: contrastApproved ? "white" : "black",
                            "svg": {
                                color: contrastApproved ? "white" : "black",
                            }
                        }}
                    />
                )}
                label={"Krev at leseren svarer riktig for å scrolle videre i historien"}
                onChange={handleCheck}
                sx={{
                    color: contrastApproved ? "white" : "black"
                }}
            />

        </Stack>
    )
}