import {WidthNormal} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React, {useState} from "react";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {FormControl, InputLabel, MenuItem, Popover, Select} from "@mui/material";


export const WidthSelector = ({value="scrollyMd", onChange}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const t = useScrollytellTranslation()

    const options = [
        {
            value: 'scrollySm',
            label: t('sm', 'Liten'),
            size: '444px'
        },
        {
            value: 'scrollyMd',
            label: t('md', 'Medium'),
            size: '700px'
        },
        {
            value: 'scrollyLg',
            label: t('lg', 'Stor'),
            size: '900px'
        },
        {
            value: 'scrollyXl',
            label: t('xl', 'Ekstra stor'),
            size: '1200px'
        },
        {
            value: 'scrolly2xl',
            label: t('2xl', 'Størst'),
            size: '1536px'
        }
    ]

    const handleChange = event =>  onChange(event.target.value)


    const handleClick = event => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const open = Boolean(anchorEl)

    return (
        <>
            <AdminIconBarItem
                Icon={WidthNormal}
                tooltip={t('editContainerPanel.widthTooltip', 'Bredde')}
                onClick={handleClick}
            />
            <Popover
                id={"width-picker-popover"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        p: 2
                    }
                }}

             >
                <FormControl
                    fullWidth={true}
                >
                    <InputLabel
                        id={"width-picker-label"}
                    >
                        {t('editContainerPanel.widthSelectorLabel', "Maks-bredde for popup")}
                    </InputLabel>
                    <Select
                        labelId={"width-picker-label"}
                        label={t('editContainerPanel.widthSelectorLabel', "Maks-bredde for popup")}
                        id={"width-picker"}
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map(option => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {`${option.label} (${option.size})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Popover>
        </>
    )
}