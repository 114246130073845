import React, {forwardRef, useEffect, useState} from "react";
import Hls from "hls.js";
import {createUseStyles} from "react-jss";
import {VideoControls} from "./VideoControls";

const useStyles = createUseStyles({
    videoPlayer: {
        maskImage: "-webkit-radial-gradient(white, black)",
        backfaceVisibility: "hidden"
    }
})

export const VideoPlayer = forwardRef(
    (
        {
            autoPlay = false,
            videoUrl,
            onLoadedMetadata,
            muted = false,
            controls = false,
            loop = false,
            onEnded = null
        },
        playerRef
    ) => {
        const [playlist, setPlaylist] = useState(null);
        const [hideCustomUnmute, setHideCustomUnmute] = useState(false)
        const [hasAudio, setHasAudio] = useState(true)

        const classes = useStyles()

        const handlePlay = () => {
            if (playerRef) {
                playerRef.current.play()
                playerRef.current.controls = controls
            }
            handleUnmute()
        }


        const handleUnmute = () =>  {
            if (playerRef) {
                playerRef.current.muted = false
            }
        }

        const handleVolumeChange = event => {
            if(!playerRef?.current.muted) {
                setHideCustomUnmute(true)
            }
        }

        class fLoader extends Hls.DefaultConfig.loader {
            constructor(config) {
                super({...config});
                const load = this.load.bind(this);
                this.load = (context, config, callbacks) => {
                    if ("manifest" !== context.type) {
                        const newUrl = new URL(playlist);
                        load(
                            {
                                ...context,
                                url: `${context.url}${newUrl.search}`,
                            },
                            config,
                            callbacks
                        );
                    } else {
                        load(context, config, callbacks);
                    }
                };
            }
        }

        useEffect(() => {
            setPlaylist(videoUrl);
        }, [videoUrl]);

        useEffect(() => {
            if (null !== playerRef && playlist) {
                const video = playerRef?.current;
                if (Hls.isSupported()) {
                    const hls = new Hls({
                        loader: fLoader,
                        debug: false,
                        progressive: true,
                        forceKeyFrameOnDiscontinuity: false,
                    });
                    hls.config.maxBufferLength = 5
                    hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                        setHasAudio(data.audio)
                    })
                    hls.loadSource(playlist);
                    hls.attachMedia(video);

                    video.controls = autoPlay && controls;
                    video.loop = loop;
                    video.muted = muted;
                } else {
                    video.setAttribute('src', playlist)
                }
            }
        }, [playlist, playerRef, controls, muted]);

        const handleVideoEnded = () => onEnded?.()

        if (Hls.isSupported()) {
            return (
                <VideoControls
                    onPlay={handlePlay}
                    autoPlay={autoPlay}
                    hideUnmute={hideCustomUnmute}
                    onUnmute={handleUnmute}
                    hasAudio={hasAudio}
                >
                    <video
                        onVolumeChange={handleVolumeChange}
                        className={classes.videoPlayer}
                        ref={playerRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        onLoadedMetadata={onLoadedMetadata}
                        onEnded={handleVideoEnded}
                    />
                </VideoControls>
            )
        } else {
            return (
                <VideoControls
                    onPlay={handlePlay}
                    onUnmute={handleUnmute}
                    autoPlay={autoPlay}
                    hideUnmute={hideCustomUnmute}
                    hasAudio={hasAudio}
                >
                    <video
                        onVolumeChange={handleVolumeChange}
                        className={classes.videoPlayer}
                        ref={playerRef}
                        playsInline={true}
                        muted={muted}
                        preload={'auto'}
                        loop={loop}
                        controls={controls}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                        onLoadedMetadata={onLoadedMetadata}
                        onEnded={handleVideoEnded}
                    />
                </VideoControls>
            )
        }
    }
);
