import {Check, X} from "lucide-react";
import React from "react";
import {createUseStyles} from "react-jss";
import {AlternativeLetter} from "./AlternativeLetter";
import {AlternativeContainer} from "./AlternativeContainer";

const useStyle = createUseStyles(({ colors }) => ({
    correctAnswer: {
        backgroundColor: colors.seaFoamDarkGreen,
    },
    correctAnswerCheck: { color: colors.black },
}));

export const AlternativeCorrect = ({alternative, whiteBorder=false, children}) => {
    const classes = useStyle()
    return (
        <AlternativeContainer
            variantClass={classes.correctAnswer}
            whiteBorder={whiteBorder}
        >
            <AlternativeLetter>
                {alternative.toUpperCase()}
            </AlternativeLetter>
            <Check
                className={classes.correctAnswerCheck}
            />
            {children}
        </AlternativeContainer>
    );
}