import {createContext, useContext, useReducer} from "react";

const ProgressStateContext = createContext(undefined)
const ProgressDispatchContext = createContext(undefined)
export const BLOCK_UNLOCKED = 'blockUnlocked'
export const LOCKS_SETUP = 'locksSetup'
export const SECTION_ENTERED_VIEW = 'sectionEnteredView'

const initialState = {
    locks: [],
    currentSectionId: null
}

const isSame = (lock, action) => lock.sectionId === action.sectionId
    && lock.containerId === action.containerId
    && lock.blockId === action.blockId

const progressReducer = (state, action) => {
    console.debug({action})
    switch(action.type) {
        case BLOCK_UNLOCKED:
            return {
                ...state,
                locks: state.locks.filter(lock => !isSame(lock, action.payload))
            }
        case LOCKS_SETUP:
            return {
                ...state,
                locks: action.payload
            }
        case SECTION_ENTERED_VIEW:
            return {
                ...state,
                currentSectionId: action.payload
            }
        default:
            throw new Error(`Unsupport action type ${action.type}`)
    }
}


export const ProgressProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        progressReducer,
        initialState,
        undefined
    )

    return (
        <ProgressStateContext.Provider value={state}>
            <ProgressDispatchContext.Provider value={dispatch}>
                {children}
            </ProgressDispatchContext.Provider>
        </ProgressStateContext.Provider>
    )
}

export const useProgressState = () => {
    const context = useContext(ProgressStateContext)
    if (context === undefined) {
        throw new Error("useProgressState must be used within a ProgressProvider")
    } else {
        return context
    }
}

export const useProgressDispatch = () => {
    const context = useContext(ProgressDispatchContext)
    if (context === undefined) {
        throw new Error("useProgressDispatch must be used within a ProgressProvider")
    } else {
        return context
    }
}