import React, { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { GradientDirections } from "../helpers/enums";
import { Close, DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
} from "@mui/material";
import { ColorPickerButton } from "./colorPicker/ColorPicker";
import { useScrollytellTranslation } from "../Admin/scrollytellContext";

const useStyles = createUseStyles(({ colors }) => ({
  linearGradientPicker: {
    position: "absolute",
    width: "240px",
    display: "flex",
    zIndex: 10000,
    flexDirection: "column",
    backgroundColor: colors.white,
    borderRadius: "4px",
    color: "#0000008A",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #0000001F",
    padding: "10px 10px 0 10px",
    "& h3": {
      margin: "11px 0",
      color: "#000000DE",
    },
  },
  direction: {
    display: "flex",
    borderBottom: "1px solid #0000001F",
    padding: "10px 0",
  },
  directionLabel: {
    fontWeight: "bold",
    marginBottom: "5px",
  },
  colors: {
    display: "flex",
    padding: "0 10px",
    borderBottom: "1px solid #0000001F",
  },
  fromColor: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "calc(50% - 0.5px)",
    height: "80px",
    borderRight: "1px solid #0000001F",
  },
  toColor: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "calc(50% - 0.5px)",
    height: "80px",
  },

  colorPickerLabel: {
    fontSize: "12px",
    color: "#00000099",
    marginBottom: "10px",
  },
  colorInputWrapper: { display: "flex", alignItems: "center" },
  colorStartStop: {
    display: "flex",
    margin: "0 22px",
  },
  removeGradient: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "center",
  },
  colorPickers: { marginTop: "20px", display: "flex" },
}));

export const LinearGradientPicker = ({
  gradient,
  open,
  onChange,
  onClear,
  onClose,
  templateColors = [],
}) => {
  const ref = useRef(null);
  const t = useScrollytellTranslation();
  const [directionMenuOpen, setDirectionMenuOpen] = useState(false);
  const classes = useStyles();
  const sliderValue = [gradient?.fromPercent, gradient?.toPercent];

  const updateGradient = (property, value) => {
    if (!gradient) {
      onChange({
        ...gradient,
        direction: GradientDirections.HORIZONTAL,
        fromPercent: 0,
        toPercent: 100,
        fromColor: "#ffffff",
        toColor: "#000000",
        [property]: value,
      });
    } else {
      onChange({
        ...gradient,
        [property]: value,
      });
    }
  };

  return (
    open && (
      <div className={classes.linearGradientPicker} ref={ref}>
        <div className={classes.header}>
          <h3>{t("gradientPicker.title", "Gradient")}</h3>{" "}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.direction}>
          <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
            <label className={classes.directionLabel}>
              {t("gradientPicker.directionLabel", "Retning")}
            </label>
            <Select
              id="direction-select"
              open={directionMenuOpen}
              onClose={() => setDirectionMenuOpen(false)}
              onOpen={() => setDirectionMenuOpen(true)}
              value={gradient?.direction || GradientDirections.VERTICAL}
              variant="outlined"
              sx={{ padding: "8px", width: "100%", maxHeight: "28px" }}
              onChange={(e) => updateGradient("direction", e.target.value)}
            >
              {Object.entries(GradientDirections).map((entry) => (
                <MenuItem key={entry[0]} value={entry[1]}>
                  {t(`gradientPicker.gradientDirection.${entry[0]}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.colors}>
          <div className={classes.fromColor}>
            <label className={classes.colorPickerLabel}>
              {t("gradientPicker.colorOne", "Farge #1")}
            </label>
            <div className={classes.colorInputWrapper}>
              <ColorPickerButton
                color={gradient?.fromColor || "#ffffff"}
                onChange={(c) => updateGradient("fromColor", c)}
                templateColors={templateColors}
              />
              <OutlinedInput
                value={gradient?.fromPercent || 0}
                size="small"
                onChange={(e) => updateGradient("fromPercent", e.target.value)}
                onBlur={() => {
                  if (gradient?.fromPercent < 0) {
                    updateGradient("fromPercent", 0);
                  } else if (gradient?.fromPercent > 100) {
                    updateGradient("fromPercent", 100);
                  }
                }}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: "number",
                  "aria-labelledby": "input-slider",
                  sx: { padding: "8.5px 5px" },
                }}
                sx={{
                  maxHeight: "28px",
                  maxWidth: "55px",
                }}
              />
            </div>
          </div>
          <div className={classes.toColor}>
            <label className={classes.colorPickerLabel}>
              {t("gradientPicker.colorTwo", "Farge #2")}
            </label>
            <div className={classes.colorInputWrapper}>
              <ColorPickerButton
                color={gradient?.toColor || "#000000"}
                onChange={(c) => updateGradient("toColor", c)}
                templateColors={templateColors}
              />
              <OutlinedInput
                value={gradient?.toPercent || 100}
                size="small"
                onChange={(e) => updateGradient("toPercent", e.target.value)}
                onBlur={() => {
                  if (gradient?.toPercent < 0) {
                    updateGradient("toPercent", 0);
                  } else if (gradient?.toPercent > 100) {
                    updateGradient("toPercent", 100);
                  }
                }}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: "number",
                  "aria-labelledby": "input-slider",
                  sx: { padding: "8.5px 5px" },
                }}
                sx={{ maxHeight: "28px", maxWidth: "55px" }}
              />
            </div>
          </div>
        </div>
        <div className={classes.colorStartStop}>
          <Box sx={{ width: 300 }}>
            <Slider
              getAriaLabel={() => "Color range"}
              value={sliderValue}
              onChange={(e, newValue, activeThumb) => {
                if (activeThumb === 0 && newValue[0] !== sliderValue[0]) {
                  updateGradient("fromPercent", newValue[0]);
                }
                if (activeThumb === 1 && newValue[1] !== sliderValue[1]) {
                  updateGradient("toPercent", newValue[1]);
                }
              }}
              valueLabelDisplay="auto"
              componentsProps={{
                Track: {
                  sx: { color: "#3F51B5" },
                },
                Rail: {
                  sx: { color: "#3F51B5" },
                },
                Thumb: {
                  sx: { color: "#3F51B5" },
                },
              }}
            />
          </Box>
        </div>
        <div className={classes.removeGradient}>
          <Button
            onClick={onClear}
            sx={{ textTransform: "capitalize", color: "#0000008A" }}
          >
            <DeleteOutline />
            {t("gradientPicker.removeGradient", "Fjern gradient")}
          </Button>
        </div>
      </div>
    )
  );
};
