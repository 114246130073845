import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Sidebar } from "./Sidebar";
import { useScrollyTellProps } from "./scrollytellContext";
import { initializeStory } from "./service";
import { Story } from "../Story/Story";

const useStyles = createUseStyles({
  admin: {
    display: "flex",
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    height: "100%",
  },
});


export const Admin = ({ sidePanel }) => {
  const { onChange, story } = useScrollyTellProps();
  const [currentSection, setCurrentSection] = useState(0);
  const [isPreview] = useState(false);

  const classes = useStyles({});

  useEffect(() => {
    if (
      story === undefined ||
      Object.keys(story).length === 0 ||
      story.sections === undefined
    ) {
      onChange(initializeStory(story));
    }
  }, [story, onChange]);

  return (
    <div className={classes.admin}>
      <Sidebar
        appBar={story?.appBar}
        sections={story?.sections}
        currentSection={currentSection}
        isOpen={sidePanel}
      />
      {story && (
        <Story
          data={story}
          isAdminMode={!isPreview}
          showGrid={false}
          onSectionChange={setCurrentSection}
          showSidePanel={sidePanel}
        />
      )}
    </div>
  );
};
