import React, { useRef } from "react";
import { createUseStyles } from "react-jss";
import { useClickOutside } from "../common/useClickOutside";
import { updateSection } from "../Admin/service";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { SectionMinHeights } from "../helpers/enums";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles(({ colors }) => ({
  subMenu: {
    top: "41px",
    left: "2px",
    position: "absolute",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
  heightMenuItemText: {
    fontSize: "12px",
    fontWeight: "bold",
    "&.active": {
      color: colors.adminBlue,
    },
  },
}));

export const SectionHeightSubMenu = ({ sectionId, section, onClose }) => {
  const t = useScrollytellTranslation();
  const heightMenuRef = useRef();
  const { story, onChange } = useScrollyTellProps();
  useClickOutside(heightMenuRef, () => onClose());
  const classes = useStyles();

  const setMinHeight = (minHeight) => {
    if (minHeight) {
      onChange(updateSection(story, sectionId, { minHeight }));
    }
  };

  return (
    <AdminIconBar ref={heightMenuRef} className={classes.subMenu}>
      <AdminIconBarItem
        Custom={
          <span
            className={`${classes.heightMenuItemText} ${
              section.minHeight === SectionMinHeights.ONE_AND_HALF
                ? "active"
                : ""
            }`}
          >
            1 &#189;
          </span>
        }
        onClick={() => setMinHeight(SectionMinHeights.ONE_AND_HALF)}
        tooltip={t(
          "sectionHeightSubMenu.oneAndHalfTooltip",
          "Sett en og en halv skjermhøyde"
        )}
        classname={classes.subMenuItem}
      />
      <AdminIconBarItem
        Custom={
          <span
            className={`${classes.heightMenuItemText} ${
              section.minHeight === SectionMinHeights.FULL ||
              section.minHeight === undefined
                ? "active"
                : ""
            }`}
          >
            1
          </span>
        }
        onClick={() => setMinHeight(SectionMinHeights.FULL)}
        tooltip={t("sectionHeightSubMenu.oneTooltip", "Sett en skjermhøyde")}
        classname={classes.subMenuItem}
      />
      <AdminIconBarItem
        Custom={
          <span
            className={`${classes.heightMenuItemText} ${
              section.minHeight === SectionMinHeights.HALF ? "active" : ""
            }`}
          >
            &#189;
          </span>
        }
        onClick={() => setMinHeight(SectionMinHeights.HALF)}
        tooltip={t(
          "sectionHeightSubMenu.halfTooltip",
          "Sett en halv skjermhøyde"
        )}
        classname={classes.subMenuItem}
      />
      <AdminIconBarItem
        Custom={
          <span
            className={`${classes.heightMenuItemText} ${
              section.minHeight === SectionMinHeights.QUARTER ? "active" : ""
            }`}
          >
            &#188;
          </span>
        }
        onClick={() => setMinHeight(SectionMinHeights.QUARTER)}
        tooltip={t(
          "sectionHeightSubMenu.quarterTooltip",
          "Sett en kvart skjermhøyde"
        )}
        classname={classes.subMenuItem}
      />
      <AdminIconBarItem
        Custom={
          <span
            className={`${classes.heightMenuItemText} ${
              section.minHeight === SectionMinHeights.CONTENT ? "active" : ""
            }`}
          >
            {t("sectionHeightSubMenu.contentText", "Tilpass innhold")}
          </span>
        }
        onClick={() => setMinHeight(SectionMinHeights.CONTENT)}
        tooltip={t("sectionHeightSubMenu.contentText", "Tilpass til innhold")}
        classname={classes.subMenuItem}
      />
    </AdminIconBar>
  );
};
