import {BlockTypes} from "../helpers/enums";

export const getEmptyBlockByType = (type) => {
    switch (type) {
        case BlockTypes.TEXT:
            return {
                type,
                text: undefined,
            };
        case BlockTypes.IMAGE:
            return {
                type,
                imagePath: "",
                altText: "",
                caption: undefined,
                showCaptionIcon: false,
                color: undefined,
                margin: undefined,
                padding: undefined,
                backgroundColor: undefined,
            };
        case BlockTypes.VIDEO:
            return {
                type,
                videoUrl: "",
                caption: undefined,
                autoPlay: true,
                showControls: false,
            };
        case BlockTypes.VIDEO_SCROLL:
            return {
                type,
                videoUrl: "",
                playbackConstant: 200,
            };
        case BlockTypes.QUIZ:
            return {
                type,
                question: "",
                alternatives: [
                    {
                        alternative: "a",
                        text: "",
                    },
                    {
                        alternative: "b",
                        text: "",
                    },
                    {
                        alternative: "c",
                        text: "",
                    },
                ],
                answer: "a",
            };
        case BlockTypes.THREE_D:
            return {
                type,
                scene: undefined,
                showToolbar: false,
                enablePan: false,
                enableZoom: false,
            };
        case BlockTypes.CODEWORD:
            return {
                type: type,
                secret: "",
                text: "",
                locked: false,
                unlocked: false
            }
        case BlockTypes.READ_MORE:
            return {
                type: type,
                icon: "info",
                buttonText: "Vis mer",
                buttonColor: "#1B8751",
                contentType: "dialog",
                blocks: [
                    {
                        type: BlockTypes.TEXT,
                        text: undefined
                    },
                    {
                        type: BlockTypes.TEXT,
                        text: undefined
                    }
                ],
                maxWidth: undefined,
                backdrop: {
                    backgroundColor: undefined,
                    transparency: "0.8"
                },
                backgroundColor: undefined,
                roundedCorners: true,
                url: undefined
            }
        default:
            return undefined;
    }
};

