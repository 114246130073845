import React, { useCallback, useState } from "react";
import { MiniSection } from "./MiniSection";
import { createUseStyles } from "react-jss";
import { updateSectionOrder } from "./service";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "./scrollytellContext";
import { Add } from "@mui/icons-material";
import { Dialog } from "../common/Dialog";
import { SectionTemplatePicker } from "./SectionTemplatePicker";

const useStyles = createUseStyles(({ colors }) => ({
  sections: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "103px",
  },
  addSectionButton: {
    bottom: 0,
    width: "208px",
    height: "103px",
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#666666",
    border: "none",
    color: colors.white,
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.75)",
    },
  },
}));

export const Sections = ({ sections, currentSection }) => {
  const classes = useStyles();
  const { story, onChange } = useScrollyTellProps();
  const t = useScrollytellTranslation();
  const [showModal, setShowModal] = useState(false);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      onChange(updateSectionOrder(story, dragIndex, hoverIndex));
    },
    [sections]
  );

  return (
    <div className={classes.sections}>
      {sections?.map((section, i) => (
        <MiniSection
          key={section.order}
          index={i}
          section={section}
          currentSection={currentSection}
          moveSection={moveCard}
          totalSections={sections.length}
        />
      ))}
      <button
        className={classes.addSectionButton}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Add />
        {t("newSectionButton", "Ny seksjon")}
      </button>
      <Dialog
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={t("sectionTemplateModalTitle", "Sett inn ny seksjon")}
      >
        <SectionTemplatePicker onClose={() => setShowModal(false)} />
      </Dialog>
    </div>
  );
};
