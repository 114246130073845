import React from "react";
import {ReadMoreButton} from "./ReadMoreButton";
import {AdminActions} from "../Block/AdminActions";
import {CONTENT_MODAL_OPENED, POPOVER_OPENED, useBlockDispatch} from "./blockContext";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    readMoreBlock: {
        width: "100%",
        textAlign: "center"
    }
})

export const ReadMoreBlock = ({blockState, isAdminMode=false, isActiveBlock=false, children}) => {
    const readMoreDispatch = useBlockDispatch()
    const classes = useStyles()

    const handleReadMore = event => {
        if(isAdminMode) {
            readMoreDispatch({
                type: POPOVER_OPENED,
                payload: event.currentTarget
            })
        } else if("link" === blockState.contentType) {
                if(blockState.url) {
                    window.open(blockState.url, "_blank")
                }
        } else {
            readMoreDispatch({type: CONTENT_MODAL_OPENED})
        }
    }

    return (
        <div className={classes.readMoreBlock}>
            <ReadMoreButton
                onClick={handleReadMore}
                buttonText={blockState.buttonText}
                buttonColor={blockState.buttonColor}
                icon={blockState.icon}
            />
            <AdminActions
                show={isActiveBlock}
            >
                {children}
            </AdminActions>
        </div>
    )
}