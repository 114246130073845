import {SectionBackgroundImageSubMenu} from "../Section/SectionBackgroundImageSubMenu";
import {SectionHeightSubMenu} from "../Section/SectionHeightSubMenu";
import {BackgroundColorSubMenu} from "../Section/BackgroundColorSubMenu";
import {VerticalAlignMenu} from "../Section/VerticalAlignMenu";
import React from "react";

export const SubMenu = ({section, sectionId, currentMenu, onCloseMenu}) => {
    switch (currentMenu) {
        case "wallpaperMenu":
            return (
                <SectionBackgroundImageSubMenu
                    section={section}
                    sectionId={sectionId}
                    onClose={onCloseMenu}
                />
            )
        case "heightMenu":
            return (
                <SectionHeightSubMenu
                    section={section}
                    sectionId={sectionId}
                    onClose={onCloseMenu}
                />
            )
        case "colorMenu":
            return (
                <BackgroundColorSubMenu
                    section={section}
                    sectionId={sectionId}
                    onClose={onCloseMenu}
                />
            )
        case "verticalAlignMenu":
            return (
                <VerticalAlignMenu
                    sectionId={sectionId}
                    onClose={onCloseMenu} />
            )
        default:
            return null
    }
}