import React from "react";
import {TextField,} from "@mui/material";
import {EditAlternatives} from "./EditAlternatives";
import {EditQuizContainer} from "./EditQuizContainer";
import {EditLock} from "./EditLock";
import {AdminActions} from "../Block/AdminActions";
import {useContrastApproved} from "../helpers/useContrast";
import {theme} from "@ekultur/vm";

export const EditQuizBlock = ({block, isActiveBlock, children, onPropertyUpdate, containerBackground}) => {

    const updateAlternative = (alternative, text) => {
        const updatedAlternatives = block.alternatives.map((a) =>
            a.alternative === alternative ? {...a, text} : a
        );
        onPropertyUpdate("alternatives", updatedAlternatives);
    };


    const handleUpdateQuestion = event => onPropertyUpdate("question", event.target.value);
    const contrastApproved = useContrastApproved(
        containerBackground !== undefined
            ? containerBackground.substring(0, 7)
            : theme.colors.white,
        theme.colors.white
    )

    return (
        <EditQuizContainer>
            <TextField
                label="Question"
                value={block.question}
                onChange={handleUpdateQuestion}
                sx={{
                    m: 1,
                    'fieldset:not(:focus)': {
                        color: contrastApproved ? "white" : "black",
                        borderColor: contrastApproved ? "white !important" : "black",
                    },
                    'input': {
                        color: contrastApproved ? "white" : "black",
                    }
                }}
                InputLabelProps={{
                    sx: {
                        color: contrastApproved ? "white !important" : "black",
                    }
                }}
            />
            <EditAlternatives
                alternatives={block.alternatives}
                answer={block.answer}
                updateProperty={onPropertyUpdate}
                updateAlternative={updateAlternative}
                contrastApproved={contrastApproved}
            />
            <EditLock
                locked={block.locked}
                onChange={onPropertyUpdate}
                contrastApproved={contrastApproved}
            />
            <AdminActions
                show={isActiveBlock}
            >
                {children}
            </AdminActions>
        </EditQuizContainer>
    );
};
