import React, { useEffect, useState } from "react";
import { FormatColorText, Palette, FormatColorFill } from "@mui/icons-material";
import { createUseStyles } from "react-jss";
import { IconButton, Tooltip } from "@mui/material";
import { useScrollytellTranslation } from "../../Admin/scrollytellContext";

const useStyles = createUseStyles(({ colors }) => ({
  richTextColorPicker: {
    order: 4,
    position: "relative",
  },
  selectButton: {},
  picker: {
    display: "flex",
    flexDirection: "column",
  },
  color: {
    display: "flex",
    padding: "4px 10px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `${colors.black}11`,
    },
    "&.active": {
      backgroundColor: `${colors.black}11`,
    },
  },
  colorIndicator: {
    display: "flex",
    width: "24px",
    height: "24px",
    borderRadius: "48px",
    border: `1px solid ${colors.black}`,
    marginRight: "10px",
  },
  colorLabel: {},
  tabs: {
    backgroundColor: colors.white,
    position: "absolute",
    top: "48px",
    border: "1px solid #F1F1F1",
    borderTop: "none",
    borderRadius: "4px",
    padding: "5px",
  },
  tabOptions: {
    display: "flex",
    marginBottom: "10px",
  },
  tabOption: {
    width: "25px",
    padding: "5px 10px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": { backgroundColor: colors.coolGray200 },
    "&.active": { backgroundColor: colors.coolGray200 },
  },
}));

const TabOptions = Object.freeze({ COLOR: "color", BGCOLOR: "bgColor" });

export const RichTextColorPicker = (props) => {
  const {
    config,
    expanded,
    onExpandEvent,
    onChange,
    currentState,
    defaultColor,
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TabOptions.COLOR);
  const t = useScrollytellTranslation();

  useEffect(() => {
    if (defaultColor) {
      onChange("color", defaultColor);
    }
  }, [defaultColor]);

  const tabs = (
    <>
      <div
        className={`${classes.tabOption} ${
          activeTab === TabOptions.COLOR ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab(TabOptions.COLOR);
        }}
      >
        <Tooltip title={t("richTextFontColorButton", "Skriftfarge")}>
          <FormatColorText sx={{ width: "1em", height: "1em" }} />
        </Tooltip>
      </div>
      <div
        className={`${classes.tabOption} ${
          activeTab === TabOptions.BGCOLOR ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab(TabOptions.BGCOLOR);
        }}
      >
        <Tooltip
          title={t("richTextFontBackgroundColorButton", "Farge bak skriften")}
        >
          <FormatColorFill sx={{ width: "1em", height: "1em" }} />
        </Tooltip>
      </div>
    </>
  );
  return (
    <div
      className={classes.richTextColorPicker}
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
    >
      <IconButton onClick={onExpandEvent} className={classes.selectButton}>
        <Palette sx={{ width: "1em", height: "1em" }} />
      </IconButton>
      {expanded ? (
        <Tabs tabs={tabs}>
          {activeTab === TabOptions.COLOR && (
            <Picker
              color={currentState.color || defaultColor}
              colors={config.colors?.colors}
              onChange={onChange}
              type="color"
            />
          )}
          {activeTab === TabOptions.BGCOLOR && (
            <Picker
              color={currentState.bgColor}
              colors={config.colors?.bgColors}
              onChange={onChange}
              type="bgcolor"
            />
          )}
        </Tabs>
      ) : null}
    </div>
  );
};

const Picker = ({ color, colors, onChange, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.picker}>
      {colors.map(({ hex, label }) => (
        <Color
          className={color === hex ? "active" : ""}
          key={`color-${label}`}
          color={hex}
          label={label}
          onClick={(color) => onChange(type, color)}
        />
      ))}
    </div>
  );
};

const Color = ({ color, label, onClick, className }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.color} ${className}`}
      onClick={() => onClick(color)}
    >
      <span
        className={classes.colorIndicator}
        style={{ backgroundColor: color }}
      />
      {label && <span className={classes.colorLabel}>{label}</span>}
    </div>
  );
};

const Tabs = ({ tabs, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.tabs}>
      <div className={classes.tabOptions}>{tabs}</div>
      {children}
    </div>
  );
};
