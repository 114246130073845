import {createUseStyles} from "react-jss";
import {useState} from "react";

const useStyles = createUseStyles({
    videoPlayButtonContainer: ({visibility}) => ({
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: visibility,
        maxHeight: "100%"
    }),
    playButton: {
        width: "20%",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderRadius: "50%",
        aspectRatio: "1/1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "background-color 300ms ease, transform 80ms",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.95)",
            transform: "scale(1.025)"
        },
        "&:active": {
            transform: "scale(0.96)"
        },
        zIndex: 1,
    },
    playSvg: {
        width: "60%",
        height: "auto",
        fill: "white",
        stroke: "#ffffff",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        transform: "translate(2%, -1%)"
    }

})

export const VideoPlayButton = ({onPlay, autoplay=false, children}) => {
    const [showPlayButton, setShowPlayButton] = useState(!autoplay)
    const classes = useStyles({
        visibility: showPlayButton ? "visible" : "hidden",
    })

    const handlePlay = () => {
        setShowPlayButton(false)
        onPlay()
    }

    return (
        <div>
            <div
                className={classes.videoPlayButtonContainer}
            >
                <div
                    className={classes.playButton}
                    onClick={handlePlay}
                >
                    <svg
                        viewBox={"0 0 24 24"}
                        xmlns={"http://www.w3.org/2000/svg"}
                        className={classes.playSvg}
                    >
                        <polygon points={"6 3 20 12 6 21 6 3"}/>
                    </svg>
                </div>
            </div>
            {children}
        </div>
    )
}