import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {
    ExternalLink,
    Eye,
    Film,
    Forward,
    HelpCircle,
    Image,
    Info,
    Lightbulb,
    Newspaper,
    Play,
    Plus,
    Zap
} from "lucide-react";

export const useReadMoreIconAndLabels = () => {
    const t = useScrollytellTranslation()
    return {
        "info": {
            icon: <Info/>,
            label: t("readMore.infoLabel", "Info")
        },
        "externalLink": {
            icon: <ExternalLink/>,
            label: t("readMore.externalLinkLabel", "Ekstern lenke")
        },
        "eye": {
            icon: <Eye/>,
            label: t("readMore.eyeLabel", "Øye")
        },
        "lightning": {
            icon: <Zap/>,
            label: t("readMore.lightningLabel", "Lyn")
        },
        "plus": {
            icon: <Plus/>,
            label: t("readMore.plusLabel", "Pluss")
        },
        "newspaper": {
            icon: <Newspaper/>,
            label: t("readMore.newspaperLabel", "Avis")
        },
        "forward": {
            icon: <Forward/>,
            label: t("readMore.forwardLabel", "Fremover")
        },
        "movie": {
            icon: <Film/>,
            label: t("readMore.movieLabel", "Film")
        },
        "picture": {
            icon: <Image/>,
            label: t("readMore.pictureLabel", "Bilde")
        },
        "play": {
            icon: <Play/>,
            label: t("readMore.playLabel", "Spill")
        },
        'help': {
            icon: <HelpCircle/>,
            label: t("readMore.helpLabel", "Spørsmålstegn")
        },
        "lightbulb": {
            icon: <Lightbulb/>,
            label: t("readMore.lightbulbLabel", "Lyspære")
        }
    }
}