import {BlockTypes} from "../helpers/enums";
import React, {useState} from "react";
import {AdminTextBlock} from "../TextBlock/AdminTextBlock";
import {AdminImageBlock} from "../ImageBlock/AdminImageBlock";
import {EditVideoBlock} from "../VideoBlock/EditVideoBlock";
import {useIsActiveBlock} from "./useIsActiveBlock";
import {CopyBlockButton} from "./CopyBlockButton";
import {RemoveBlockButton} from "./RemoveBlockButton";
import {SelectableBlock} from "./SelectableBlock";
import useDeepCompareEffect from "use-deep-compare-effect";

export const AdminSubBlock = ({sectionId, containerId, blockId, block, isAdminMode, onUpdate, onRemove, onAdd, children}) => {
    const [blockState, setBlockState] = useState({
        data: block,
        modified: false
    })
    const isActiveBlock = useIsActiveBlock({
        sectionId: sectionId,
        containerId: containerId,
        blockId: blockId
    })

    useDeepCompareEffect(() => {
        setBlockState({
            data: block,
            modified: false
        });
    }, [block])

    const handleUpdate = updatedBlock => {
        onUpdate(blockId, updatedBlock)
    }

    const handleCopyBlock = copyBlock => {
        onAdd(copyBlock)
    }

    const handleRemoveBlock = () => {
        onRemove(blockId)
    }

    const handleUpdateState = (property, value) => {
        setBlockState({
            data: {
                ...blockState.data,
                [property]: value,
            },
            modified: true
        });
    }

    const handleCommitState = () => {
        if (blockState.modified) {
            handleUpdate(blockState.data)
        }
    }

    const blockByType = {
        [BlockTypes.TEXT]: (
            <AdminTextBlock
                text={block.text}
                placeholder={blockState.data.placeholder}
                isActiveBlock={isActiveBlock}
                onPropertyUpdate={handleUpdateState}
            >
                <CopyBlockButton
                    sectionId={sectionId}
                    containerId={containerId}
                    block={block}
                    onCopy={handleCopyBlock}
                />

                <RemoveBlockButton
                    blockId={blockId}
                    onRemove={handleRemoveBlock}
                />
            </AdminTextBlock>
        ),
        [BlockTypes.IMAGE]: (
            <AdminImageBlock
                sectionId={sectionId}
                containerId={containerId}
                block={block}
                onUpdate={handleUpdate}
                isActiveBlock={isActiveBlock}
            >
                <CopyBlockButton
                    sectionId={sectionId}
                    containerId={containerId}
                    block={block}
                    onCopy={handleCopyBlock}
                />

                <RemoveBlockButton
                    blockId={blockId}
                    onRemove={handleRemoveBlock}
                />
            </AdminImageBlock>
        ),
        [BlockTypes.VIDEO]: (
            <EditVideoBlock
                block={block}
                blockId={blockId}
                containerId={containerId}
                sectionId={sectionId}
                onUpdate={handleUpdate}
                isActiveBlock={isActiveBlock}
            >
                <CopyBlockButton
                    sectionId={sectionId}
                    containerId={containerId}
                    block={block}
                    onCopy={handleCopyBlock}
                />
                <RemoveBlockButton
                    blockId={blockId}
                    onRemove={handleRemoveBlock}
                />
            </EditVideoBlock>
        ),
    }

    if(isAdminMode) {
        return (
            <SelectableBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                onBlur={handleCommitState}
            >
                {blockByType[block.type]}
            </SelectableBlock>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }

}