import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {useState} from "react";
import {useProgressState} from "./progressContext";
import {ExpandableMenu} from "./ExpandableMenu";
import {Indicator} from "./Indicator";

export const ChapterProgress = ({show}) => {
    const [open, setOpen] = useState(false)
    const [hoveredSectionId, setHoveredSectionId] = useState(null)
    const {locks} = useProgressState()
    const {story} = useScrollyTellProps()
    const sections = (story?.sections || []).map((section, idx) => ({
        ...section,
        id: idx,
    })).filter(section => !section.excludeFromNav || locks.find(lock => lock.sectionId === section.id))

    const lockedSections = locks.map(lock => lock.sectionId)

    const handleSelectChapter = sectionId => {
        if(open) {
            document.getElementById(`section-${sectionId + 1}`).scrollIntoView();
            setTimeout(() => {
                setOpen(false)
                setHoveredSectionId(null)
            }, 200)

        } else {
            setOpen(true)
        }
    }

    const handleExpand = () => setOpen(true)

    const handleShrink = event => {
        if("touch" !== event.pointerType) {
            setHoveredSectionId(null)
            setOpen(false)
        }

    }

    if(!show || sections.length < 4 || !story.settings?.enableNav) {
        return null
    }

    return (
        <ExpandableMenu
            expanded={open}
            onOpen={handleExpand}
            onClose={handleShrink}
        >
            {sections.map((section, idx) => (
                <Indicator
                    key={section.id}
                    locked={locks.find(lock => lock.sectionId === section.id)}
                    afterLock={section.id > lockedSections[0]}
                    sectionId={section.id}
                    title={section?.title || `Seksjon ${idx+1} av ${sections.length}`}
                    open={open}
                    hoveredSectionId={hoveredSectionId}
                    onHover={setHoveredSectionId}
                    onClick={handleSelectChapter}
                />
            ))}
        </ExpandableMenu>
    )
}