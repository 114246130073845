import React, {useEffect, useRef, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {SlowMotionVideoOutlined} from "@mui/icons-material";
import {createUseStyles} from "react-jss";
import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {VideoScrollMenu} from "./VideoScrollMenu";
import {useScrollPercentage} from "../Block/useScrollPercentage";
import {getBackgroundImageString} from "../helpers/helpers";
import {AdminActions} from "../Block/AdminActions";
import {useContentHeight} from "../map/useContentHeight";
import {SectionTypes} from "../helpers/enums";

const placeholderImage = "013AjtNogoyt";

const useStyles = createUseStyles(({colors}) => ({
    blockWrapper: {
        height: ({wrapperHeight}) => wrapperHeight || "100%",
        width: "100%",
    },
    content: {
        height: ({contentHeight}) => `${contentHeight}px`,
        width: "100%",
        position: "sticky",
        top: 0,
        display: "flex",
        alignItems: "center",
    },
    videoScrollBlockEdit: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        "&:focus": {
            backgroundColor: "#ffffff22",
        },
    },

    videoUpload: {
        top: "2px",
        left: "calc(100% - 52px)",
        cursor: "pointer",
        height: "100%",
    },
    videoScrollBlockPlaceholder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#A6A6A661",
        minHeight: "400px",
    },
    videoScrollBlockCaption: {
        padding: "16px",
    },
    videoScrollBlockEditButton: {
        height: "48px",
    },
    videoScrollBlockCaptionText: {
        width: "100%",
    },
    captionIcon: {
        width: "12px",
        margin: "0 14px",
    },
}));

export const EditVideoScrollBlock = ({block, onUpdate, isActiveBlock, sectionId, children}) => {
    const [duration, setDuration] = useState();
    const [wrapperHeight, setWrapperHeight] = useState();
    const root = document.getElementById("scrollytell-content");
    const contentHeight = useContentHeight()
    const {story} = useScrollyTellProps();
    const steps =
        story.sections[sectionId].type === SectionTypes.STICKY &&
        story.sections[sectionId].containers.length;
    const option =
        root !== null
            ? {root, threshold: block.playbackConstant / 10000}
            : {threshold: block.playbackConstant / 10000};
    const [wrapperRef, percentage] = useScrollPercentage(option);
    const videoRef = useRef();
    const t = useScrollytellTranslation();
    const {onMediaSelect} = useScrollyTellProps();

    const isVisible = percentage > 0;
    const onMetadataLoaded = (e) => {
        setDuration(e.target.duration);
    };
    const {playbackConstant} = block

    useEffect(() => {
        if (block.playbackConstant && duration) {
            setWrapperHeight(Math.floor(duration) * block.playbackConstant);
        }
    }, [setWrapperHeight, duration, block.playbackConstant, Math]);

    useEffect(() => {
        if (duration) {
            const vid = videoRef?.current;
            if (vid) {
                let wrapperH;
                if (steps) {
                    wrapperH = `${(steps - 1) * contentHeight}px`;
                } else {
                    wrapperH = `${duration * playbackConstant}px`;
                }
                setWrapperHeight(wrapperH);
            }
        }
    }, [steps, contentHeight, duration]);

    const classes = useStyles({
        wrapperHeight,
        isActiveBlock,
        contentHeight: contentHeight || "100vh"
    });

    const updateProperty = (property, value) => onUpdate({
        ...block,
        [property]: value,
    })

    const setVideo = () => {
        if (onMediaSelect) {
            onMediaSelect("videoscrubb", (dmsId) => {
                updateProperty('videoUrl', dmsId)
            });
        } else {
            updateProperty('videoUrl', "013Aiu4aiAmc")
            console.warn("onMediaSelect is undefined");
        }
    };

    useEffect(() => {
        if (isVisible) {
            seek();
        }
    }, [percentage]);

    const seek = () => {
        const vid = videoRef?.current;
        if (vid?.duration) {
            vid.currentTime = vid.duration * percentage;
        }
    };

    const withContentWrapper = (content) => {
        return <div className={classes.content}>{content}</div>;
    };

    const content = (
        <div
            className={classes.videoScrollBlockEdit}
        >
            {block.videoUrl && (
                <video
                    style={{width: "100%", height: "100%"}}
                    muted={true}
                    ref={videoRef}
                    onLoadedMetadata={onMetadataLoaded}
                    src={`https://ems.dimu.org/file/${block.videoUrl}`}
                    preload="auto"
                />
            )}
            <label
                className={classes.videoUpload}
                style={{position: block.videoUrl ? "absolute" : "initial"}}
                htmlFor="videoScrollBlockUpload"
                onClick={() => setVideo()}
            >
                {!block.videoUrl && (
                    <div
                        className={classes.videoScrollBlockPlaceholder}
                        style={{
                            backgroundImage: getBackgroundImageString(
                                placeholderImage,
                                undefined,
                                true
                            ),
                            backgroundRepeat: "repeat",
                            backgroundSize: "auto",
                        }}
                    >
                        <Tooltip
                            title={t("editVideoScrollBlock.uploadImage", "Velg en video")}
                        >
                            <IconButton
                                aria-label="upload video"
                                component="span"
                                sx={{backgroundColor: "#FAFAFA"}}
                            >
                                <SlowMotionVideoOutlined/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </label>
            <AdminActions
                show={isActiveBlock}
            >
                {children}
                {block.videoUrl && (
                    <VideoScrollMenu
                        playbackConstant={block.playbackConstant}
                        onPlaybackChange={(e) =>
                            updateProperty("playbackConstant", e.target.value)
                        }
                        handleSetVideo={() => setVideo()}
                    />
                )}
            </AdminActions>
        </div>
    );

    return (
        <div className={classes.blockWrapper} ref={wrapperRef}>
            {block.videoUrl ? withContentWrapper(content) : content}
        </div>
    );
};
