import {EditTextBlock} from "./EditTextBlock";
import React, {useState} from "react";
import {AdminActions} from "../Block/AdminActions";

export const AdminTextBlock = ({text, placeholder, onPropertyUpdate, isActiveBlock,children}) => {
    return (
        <EditTextBlock
            text={text}
            placeholder={placeholder}
            onUpdate={onPropertyUpdate}
            isActiveBlock={isActiveBlock}
        >
            <AdminActions
                show={isActiveBlock}
            >
                {children}
            </AdminActions>
        </EditTextBlock>
    )
}