import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {ColorLensOutlined, RoundedCornerRounded, RoundedCornerSharp,} from "@mui/icons-material";
import {useScrollyTellProps, useScrollytellTranslation,} from "../Admin/scrollytellContext";
import {AdminIconBar} from "../icon-bar/AdminIconBar";
import {ColorPicker} from "../common/colorPicker/ColorPicker";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import {useContentHeight} from "../map/useContentHeight";

const useStyles = createUseStyles(({ colors }) => ({
  editContainerPanel: {
    position: "absolute",
    top: ({top}) => top,
    left: "4px",
    backgroundColor: colors.adminBlue,
    color: colors.white,
    borderRadius: "2px",
    zIndex: 10,
  },
}))

export const EditContainerPanel = ({sectionId, containerId, borderRadius, backgroundColor, onEdit}) => {
  const t = useScrollytellTranslation();
  const contentHeight = useContentHeight(`container-${sectionId}-${containerId}`)
  const classes = useStyles({
    top: contentHeight < 82 ? -46 : "4px"
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const {story} = useScrollyTellProps();


  const handleToggleBorderRadius = () => {
    onEdit("borderRadius", !borderRadius)
  }

  const handleChangeBackgroundColor = color => {
    onEdit('backgroundColor', color)
  }

  return (
    <div className={classes.editContainerPanel}>
      <AdminIconBar>
        <AdminIconBarItem
          Icon={ColorLensOutlined}
          onClick={() => setShowColorPicker(true)}
          tooltip={t(
            "editContainerPanel.setBackgroundColorTooltip",
            "Bakgrunnsfarge"
          )}
        />
        <AdminIconBarItem
          Icon={
            borderRadius ? RoundedCornerRounded : RoundedCornerSharp
          }
          onClick={handleToggleBorderRadius}
          tooltip={t(
            "editContainerPanel.setBorderRadiusTooltip",
            "veksle hjørneavrunding"
          )}
        />
      </AdminIconBar>
      <ColorPicker
        open={showColorPicker}
        color={backgroundColor}
        onSave={handleChangeBackgroundColor}
        onClose={() => setShowColorPicker(false)}
        templateColors={story?.settings?.colors}
      />
    </div>
  );
};
