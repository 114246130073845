import React, { useRef } from "react";
import { useClickOutside } from "../common/useClickOutside";
import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { createUseStyles } from "react-jss";
import { Stack, Switch, Typography } from "@mui/material";
import { useScrollytellTranslation } from "../Admin/scrollytellContext";

const useStyles = createUseStyles({
  subMenu: {
    top: "-5px",
    left: "-2px",
    position: "absolute",
    display: "inline-flex",
    flexDirection: "column",
    //justifyContent: "center",
    //alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    zIndex: 10,
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
});

export const OptionsMenu = ({ block, onChange, onClose }) => {
  const optionsMenu = useRef();
  const t = useScrollytellTranslation();
  useClickOutside(optionsMenu, () => onClose());

  const classes = useStyles();

  return (
    <AdminIconBar ref={optionsMenu} className={classes.subMenu}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Switch
          checked={block?.showToolbar || false}
          onChange={(e) => onChange("showToolbar", e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>
          {t("threeDBlockShowToolbarLabel", "Vis verktøylinje")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Switch
          checked={block.enablePan}
          onChange={(e) => onChange("enablePan", e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{t("threeDBlockEnablePanLabel", "Panorering")}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Switch
          checked={block.enableZoom}
          onChange={(e) => onChange("enableZoom", e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{t("threeDBlockEnableZoomTooltip", "Zoom")}</Typography>
      </Stack>
    </AdminIconBar>
  );
};
