import {Directions, DirectionsOff} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";
import {
    SET_EDIT_DIRECTIONS,
    useScrollytellDispatch,
    useScrollytellState,
    useScrollytellTranslation
} from "../Admin/scrollytellContext";

export const MapDirectionsButton = ({templateId}) => {
    const t = useScrollytellTranslation()
    const {editDirections} = useScrollytellState()
    const dispatch = useScrollytellDispatch()

    const handleClick = () => {
        dispatch({
            type: SET_EDIT_DIRECTIONS,
            editDirections: !editDirections
        });
    }


    if(7 === templateId) {
        return (
            <AdminIconBarItem
                Icon={editDirections ? DirectionsOff : Directions}
                onClick={handleClick}
                tooltip={t(
                    "editSectionPanel.mapDirections",
                    "Velg punkter i kart"
                )}
                tooltipPosition="top"
            />
        )
    } else {
        return null
    }
}