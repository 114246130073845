import React, {forwardRef, useCallback, useEffect, useRef, useState,} from "react";
import {createUseStyles} from "react-jss";
import {useInView} from "react-intersection-observer";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch, useScrollytellState,} from "../Admin/scrollytellContext";
import {EditSectionPanel} from "./EditSectionPanel";
import {SectionTypes} from "../helpers/enums";
import {StickySection} from "./StickySection";
import {ContentSection} from "./ContentSection";
import {AddContainerButton} from "./AddContainerButton";
import {SECTION_ENTERED_VIEW, useProgressDispatch} from "../progress/progressContext";
import {StickyMapSection} from "./StickyMapSection";

const useStyles = createUseStyles(({colors}) => ({
    section: {
        position: "relative",
        minHeight: "initial",
        boxSizing: "border-box",
        backgroundColor: ({backgroundColor}) => backgroundColor || "",
        border: ({isAdminMode, isActiveSection}) =>
            isAdminMode
                ? isActiveSection
                    ? `2px solid ${colors.adminBlue}`
                    : "2px solid transparent"
                : "",
        "&.hover": {
            border: ({isAdminMode, isActiveSection}) =>
                isAdminMode &&
                `2px ${isActiveSection ? "solid" : "dashed"} ${colors.adminBlue}`,
        },
        cursor: ({isAdminMode}) => (isAdminMode ? "pointer" : "default"),
    },
}));

export const Section = forwardRef(
    (
        {
            sectionId,
            isAdminMode,
            section,
            isThumbnail = false,
            onChange = () => {},
            style,
        },
        forwardRef
    ) => {
        const ref = useRef();
        const progressDispatch = useProgressDispatch()

        const [hover, setHover] = useState(false);
        const dispatch = useScrollytellDispatch();
        const {backgroundColor, order} = section;
        const [inViewRef, inView] = useInView({threshold: 0.01});
        const {activeElement} = useScrollytellState();
        const isActiveSection =
            activeElement[0] === sectionId &&
            activeElement[1] === undefined &&
            activeElement[2] === undefined;

        const classes = useStyles({
            backgroundColor,
            isAdminMode,
            isActiveSection,
        });
        useEffect(() => {
            if (inView) {
                onChange(order);
            }
        }, [inView, onChange, order]);

        const setRefs = useCallback(
            (node) => {
                ref.current = node;
                if (forwardRef !== null) {
                    forwardRef.current = node;
                }
                if (inViewRef) {

                    inViewRef(node);
                }
            },
            [inViewRef, forwardRef]
        );


        useEffect(
            () => {
                if (inView && !section.excludeFromNav) {
                    progressDispatch({
                        type: SECTION_ENTERED_VIEW,
                        payload: sectionId
                    })
                }
            }, [inView]
        )

        const handleClick = (e) => {
            if (
                !isActiveSection &&
                (
                    e.target === ref.current?.getElementsByTagName("article")[0]
                    || e.target?.parentNode?.parentNode === ref.current
                    || ref.current?.getElementsByTagName("article")[0] === e.target?.parentNode.getElementsByTagName("article")[0]
                )
            ) {
                dispatch({
                    type: SET_ACTIVE_ELEMENT,
                    activeSection: sectionId,
                });
            }
        };

        const handleMouseOver = (e) => {
            e.stopPropagation();
            setHover(true);
        };

        const handleMouseOut = (e) => {
            e.stopPropagation();
            setHover(false);
        };

        const sectionByType = {
            [SectionTypes.STICKY]: (
                <StickySection
                    sectionId={sectionId}
                    isAdminMode={isAdminMode}
                    section={section}
                    isThumbnail={isThumbnail}
                />
            ),
            [SectionTypes.CONTENT]: (
                <ContentSection
                    sectionId={sectionId}
                    isAdminMode={isAdminMode}
                    section={section}
                    isThumbnail={isThumbnail}
                />
            ),
            [SectionTypes.STICKY_MAP]: (
                <StickyMapSection
                    sectionId={sectionId}
                    isAdminMode={isAdminMode}
                    section={section}
                    isThumbnail={isThumbnail}
                />
            )
        }

        return (
            <section
                className={`${classes.section} ${isActiveSection ? "active" : ""} ${
                    hover ? "hover" : ""
                }`}
                ref={setRefs}
                style={style}
                id={isThumbnail ? `section-thumbnail-${order}` : `section-${order}`}
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {isAdminMode && isActiveSection && (
                    <>
                        <EditSectionPanel sectionId={sectionId} section={section}/>
                        <AddContainerButton
                            sectionId={sectionId}
                            gridSize={section.gridSize}
                        />
                    </>
                )}
                {sectionByType[section.type]}
            </section>
        );
    }
);
