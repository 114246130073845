import React from "react";
import {createUseStyles} from "react-jss";
import {GridHelper} from "./GridHelper/GridHelper";
import {StickyBlockContainer} from "../Container/StickyBlockContainer";
import {SectionGridSize} from "../helpers/enums";
import {Scroller, Step} from "../Scroller";
import {useScrollytellState} from "../Admin/scrollytellContext";
import {Blocks} from "../Block/Blocks";
import {MapScroll} from "../map/MapScroll";
import {MapContainer} from "../background/MapContainer";
import {useContentHeight} from "../map/useContentHeight";

const useStyles = createUseStyles({
    grid: {
        top: 0,
        position: "relative",
        minHeight: ({minHeight}) => minHeight,
        height: ({height}) => height,
        display: "grid",
        zIndex: 10,
    },
    thumbnail: {
        minHeight: "initial !important",
        height: "350px",
        width: "700px",
        gridTemplateColumns: ({gridSize}) =>
            gridSize === SectionGridSize.TWELVE_COLUMNS
                ? "repeat(12, 1fr)"
                : "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
    },
    nineColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 800px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 700px 1fr 1fr 1fr 1fr",
        },
        "@media screen and(min-width: 650px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 600px 1fr 1fr 1fr 1fr",
        },
    },
    twelveColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 1024px)": {
            gridTemplateColumns: "repeat(12, 1fr)",
        },
    }
});

export const StickyMapSection = ({
                                  sectionId,
                                  isAdminMode,
                                  section,
                                  isThumbnail,
                              }) => {
    const {
        backgroundColor,
        height,
        containers,
        gridSize = SectionGridSize.NINE_COLUMNS,
    } = section;
    const {showGrid} = useScrollytellState();
    const contentHeight = useContentHeight()
    const classes = useStyles({
        height,
        minHeight: `${5 * contentHeight}px`,
        gridSize
    });

    return (
        <>
            {showGrid && isAdminMode && (
                <GridHelper
                    sectionId={sectionId}
                    gridSize={gridSize}
                    backgroundColor={backgroundColor}
                />
            )}
            <article
                className={`${classes.grid} ${classes[gridSize]} ${
                    isThumbnail ? classes.thumbnail : ""
                }`}
            >
                <Scroller
                    section={section}
                    containersCount={containers.length}
                    isThumbnail={isThumbnail}
                    offset={0.9}
                    contentHeight={contentHeight}
                    isMap={true}
                >
                    {containers?.slice(0, isThumbnail ? 1 : containers?.length)
                        .map((container, i) => (
                        <Step data={{container, i}} key={i}>
                            <StickyBlockContainer
                                key={`container-${i}`}
                                sectionId={sectionId}
                                containerId={i}
                                isAdminMode={isAdminMode}
                                container={container}
                                gridSize={gridSize}
                                hasBlocks={Boolean(container.blocks?.length)}
                            >
                                <Blocks
                                    sectionId={sectionId}
                                    containerId={i}
                                    isAdminMode={isAdminMode}
                                    blocks={container.blocks}
                                />
                            </StickyBlockContainer>
                        </Step>
                    ))}
                </Scroller>
            </article>
            <MapContainer>
                <MapScroll
                    containersCount={containers.length}
                    zoom={section.zoom}
                    sectionId={sectionId}
                    contentHeight={contentHeight}
                    isThumbnail={isThumbnail}
                />
            </MapContainer>
        </>
    );
};
