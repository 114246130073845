export const demo03 = {
    appBar: {
        backgroundColor: "#0C0B0F",
        mode: "dark",
        exhibition: {
            name: "Forrige århundrets mote",
            description: "Bli inspirert av forrige århundrets mote!",
            url: "https://beta.virtueltmuseum.no/museum/12/exhibition/ff335e35-98ec-4e36-9c32-79428f917b69",
        },
        museum: {
            name: "Museene i Akershus",
            url: "https://beta.virtueltmuseum.no/museum/12",
        },
    },
    sections: [
        {
            type: "sticky",
            templateId: 6,
            backgroundImage: [
                { image: "013AitqX8AMt" },
                { image: "013AitqX8AMu" },
                { image: "013AitqX8AMv" },
                { image: "013AitqX8ASB" },
                { image: "013AitqX8ASC" },
                { image: "013AitqX8ASD" },
                { image: "013AitqX8ASE" },
                { image: "013AitqX8ASF" },
                { image: "013AitqX8ASG" },
                { image: "013AitqX8ASH" },
            ],
            order: 1,
            containers: [
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"4ljtn","text":"En telefonist skal i utgangspunktet høres, og ikke sees. Men Mina Rognlien som står til venstre, har all grunn til å bli sett på i den tidløse kjolen sin. Hvite prikker på svart er like klassisk som den lille jentas rutemønster. Tre ikoniske påkledninger! -1915","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":261,"style":"color-rgb(0,0,0)"},{"offset":0,"length":261,"style":"fontsize-medium"},{"offset":0,"length":261,"style":"fontfamily-Work Sans"},{"offset":0,"length":261,"style":"color-rgb(12,11,15)"},{"offset":0,"length":261,"style":"fontsize-1rem"},{"offset":0,"length":261,"style":"fontfamily-Work Sans"},{"offset":0,"length":261,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{"text-align":"start"}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"7oqlq","text":"Mange av de som er avfotografert av Niels Juel, var sommergjester som til vanlig bodde i storbyen. I Kristiania var de ikke redd for å bruke mønster på klærne sine, samme hvor mye det blir sagt at man ikke skal blande flere mønster sammen. -1920","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":245,"style":"color-rgb(12,11,15)"},{"offset":0,"length":245,"style":"fontsize-16"},{"offset":0,"length":245,"style":"fontfamily-Work Sans"},{"offset":0,"length":245,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"5sc29","text":"Både fluffy og fasjonabel må da være lov! Denne jenta vet alt om den kombinasjonen. Både kåpe og lue er optimale for iskalde vinterdager, og man kan si at de svarte bootsene hennes er limet som holder hele antrekket sammen. -1920","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":229,"style":"color-rgb(12,11,15)"},{"offset":0,"length":229,"style":"fontsize-16"},{"offset":0,"length":229,"style":"fontfamily-Work Sans"},{"offset":0,"length":229,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"51url","text":"Selv om bukser ikke var et ukjent klesplagg på begynnelsen av 1900-tallet, var det ikke mange kvinner som brukte dem til vanlig. Det er her stilsetteren på dette bildets venstreside kommer inn. Hun har nemlig tatt et oppgjør med datidens normer, og viser frem hvordan kvinners klesstil kunne se ut. -1920","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":304,"style":"color-rgb(12,11,15)"},{"offset":0,"length":304,"style":"fontsize-16"},{"offset":0,"length":304,"style":"fontfamily-Work Sans"},{"offset":0,"length":304,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"daqti","text":"Niels Juel Sr. står på trappene til storhet, med nypresset dress og skinnende lærsko. Bowlerhatten er også selvsagt på. Man er ikke husets herre uten en sånn en pent plassert på hodet. – 1909","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":191,"style":"color-rgb(12,11,15)"},{"offset":0,"length":191,"style":"fontsize-16"},{"offset":0,"length":191,"style":"fontfamily-Work Sans"},{"offset":0,"length":191,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"bokfb","text":"En av sommergjestene på Viken Gård viser seg frem med ett av sine peneste plagg, nemlig denne flotte kjolen med blomstermønster. De lange ermene er ganske unike for tiden hun levde i, så hun fikk garantert noen fine komplementer for kjolen sin. – 1915","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":251,"style":"color-rgb(12,11,15)"},{"offset":0,"length":251,"style":"fontsize-16"},{"offset":0,"length":251,"style":"fontfamily-Work Sans"},{"offset":0,"length":251,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"2m1t2","text":"Fire av Niels Juels sommergjester viser frem sin flotte stil. Kanskje det er mamma bakerst i bildet som har bestemt klærne til barna sine. Det tyder i så fall på en skarp motesans. – 1920","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":187,"style":"color-rgb(12,11,15)"},{"offset":0,"length":187,"style":"fontsize-16"},{"offset":0,"length":187,"style":"fontfamily-Work Sans"},{"offset":0,"length":187,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"aotqs","text":"Denne herrens utkledning skriker stil og selvsikkerhet. Gullkjedet over magen forteller at han har en klokke i innerlommen i tillegg, og både dressknappene og skoene hans er polert til de skinner. Dette er utvilsomt en mann som har stått opp på riktig siden av sengen. – 1921","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":275,"style":"color-rgb(12,11,15)"},{"offset":0,"length":275,"style":"fontsize-16"},{"offset":0,"length":275,"style":"fontfamily-Work Sans"},{"offset":0,"length":275,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"f4o6v","text":"Denne kvinnen har en rolig og elegant stil. Sjelden kan man gå feil med en enkel hvit bluse kombinert med et mønstrete skjørt. Blondene i halsen, beltespennen, og blomsten hun har festet i blusen sin gjør stilen hennes unik og egen. -1915","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":238,"style":"color-rgb(12,11,15)"},{"offset":0,"length":238,"style":"fontsize-16"},{"offset":0,"length":238,"style":"fontfamily-Work Sans"},{"offset":0,"length":238,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
                {
                    startColumn: 4,
                    endColumn: 7,
                    horizontalAlign: "right",
                    stepPosition: "bottom",
                    blocks: [
                        {
                            type: "text",
                            text: '{"blocks":[{"key":"2e3qp","text":"For en sjarmør! Denne unike drakten er det ikke mange barn som kan skryte av å ha. Stripene kombinert med blomstene og hatten gjør dette barnet til en av de kulest kledde på Viken Gård. -1915","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":191,"style":"color-rgb(12,11,15)"},{"offset":0,"length":191,"style":"fontsize-16"},{"offset":0,"length":191,"style":"fontfamily-Work Sans"},{"offset":0,"length":191,"style":"bgcolor-transparent"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                        },
                    ],
                    backgroundColor: "#ffffffb3",
                },
            ],
        },
    ],
}