import React from "react";
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles(({colors}) => ({
    alternative: {
        display: "flex",
        alignItems: "center",
        border: ({whiteBorder}) => `2px solid ${whiteBorder ? colors.white : colors.black}`,
        borderRadius: "999px",
        padding: "11px 24px 13px 20px",
        margin: "8px 0",
        minHeight: "24px"
    },
}));
export const AlternativeContainer = ({onClick=null, variantClass="", whiteBorder=false, children}) => {
    const classes = useStyle({
        whiteBorder: whiteBorder
    })
    return (
        <div
            className={`${classes.alternative} ${variantClass}`}
            onClick={onClick}
        >
            {children}
        </div>
    )
}