import {FormControlLabel, Radio} from "@mui/material";
import React from "react";

export const EditAlternativeCorrectAnswer = ({checked, value, onChange, namePostfix, contrastApproved}) => {

    const handleChange = event => {
        onChange("answer", event.target.value)
    }

    return (
        <FormControlLabel
            sx={{
                color: contrastApproved ? "white" : "black",
            }}
            control={
                <Radio
                    checked={checked}
                    onChange={handleChange}
                    value={value}
                    name={`quiz-${namePostfix}`}
                    inputProps={{"aria-label": value}}
                    sx={{
                        'span': {
                            color: contrastApproved ? "white" : "black",
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            color: contrastApproved ? "white !important" : "black",
                        }
                    }}
                />
            }
            label="correct answer"
        />
    )
}