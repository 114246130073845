import {Typography} from "@ekultur/vm";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    text: {
        color: "#D9D9D9",
    }
})

export const SectionTitle = ({show, children}) => {
    const classes = useStyles()
    if(show) {
        return (
            <Typography
                tag="h5"
                size="sm"
                className={classes.text}
            >
                {children}
            </Typography>
        )
    } else {
        return null
    }
}