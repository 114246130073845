import {Button} from "@mui/material";
import {Volume2} from "lucide-react";
import {useState} from "react";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const UnmuteButton = ({hide, onUnmute}) => {
    const [showUnmuteButton, setShowUnmuteButton] = useState(true)
    const t = useScrollytellTranslation()

    const handleClick = event => {
        setShowUnmuteButton(false)
        onUnmute()
    }
    const buttonColor = "rgba(0, 0, 0, 0.8)"
    const color = "white"

    return (
        <Button
            type={"button"}
            onClick={handleClick}
            sx={{
                border: "none",
                outline: "none",
                display: "inline-flex",
                borderRadius: "999px",
                backgroundColor: buttonColor,
                padding: "8px 12px 8px 12px",
                gap: "8px",
                color: color,
                alignItems: "center",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.95)",
                    transform: "scale(1.025)"
                },
                "&:active": {
                    transform: "scale(0.96)"
                },
                visibility: showUnmuteButton && !hide ? "visible" : "hidden"
            }}
        >
            {t('unmuteVideo', "Skru på lyd")}
            <Volume2 />
        </Button>
    )
}