import {Box} from "@mui/material";
import React from "react";

export const EditQuizContainer = ({children}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                position: "relative"
            }}
        >
            {children}
        </Box>
    )
}