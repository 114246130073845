import {useEffect, useState} from "react";
import {GeoJSON} from "ol/format";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {Fill, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {GreatCircle} from 'arc'


const styles = {
    'route': new Style({
        stroke: new Stroke({
            width: 6,
            color: [24, 20, 230, 1],
        }),
    }),
    'startIcon': new Style(),
    'geoMarker': new Style({
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({color: [24, 20, 230, 1]}),
            stroke: new Stroke({
                color: 'white',
                width: 2,
            }),
        }),
    }),
}

export const useFlightArc = ({
                                 map,
                                 counter = 0,
                                 from,
                                 to,
                                edit
                             }) => {
    const [data, setData] = useState({
        position: null,
        route: null,
    })
    useEffect(() => {
        if (map && !edit) {
            if(from && to) {
                const arcGenerator = new GreatCircle(
                    {
                        x: from[0],
                        y: from[1]
                    },
                    {
                        x: to[0],
                        y: to[1]
                    }
                )

                const arcLine = arcGenerator.Arc(
                    1100,
                    {
                        offset: 10
                    }
                )
                const format = new GeoJSON()
                const route = format.readGeometry(
                    arcLine.json().geometry,
                    {
                        dataProjection: "EPSG:4326",
                        featureProjection: "EPSG:3857"
                    }
                )
                const routeFeature = new Feature({
                    type: "route",
                    geometry: route
                })

                const startMarker = new Feature({
                    type: "startIcon",
                    geometry: new Point(route.getFirstCoordinate())
                })

                const position = startMarker.getGeometry().clone()
                const geoMarker = new Feature({
                    type: "geoMarker",
                    geometry: position
                })


                const vectorLayer = new VectorLayer({
                    source: new VectorSource({
                        features: [routeFeature, geoMarker],
                    }),
                    style: function (feature) {
                        return styles[feature.get('type')];
                    },
                });
                map.addLayer(vectorLayer)
                setData({
                    position: position,
                    route: route,
                })

                return () => {
                    map.removeLayer(vectorLayer)
                }
            }
        }
    }, [map, from, to, edit]);


    useEffect(() => {
        const currentCoordinate = data.route?.getCoordinateAt(
            counter,
        );
        if(currentCoordinate) {
            data.position?.setCoordinates(currentCoordinate);
            map?.getView()?.setCenter(currentCoordinate)
        }


    }, [counter]);

}