import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {SectionTypes} from "../helpers/enums";
import {Height} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";

export const HeightButton = ({sectionType, onClick}) => {
    const t = useScrollytellTranslation();
    const handleClick = () => onClick("heightMenu")

    if(sectionType === SectionTypes.CONTENT) {
        return (
            <AdminIconBarItem
                Icon={Height}
                onClick={handleClick}
                tooltip={t("editSectionPanel.heightTooltip", "Høyde")}
            />
        )
    } else {
        return null
    }
}