import {ProgressLock} from "./ProgressLock";
import {ProgressRectangleAfterLock} from "./ProgressRectangleAfterLock";
import {ProgressRectangle} from "./ProgressRectangle";

export const Indicator = ({locked, afterLock, sectionId, title, open, hoveredSectionId, onHover, onClick}) => {
    if(afterLock) {
        return (
            <ProgressRectangleAfterLock/>
        )
    } else if(locked) {
        return (
            <ProgressLock
                sectionId={sectionId}
                expanded={open}
                hoveredSectionId={hoveredSectionId}
                onHover={onHover}
                onClick={onClick}
                title={title}
            />
        )
    } else {
        return (
            <ProgressRectangle
                sectionId={sectionId}
                expanded={open}
                hoveredSectionId={hoveredSectionId}
                onHover={onHover}
                onClick={onClick}
                title={title}
            />
        )
    }
}