import {ZoomOut} from "@mui/icons-material";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";
import React from "react";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const MapZoomOutButton = ({zoom, templateId, onUpdate}) => {
    const t = useScrollytellTranslation()
    const handleUpdate = () => {
        onUpdate(
            "zoom",
            zoom ? zoom - 1 : 8
        )
    }

    if(7 === templateId) {
        return (
            <AdminIconBarItem
                Icon={ZoomOut}
                onClick={handleUpdate}
                disabled={zoom <= 3}
                tooltip={t(
                    "editSectionPanel.mapZoomOut",
                    "Zoom ut"
                )}
                tooltipPosition="top"
            />
        )
    } else {
        return null
    }
}