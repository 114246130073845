import {X} from "lucide-react";
import React from "react";
import {createUseStyles} from "react-jss";
import {AlternativeLetter} from "./AlternativeLetter";
import {AlternativeContainer} from "./AlternativeContainer";

const useStyle = createUseStyles(({ colors }) => ({
    wrongAnswerX: {
        color: colors.carnation,
    }
}));

export const AlternativeReveal = ({alternative, whiteBorder=false, children}) => {
    const classes = useStyle()
    return (
        <AlternativeContainer
            whiteBorder={whiteBorder}
        >
            <AlternativeLetter>
                {alternative.toUpperCase()}
            </AlternativeLetter>
            <X
                className={classes.wrongAnswerX}
            />
            {children}
        </AlternativeContainer>
    );
}