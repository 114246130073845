import {GridSizeSelectors} from "./GridSizeSelectors";
import React, {createRef, useState} from "react";
import {EditContainerPanel} from "./EditContainerPanel";
import {AddBlockPanel} from "./AddBlockPanel";
import {DuplicateContainerButton} from "./DuplicateContainerButton";
import {RemoveContainerButton} from "./RemoveContainerButton";
import {useIsActiveContainer} from "./useIsActiveContainer";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch, useScrollyTellProps} from "../Admin/scrollytellContext";
import {addBlock, updateContainer} from "../Admin/service";
import {SectionTemplates} from "../helpers/enums";

export const EditContainer = ({
    sectionId,
    containerId,
    isAdminMode,
    gridSize,
    container
}) => {
    const gridSizeRef = createRef();
    const [isDragging, setIsDragging] = useState(false);
    const isActiveContainer = useIsActiveContainer({
        sectionId: sectionId,
        containerId: containerId
    })
    const dispatch = useScrollytellDispatch()
    const { onChange, story } = useScrollyTellProps()

    const handleEdit = (property, value) => {
        onChange(
            updateContainer(story, sectionId, containerId, { [property]: value })
        );
    }

    const handleAddBlock = emptyBlock => {
        const updatedStory = addBlock(
            story,
            sectionId,
            containerId,
            emptyBlock
        );
        onChange(updatedStory);
        const blockId = updatedStory.sections[sectionId].containers[containerId].blocks.length - 1;
        dispatch({
            type: SET_ACTIVE_ELEMENT,
            activeSection: sectionId,
            activeContainer: containerId,
            activeBlock: blockId,
        });
    };

    const {
        startColumn = 5,
        endColumn = 6,
    } = container

    const whenNotDragging = () => {
        if (isDragging) {
            return null
        } else {
            return (
                <>
                    <EditContainerPanel
                        borderRadius={container.borderRadius}
                        backgroundColor={container.backgroundColor}
                        sectionId={sectionId}
                        containerId={containerId}
                        onEdit={handleEdit}
                    />
                    <AddBlockPanel
                        onAdd={handleAddBlock}
                        tag={story.sections[sectionId]?.templateId === SectionTemplates.SCROLLVIDEO_SECTION ? "all" : "advanced"}
                    />
                    <DuplicateContainerButton
                        container={container}
                        sectionId={sectionId}
                        containerId={containerId}
                    />
                    <RemoveContainerButton
                        sectionId={sectionId}
                        containerId={containerId}
                    />
                </>
            )
        }
    }

    if (isAdminMode && isActiveContainer) {
        return (
            <>
                <GridSizeSelectors
                    containerId={containerId}
                    sectionId={sectionId}
                    data={{startColumn, endColumn, gridSize}}
                    ref={gridSizeRef}
                    onIsDraggingChange={setIsDragging}
                />
                {whenNotDragging()}
            </>
        )
    } else {
        return null
    }
}