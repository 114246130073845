import {useEffect, useState} from "react";

export const useContentHeight = (elemenetId="scrollytell-content") => {
    const [contentHeight, setContentHeight] = useState("1000")

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setContentHeight(event[0].contentBoxSize[0].blockSize);
        });

        resizeObserver.observe(document.getElementById(elemenetId));
    });

    return contentHeight
}