import React, {useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {ArrowDropDown, ArrowDropUp, FormatSize} from "@mui/icons-material";
import {fontStyleMap, getHasInlineStyleByKey, toggleInlineStyle,} from "./helpers";

const useStyles = createUseStyles(({colors}) => ({
    fontSizeSelector: {
        order: 1,
        border: "none",
        padding: 0,
        margin: 0,
        "& fieldset": {border: "none"},
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.04)",
            borderRadius: "10px",
        },
    },
    selected: {
        display: "flex",
        alignItems: "center",
        height: "40px",
    },
    selectedValue: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 0 0 8px",
        width: "45px",
        alignItems: "center",
    },
    fontSizeList: {
        position: "absolute",
        margin: 0,
        backgroundColor: colors.white,
        boxShadow:
            "rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px",
        listStyle: "none",
        paddingInlineStart: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: "200px",
        overflowY: "scroll",
        width: "fit-content",
    },
    fontSize: {
        padding: "4px 20px",
        "&:hover": {
            backgroundColor: "#f1f1f1",
        },
        "&.selected": {
            backgroundColor: "#b9b9b9",
        },
    },
}));

export const FontSizeSelector = ({onChange, editorState}) => {
    const [expanded, setExpanded] = useState(false);
    const menuRef = useRef();
    const options = Object.entries(fontStyleMap);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [setExpanded]);

    const currentFocus = editorState.getSelection().getFocusKey();
    const currentFontSize = editorState
        .getCurrentInlineStyle(currentFocus)
        .toArray()
        .filter((c) => c.substr(0, 5) === "SIZE_")[0];

    const selectFontSize = (key) => {
        const currentStyles = editorState
            .getCurrentInlineStyle()
            .toArray()
            .filter((c) => c.substr(0, 5) === "SIZE_");
        if (currentStyles && currentStyles[0] !== undefined) {
            const reversedState = toggleInlineStyle(editorState, currentStyles[0]);
            onChange(toggleInlineStyle(reversedState, key));
        } else {
            onChange(toggleInlineStyle(editorState, key));
        }
    };
    const classes = useStyles();

    const labels = {
        'SIZE_XS': 'XS',
        'SIZE_SM': "SM",
        "SIZE_STANDARD": "Standard",
        "SIZE_LG": "LG",
        "SIZE_XL": "XL",
        "SIZE_XL2": "2XL",
        "SIZE_XL3": "3XL",
        "SIZE_XL4": "4XL",
        "SIZE_XL5": "5XL",
        "SIZE_XL6": "6XL",
        "SIZE_XL7": "7XL",
        "SIZE_XL8": "8XL",
        "SIZE_XL9": "9XL",
    }


    return (
        <div className={classes.fontSizeSelector} ref={menuRef}>
            <div className={classes.selected} onClick={() => setExpanded(!expanded)}>
                <FormatSize/>
                <span className={classes.selectedValue}>
                    {currentFontSize?.slice(5)}
                    {expanded ? <ArrowDropUp/> : <ArrowDropDown/>}
                </span>
            </div>
            {expanded && (
                <ul className={classes.fontSizeList}>
                    {options
                        .filter(([key]) => key.startsWith("SIZE_"))
                        .map(([key]) => {
                            const isSelected = getHasInlineStyleByKey(editorState, key);
                            return (
                                <li
                                    className={`${classes.fontSize} ${
                                        isSelected ? "selected" : ""
                                    }`}
                                    key={key}
                                    onClick={() => selectFontSize(key)}
                                >
                                    {labels[key]}
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );
};
