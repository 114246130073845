import React, { useRef } from "react";
import { createUseStyles } from "react-jss";
import { SlowMotionVideoOutlined } from "@mui/icons-material";

import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { Box, Divider, Slider, Typography } from "@mui/material";
import { useScrollytellTranslation } from "../Admin/scrollytellContext";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
  subMenu: {
    position: "absolute",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
});

export const VideoScrollMenu = ({
  playbackConstant,
  onPlaybackChange,
  handleSetVideo,
}) => {
  const t = useScrollytellTranslation();
  const backgroundMenuRef = useRef();
  const classes = useStyles();
  return (
    <AdminIconBar ref={backgroundMenuRef} className={classes.subMenu}>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          sx={{ fontSize: "14px", margin: "0 15px 0 0" }}
        >
          {t("videoScrollBlockPlaybackRateLabel", "Scrollehastighet")}
        </Typography>
        <Slider
          aria-label="Playback rate"
          value={playbackConstant}
          valueLabelDisplay="auto"
          step={100}
          min={0}
          max={1200}
          onChange={onPlaybackChange}
        />
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />
      <AdminIconBarItem
        Icon={SlowMotionVideoOutlined}
        onClick={() => handleSetVideo()}
        tooltip={t("videoScrollBlockSelectVideoTooltip", "Velg video")}
        classname={classes.subMenuItem}
      />
    </AdminIconBar>
  );
};
