import {useTheme} from "react-jss";
import {DialogContent} from "@mui/material";
import {useIsActiveContainer} from "../Container/useIsActiveContainer";
import {SET_ACTIVE_ELEMENT, useScrollytellDispatch} from "../Admin/scrollytellContext";
import {useRef} from "react";


export const ReadMoreSubBlocksContainer = ({isAdminMode,  children}) => {
    const dispatch = useScrollytellDispatch()
    const {colors} = useTheme()
    const dialogRef = useRef()

    const isActiveContainer = useIsActiveContainer({
        sectionId: "subSection",
        containerId: "subContainer",
    })

    const handleClick = (event) => {
        event.stopPropagation()
        if (!isActiveContainer && event.target === dialogRef.current) {
            dispatch({
                type: SET_ACTIVE_ELEMENT,
                activeSection: "subSection",
                activeContainer: 'subContainer',
            });
        }
    }


    return (
        <DialogContent
            sx={{
                minHeight: "150px",
                border: isAdminMode ? `2px ${isActiveContainer ? "solid" : "dashed"} ${colors.adminBlue}` : 0,
                "& :hover": {
                    cursor: "pointer"
                },
                p: "1.5rem"
            }}
            ref={dialogRef}
            onClick={handleClick}
        >
            {children}
        </DialogContent>
    )

}