import {ButtonBase, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import {PopoverColorPicker} from "./PopoverColorPicker";

export const ReadMoreColorField = ({autoFocus=false, label, property, value, onChange, allowAlpha=true}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const handleChange = color => {
        onChange(property, color)
    }

    const handleInputChange = event => {
        onChange(property, event.target.value)
    }

    const handleOpenPicker = event => setAnchorEl(event.currentTarget)

    const handleClosePicker = () => setAnchorEl(null)

    return (
        <Stack
            direction={"row"}
            spacing={1}
        >
            <TextField
                label={label}
                value={value}
                onChange={handleInputChange}
                autoFocus={autoFocus}
            />
            <ButtonBase
                onClick={handleOpenPicker}
                sx={{
                    height: "56px",
                    width: "56px",
                    borderRadius: "8px",
                    backgroundColor: value
                }}
            />
            <PopoverColorPicker
                anchorEl={anchorEl}
                onClose={handleClosePicker}
                onSave={handleChange}
                color={value}
                allowAlpha={allowAlpha}
            />
        </Stack>
    )
}