import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px",
        minHeight: "52px"
    },
    button: {
        border: "none",
        outline: "none",
        display: "inline-flex",
        padding: "11px 24px",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        borderRadius: "999px",
        background: "#3F3F3F",
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "160%",
        letterSpacing: "0.024px",
        '&:hover': {
            cursor: "pointer",
            transform: "scale(1.05) rotate(-1deg);"
        }
    }
})
export const RetryButton = ({show, onClick}) => {
    const classes = useStyles()

    if(show) {
        return (
            <div
                className={classes.buttonContainer}
            >
                <button
                    type={"button"}
                    className={classes.button}
                    onClick={onClick}
                >
                    Prøv igjen
                </button>
            </div>
        )
    } else {
        return (
            <div
                className={classes.buttonContainer}
            >
            </div>
        )
    }
}
