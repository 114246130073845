import React, { forwardRef } from "react";
import { createUseStyles } from "react-jss";
import {
  useScrollytellState,
  useScrollytellTranslation,
} from "../Admin/scrollytellContext";
import { DragButton } from "./DragButton";

const useStyles = createUseStyles(({ colors }) => ({
  gridSizeSelector: {},
  arrowIcon: {
    backgroundColor: colors.adminBlue,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    height: "auto",
    "&:hover": { color: `${colors.white}aa` },
    cursor: "pointer",
  },
  arrowUp: {
    position: "absolute",
    top: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  arrowDown: {
    position: "absolute",
    bottom: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export const GridSizeSelectors = forwardRef(
  ({ sectionId, containerId, data, onIsDraggingChange }, ref) => {
    const { startColumn, endColumn } = data;
    const { showGrid } = useScrollytellState();
    const t = useScrollytellTranslation();
    const classes = useStyles();
    return (
      <div className={classes.gridSizeSelector} ref={ref}>
        <DragButton
          side="start"
          title={t("gridSizeSelector.decreaseWidthRight", "Øk størrelsen")}
          containerId={containerId}
          showGrid={showGrid}
          startColumn={startColumn}
          endColumn={endColumn}
          onIsDraggingChange={onIsDraggingChange}
        />
        <DragButton
          side="end"
          title={t("gridSizeSelector.decreaseWidthRight", "Øk størrelsen")}
          containerId={containerId}
          showGrid={showGrid}
          startColumn={startColumn}
          endColumn={endColumn}
          onIsDraggingChange={onIsDraggingChange}
        />
      </div>
    );
  }
);
