import {useEffect, useRef, useState} from "react";
import {Map, View} from 'ol'
import {XYZ} from "ol/source";
import TileLayer from "ol/layer/Tile";
import 'ol/ol.css';
import {fromLonLat, get, toLonLat} from "ol/proj";
import {useFlightArc} from "./useFlightArc";
import {defaults} from "ol/interaction";
import {useEditLayer} from "./useEditLayer";
import {useDistance} from "./useDistance";

export const OpenLayerMap = ({from, to, zoom=8, progress, onChange, edit=false}) => {
    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [modifyTarget, setModifyTarget] = useState(null)


    useEffect(
        () => {
            map?.getView()?.setZoom(zoom)
        }, [zoom]
    )

    const interactions = useEditLayer({
        map: map,
        edit: edit,
        to: to,
        from: from
    })

    const distance = useDistance({
        map: map,
        from: from,
        to: to
    })

    useFlightArc({
        map: map,
        counter: progress,
        from: from,
        to: to,
        edit: edit
    })

    const handleAdd = event => {
        let newCoord = toLonLat(event.feature.getGeometry().getCoordinates());
        onChange({
            from: from ? from : newCoord,
            to: from ? newCoord : null
        })
    }

    const handleStartModify = event => {
        let coords = []
        event.features.forEach((feature, idx) => coords[idx] = toLonLat(feature.getGeometry().getCoordinates()))
        if(coords[0].every((v, i) => v === from[i])) {
            setModifyTarget('from')
        } else if(coords[0].every((v, i) => v === to[i])) {
            setModifyTarget('to')
        }
    }

    const handleEdit = event => {
        const coords = [];
        event.features.forEach((feature, idx) => coords[idx] = toLonLat(feature.getGeometry().getCoordinates()))
        if(modifyTarget === 'from') {
            onChange({
                from: coords[0],
                to: to
            })
        } else if(modifyTarget === 'to') {
            onChange({
                from: from,
                to: coords[0]
            })
        }
        setModifyTarget(null)
    }


    useEffect(() => {
        const osmLayer = new TileLayer({
            preload: Infinity,
            source: new XYZ({
                url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            })
        })


        const extent = get('EPSG:3857').getExtent().slice();
        extent[0] += extent[0];
        extent[2] += extent[2];

        const initialMap = new Map({
            target: mapRef?.current,
            view: new View({
                center: from ? fromLonLat(from) : fromLonLat([10.466, 61.115]),
                zoom: zoom,
                extent: extent
            }),
            layers: [
                osmLayer
            ],
            interactions: defaults({
                mouseWheelZoom: false,

            })
        })

        setMap(initialMap)

        return () => initialMap.setTarget(null)
    }, []);


    useEffect(() => {
        if(!edit) {
            const view = map?.getView()
            view?.setZoom(zoom - (Math.sin(progress * Math.PI) * (distance > 1000 ? 4 : 2)))
        }

    }, [progress]);

    useEffect(
        () => {
            if(Object.keys(interactions).length > 0) {
                interactions.mouseWheelZoom.setActive(edit)
                interactions.modify.setActive(edit)
                interactions.draw.setActive(edit && (!to || !from))
                interactions.snap.setActive(edit)
            }

            if(edit) {
                interactions.draw?.on('drawend', handleAdd)
                interactions.modify?.on("modifystart", handleStartModify)
                interactions.modify?.on("modifyend", handleEdit)

                return () => {
                    interactions.draw?.un('drawend', handleAdd)
                    interactions.modify?.un("modifystart", handleStartModify)
                    interactions.modify?.un("modifyend", handleEdit)
                }
            }

        }, [edit, from, to, modifyTarget]
    )

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
            }}
            ref={mapRef}
            className="map-container"
        >

        </div>
    )
}