import { convertFromRaw, EditorState, RichUtils } from "draft-js";
import { isJsonString } from "../../helpers/helpers";

const xs = {
  fontSize: "var(--text-xs)"
}

const sm = {
  fontSize: "var(--text-sm)"
}

const standard = {
  fontSize: "var(--text-standard)"
}

const lg = {
  fontSize: "var(--text-lg)"
}

const xl = {
  fontSize: "var(--text-xl)"
}

const xl2 = {
  fontSize: "var(--text-2xl)"
}

const xl3 = {
  fontSize: "var(--text-3xl)",
  lineHeight: 1.2
}

const xl4 = {
  fontSize: "var(--text-4xl)",
  lineHeight: 1.2
}

const xl5 = {
  fontSize: "var(--text-5xl)",
  lineHeight: 1.2
}

const xl6 = {
  fontSize: "var(--text-6xl)",
  lineHeight: 1.2
}

const xl7 = {
  fontSize: "var(--text-7xl)",
  lineHeight: 1.2
}

const xl8 = {
  fontSize: "var(--text-8xl)",
  lineHeight: 1.2
}

const xl9 = {
  fontSize: "var(--text-9xl)",
  lineHeight: 1.2
}

export const fontStyleMap = {
  FONT_SIZE_8: xs,
  FONT_SIZE_9: xs,
  FONT_SIZE_10: xs,
  FONT_SIZE_11: xs,
  FONT_SIZE_12: xs,
  FONT_SIZE_14: sm,
  FONT_SIZE_16: standard,
  FONT_SIZE_18: lg,
  FONT_SIZE_20: xl,
  FONT_SIZE_24: xl2,
  FONT_SIZE_30: xl3,
  FONT_SIZE_36: xl4,
  FONT_SIZE_48: xl5,
  FONT_SIZE_60: xl6,
  FONT_SIZE_72: xl7,
  FONT_SIZE_92: xl8,
  SIZE_XS: xs,
  SIZE_SM: sm,
  SIZE_STANDARD: standard,
  SIZE_LG: lg,
  SIZE_XL: xl,
  SIZE_XL2: xl2,
  SIZE_XL3: xl3,
  SIZE_XL4: xl4,
  SIZE_XL5: xl5,
  SIZE_XL6: xl6,
  SIZE_XL7: xl7,
  SIZE_XL8: xl8,
  SIZE_XL9: xl9
};

export const toggleInlineStyle = (editorState, type) => {
  const newState = RichUtils.toggleInlineStyle(editorState, type);
  const currentSelection = editorState.getSelection();
  const stateWithContentAndSelection = EditorState.forceSelection(
    newState,
    currentSelection
  );
  if (newState) {
    return stateWithContentAndSelection;
  }
};

export const getHasInlineStyleByKey = (editorState, key) =>
  editorState.getCurrentInlineStyle().has(key);

const fixOldFontSize = val => {
  /**
   * The title section template contained wrong font syntax. This function replaces that with correct syntax
   */
  return val.replace('font-size-72', 'SIZE_XL7')
      .replace('fontsize-72', 'SIZE_XL7')
      .replace('fontsize-36', 'SIZE_XL4')
}

export const convertRawToEditorState = (value) => {
  if (value) {
    const val = fixOldFontSize(value)
    if (isJsonString(val)) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(val)));
    } else {
      console.warn("Initial data is not a valid editor state");
      return EditorState.createEmpty();
    }
  } else {
    return EditorState.createEmpty();
  }
};
