import {useIsActiveBlock} from "../Block/useIsActiveBlock";
import React, {useState} from "react";
import {CodewordDialog} from "./CodewordDialog";
import {CodewordBlock} from "./CodewordBlock";
import {updateBlock} from "../Admin/service";
import {useScrollyTellProps} from "../Admin/scrollytellContext";
import {AdminActions} from "../Block/AdminActions";

export const EditCodewordBlock = ({sectionId, containerId, blockId, block, children}) => {
    const [showDialog, setShowDialog] = useState(false)
    const {onChange, story} = useScrollyTellProps()
    const isActiveBlock = useIsActiveBlock({
        sectionId: sectionId,
        blockId: blockId,
        containerId: containerId
    })


    const handleCloseDialog = newSecretValue => {
        const updatedBlock = updateBlock(
            story,
            sectionId,
            containerId,
            blockId,
            {
                ...block,
                secret: newSecretValue,
                locked: newSecretValue !== "",
            }
        )
        onChange(updatedBlock)
        setShowDialog(false)

    }

    const handleShowDialog = () => setShowDialog(true)

    return (
        <>
            <CodewordBlock
                secret={block.secret}
                placeholder={block.text}
                onClick={handleShowDialog}
                blockId={blockId}
                containerId={containerId}
                sectionId={sectionId}
            />
            <AdminActions
                show={isActiveBlock}
            >
                {children}
            </AdminActions>
            <CodewordDialog
                open={showDialog}
                onClose={handleCloseDialog}
            />
        </>
    )

}