import React, {useState} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ colors }) => ({
    sectionOption: {
        display: "flex",
        width: "288px",
        flexDirection: "column",
        margin: "26px",
        cursor: "pointer",
    },
    sectionPreview: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        border: "1px solid #757575",
        "&:hover": {
            border: `1px solid ${colors.adminBlue}`,
        },
    },
    sectionTitle: { marginTop: "9px", fontSize: "11px", fontFamily: "Work Sans" },
}))

export const SectionTemplate = ({ template: { title, preview }, onClick }) => {
    const classes = useStyles()
    const [loaded, setLoaded] = useState(false)
    const [paused, setPaused] = useState(false)

    return (
        <div className={classes.sectionOption} onClick={onClick}>
            <video
                className={classes.sectionPreview}
                src={`https://ems.dimu.org/file/${preview}`}
                muted={true}
                autoPlay={false}
                loop={true}
                onMouseEnter={(e) => {
                    e.target.defaultPlaybackRate = 3.0;
                    e.target.play()
                        .then(() => {
                            if(!loaded) {
                                if(paused) {
                                    e.target.pause()
                                }
                                setLoaded(true)
                            } else {
                                setPaused(false)
                            }

                        })
                }}
                onMouseLeave={(e) => {
                    setPaused(true)
                    if(loaded) {
                        e.target.pause()
                    }
                }}
            />
            <span className={classes.sectionTitle}>{title}</span>
        </div>
    );
};