import React, {useState} from "react";
import {useScrollyTellProps,} from "../Admin/scrollytellContext";
import {ButtonBase, Dialog, DialogContent, DialogTitle, InputAdornment, Stack, TextField} from "@mui/material";
import {LockOpen, LockOutlined} from "@mui/icons-material";
import useDeepCompareEffect from "use-deep-compare-effect";


export const EditLockBlock = ({isAdminMode, lock, sectionId, children}) => {
    const [editing, setEditing] = useState(false)
    const [lockState, setLockState] = useState(lock)
    const { onChange, story } = useScrollyTellProps();

    useDeepCompareEffect(() => {
        setLockState(lock)
    }, [lock])


    const handleClick = () => setEditing(true)

    const handleClose = () => {
        let sections = story.sections
        sections[sectionId].lock = lockState
        onChange({
            ...story,
            sections: sections
        })
        setEditing(false)
    }

    const handleChangeLockedLabel = event => {
        setLockState(previous => ({
            ...previous,
            lockedLabel: event.target.value
        }))
    }

    const handleChangeUnlockedLabel = event => {
        setLockState(previous => ({
            ...previous,
            unlockedLabel: event.target.value
        }))
    }

    if(isAdminMode) {
        return (
            <>
                <ButtonBase
                    onClick={handleClick}
                >
                    {children}

                </ButtonBase>
                <Dialog
                    open={editing}
                    onClose={handleClose}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>
                        Rediger låstekster
                    </DialogTitle>
                    <DialogContent>
                        <Stack
                            spacing={2}
                            sx={{
                                pt: 2,
                                width: "100%"
                            }}
                        >
                            <TextField
                                label={"Beskjed til leser når låst"}
                                value={lockState.lockedLabel}
                                onChange={handleChangeLockedLabel}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label={"Beskjed til leser når låst opp"}
                                value={lockState.unlockedLabel}
                                onChange={handleChangeUnlockedLabel}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOpen />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                    </DialogContent>
                </Dialog>
            </>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}
