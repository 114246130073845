import {useScrollytellTranslation} from "../Admin/scrollytellContext";
import {BlockTypes} from "../helpers/enums";
import {
    MoreHoriz,
    Password,
    PhotoLibrary,
    Quiz,
    SlowMotionVideo,
    TextFields,
    ThreeDRotation,
    VideoLibrary
} from "@mui/icons-material";

export const useBlockOptions = () => {
    const t = useScrollytellTranslation()

    return [
        {
            type: BlockTypes.TEXT,
            Icon: <TextFields />,
            tooltip: t("addBlockPanel.addTextBlockTooltip", "Legg til tekst"),
            tags: ["basic", "advanced", "all"]
        },
        {
            type: BlockTypes.IMAGE,
            Icon: <PhotoLibrary />,
            tooltip: t("addBlockPanel.addImageBlockTooltip", "Legg til bilde"),
            tags: ["basic", "advanced", "all"]
        },
        {
            type: BlockTypes.VIDEO,
            Icon: <VideoLibrary />,
            tooltip: t("addBlockPanel.addVideoBlockTooltip","Legg til video"),
            tags: ["basic", "advanced", "all"]
        },
        {
            type: BlockTypes.VIDEO_SCROLL,
            Icon: <SlowMotionVideo />,
            tooltip: t("addBlockPanel.addVideoScrollBlockTooltip", "Legg til scrolle video"),
            tags: ["all"]
        },
        {
            type: BlockTypes.QUIZ,
            Icon: <Quiz />,
            tooltip: t("addBlockPanel.addQuizBlockTooltip", "Legg til quiz"),
            tags: ["advanced", "all"]
        },
        {
            type: BlockTypes.THREE_D,
            Icon: <ThreeDRotation />,
            tooltip: t("addBlockPanel.add3dBlockTooltip", "Legg til 3D modell"),
            tags: ["advanced", "all"]
        },
        {
            type: BlockTypes.CODEWORD,
            Icon: <Password />,
            tooltip: t("addBlockPanel.addCodewordBlockTooltip", "Legg til kodeord"),
            tags: ["advanced", "all"]
        },
        {
            type: BlockTypes.READ_MORE,
            Icon: <MoreHoriz />,
            tooltip: t("addBlockPanel.addReadMoreBlockTooltip", "«Vis mer» knapp"),
            tags: ["advanced", "all"]
        }
    ]
}