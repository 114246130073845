import React, { useEffect } from "react";
import Vivus, { EASE } from "vivus";
import { createUseStyles } from "react-jss";
import { LogoGray } from "./LogoGray";

const useStyles = createUseStyles({
    loadingLogo: {
        width: 80,
        height: "86.73px",
        position: "relative",

        "& #my-svg": {
            position: "absolute",
            zIndex: 1000,
            width: "100%",
            height: "initial",
        },
        "& .invisible": {
            opacity: 0,
            animation: "$fadingIn ease-in .5s alternate",
            animationFillMode: "forwards",
            animationDuration: ".5s",
        },
        "& .invisible.two": {
            animationDelay: "1.8s",
        },
        "& .invisible.three": {
            animationDelay: "2.3s",
        },
    },
    "@keyframes fadingIn": {
        from: {
            opacity: 0,
        },
        to: { opacity: 1 },
    },
    "@keyframes fadingOut": {
        from: {
            opacity: 1,
        },
        to: { opacity: 0 },
    },
});

export const LoadingLogo = ({ className }) => {
    const classes = useStyles();

    useEffect(() => {
        new Vivus(
            "loading-logo",
            {
                duration: 160,
                file: "https://ems.dimu.org/image/019EBvjR3PiXP?mediaType=image/svg%2Bxml",
                animTimingFunction: EASE,
                type: "oneByOne",
            },
            (animation) => {
                animation.play(animation.getStatus() === "end" ? -1 : 1);
            }
        );
    }, []);

    return (
        <>
            <div
                id="loading-logo"
                className={`${classes.loadingLogo} ${className}`}
            >
                <LogoGray />
            </div>
        </>
    );
};
