import {Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import React, {useState} from "react";
import {useScrollytellTranslation} from "./scrollytellContext";

export const EditSectionTitle = ({value, onChange}) => {
    const t = useScrollytellTranslation()
    const [open, setOpen] = useState(false)
    const [newValue, setNewValue] = useState(value)

    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false)
        onChange(newValue)
    }

    const handleChange = event => setNewValue(event.target.value)

    return (
        <>
            <Tooltip title={t("editTitleSectionTooltip", "Seksjonstittel")}>
                <IconButton onClick={handleOpen}>
                    <Edit
                        sx={{
                            color: "white"
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>
                    {t("editTitleSectionTitle", "Rediger seksjonstittel")}
                </DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={2}
                        sx={{
                            pt: 2,
                            width: "100%"
                        }}
                    >
                        <TextField
                            label={t('editTitleSectionInputLabel', 'Seksjonstittel')}
                            value={newValue || ""}
                            onChange={handleChange}
                        />
                    </Stack>
                </DialogContent>

            </Dialog>
        </>
    )
}