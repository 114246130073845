import React from "react";
import {createUseStyles} from "react-jss";
import {useScrollyTellProps} from "./scrollytellContext";
import {addSection} from "./service";
import {sectionTemplates} from "./sectionTemplates";
import {SectionTemplate} from "./SectionTemplate";

const useStyles = createUseStyles({
  sectionDialog: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "1054px",
    margin: "0 auto",
  },
})

export const SectionTemplatePicker = ({ onClose }) => {
  const classes = useStyles();
  const { story, onChange } = useScrollyTellProps();

  const addTemplate = (templateId) => {
    const template = sectionTemplates.find((t) => t.templateId === templateId);
    if (template) {
      onChange(
        addSection(story, {
          ...template.section,
          order: story.sections.length + 1,
        })
      );
      onClose();
    } else {
      console.warn("Unable to find template");
    }
  };

  return (
    <div className={classes.sectionDialog}>
      {sectionTemplates.map((template) => (
        <SectionTemplate
          key={`template-${template.templateId}`}
          template={template}
          onClick={() => addTemplate(template.templateId)}
        />
      ))}
    </div>
  );
};

