import React from "react";
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles(({ colors }) => ({
    alternativeLetter: {
        fontWeight: "bold",
        marginRight: "12px",
    }
}));

export const AlternativeLetter = ({children}) => {
    const classes = useStyle()
    return (
        <span className={classes.alternativeLetter}>
            {children}
        </span>
    )
}