import React, { useRef } from "react";
import { createUseStyles } from "react-jss";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../helpers/enums";
import { IconButton, Tooltip } from "@mui/material";
import { ContentCopy, Delete, DragHandle, Edit } from "@mui/icons-material";
import {
  SET_ACTIVE_ELEMENT,
  useScrollytellDispatch,
  useScrollyTellProps,
  useScrollytellTranslation,
} from "./scrollytellContext";
import {addSection, deleteSection, updateSection, updateSectionOrder} from "./service";
import { getTemplateTitleById } from "./sectionTemplates";
import { Section } from "../Section/Section";
import {EditSectionTitle} from "./EditSectionTitle";
import {ToggleNavVisibility} from "./ToggleNavVisbility";

const useStyles = createUseStyles(({ colors }) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto 18px auto",
    opacity: ({ isDragging }) => (isDragging ? 0.5 : 1),
  },
  sectionData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "208px",
  },
  sectionInfo: {},
  sectionSettings: {
    display: "flex",
  },
  handle: {
    cursor: "move",
    color: colors.white,
  },
  delete: {
    color: colors.white,
  },
  copy: {
    color: colors.white,
  },
  preview: {
    height: "103px",
    width: "208px",
    display: "flex",
    overflow: "hidden",
    border: "2px solid transparent",
    "&.active": {
      border: "2px solid #4484F1",
    },
    "&:hover": {
      border: "2px solid #4484F1",
    },
    "& #preview-3d-modelviewer-container canvas": {
      width: "max-content !important",
      height: "max-content !important",
      minWidth: "400px",
      minHeight: "30px",
    },
    "& *": {
      pointerEvents: "none",
    },
  },
  typeWrapper: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.black}`,
    padding: "2px 5px",
  },
}));

export const MiniSection = ({
  index,
  section,
  currentSection,
  totalSections,
}) => {
  const t = useScrollytellTranslation();
  const { onChange, story } = useScrollyTellProps();
  const dispatch = useScrollytellDispatch();
  const ref = useRef();
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.SECTION,
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onChange(updateSectionOrder(story, dragIndex, hoverIndex));
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.SECTION,
    item: () => {
      return { order: section.order, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const classes = useStyles({ isDragging });
  drag(drop(ref));

  const onDeleteSection = () => {
    onChange(deleteSection(story, index));
  };

  const onEditSectionTitle = newTitle => {
    onChange(
        updateSection(
            story,
            index,
            {
              ...section,
              title: newTitle
            }
        )
    )
  }

  const onEditSectionVisibility = hide => {
    onChange(
        updateSection(
            story,
            index,
            {
              ...section,
              excludeFromNav: hide
            }
        )
    )
  }

  const copySection = () => {
    const updatedStory = addSection(story, {
      ...section,
      order: totalSections + 1,
    });
    onChange(updateSectionOrder(updatedStory, totalSections, index));
  };

  const scrollTo = (id) => {
    const content = document.getElementById("scrollytell-content");
    if (content) {
      dispatch({
        type: SET_ACTIVE_ELEMENT,
        activeSection: id,
        activeContainer: undefined,
        activeBlock: undefined,
      });
      document.getElementById(`section-${id + 1}`).scrollIntoView();
    }
  };

  return (
    <div className={classes.section} ref={preview} data-handler-id={handlerId}>
      <Tooltip title={getTemplateTitleById(section.templateId)}>
        <div
          className={`${classes.preview} ${
            currentSection === section.order ? "active" : ""
          }`}
          ref={parentRef}
          onClick={() => scrollTo(index)}
        >
          <Section
            isAdminMode={false}
            section={section}
            sectionId={index}
            ref={childRef}
            isThumbnail={true}
            style={{
              transform: "scale(0.297143)",
              transformOrigin: "left top",
              minHeight: "346px",
              width: "700px",
            }}
          />
        </div>
      </Tooltip>
      <div className={classes.sectionData}>
        <div className={classes.sectionInfo}>{index + 1}.</div>
        <div className={classes.sectionSettings}>
          <EditSectionTitle
              value={section.title}
              onChange={onEditSectionTitle}
          />
          <ToggleNavVisibility
              value={section.excludeFromNav}
              onToggle={onEditSectionVisibility}
          />
          <Tooltip title={t("deleteSectionTooltip", "Slett seksjon")}>
            <IconButton onClick={onDeleteSection}>
              <Delete className={classes.delete} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("copySectionTooltip", "Kopier seksjon")}>
            <IconButton onClick={copySection}>
              <ContentCopy className={classes.copy} />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={t("reorderSectionTooltip", "Dra for å endre rekkefølge")}
          >
            <IconButton ref={ref}>
              <DragHandle className={classes.handle} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
