import {BlockTypes} from "../helpers/enums";
import {TextBlock} from "../TextBlock/TextBlock";
import React from "react";
import {ImageBlock} from "../ImageBlock/ImageBlock";
import {VideoBlock} from "../VideoBlock/VideoBlock";
import {VideoScrollBlock} from "../VideoScrollBlock/VideoScrollBlock";
import {QuizBlock} from "../QuizBlock/QuizBlock";
import {ThreeDBlock} from "../ThreeDBlock/ThreeDBlock";
import {CodewordBlock} from "../codeword/CodewordBlock";
import {BlockProvider} from "../read-more/blockContext";
import {ReadMoreBlock} from "../read-more/ReadMoreBlock";
import {ReadMoreContent} from "../read-more/ReadMoreContent";

export const Block = ({block, blockId, sectionId, containerId}) => {
    const blockByType = {
        [BlockTypes.TEXT]: (
            <TextBlock
                key={`textBlock-${blockId}`}
                block={block}
            />
        ),
        [BlockTypes.IMAGE]: (
            <ImageBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                block={block}
            />
        ),
        [BlockTypes.VIDEO]: (
            <VideoBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                block={block}
            />
        ),
        [BlockTypes.VIDEO_SCROLL]: (
            <VideoScrollBlock
                sectionId={sectionId}
                block={block}
            />
        ),
        [BlockTypes.QUIZ]: (
            <QuizBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                block={block}
            />
        ),
        [BlockTypes.THREE_D]: (
            <ThreeDBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                block={block}
            />
        ),
        [BlockTypes.CODEWORD]: (
            <CodewordBlock
                sectionId={sectionId}
                containerId={containerId}
                blockId={blockId}
                secret={block.secret}
                placeholder={block.text}
            />
        ),
        [BlockTypes.READ_MORE]: (
            (
                <BlockProvider>
                    <ReadMoreBlock
                        blockState={block}
                    />
                    <ReadMoreContent
                        blockState={block}
                    />
                </BlockProvider>
            )
        )
    }

    return blockByType[block.type]
}