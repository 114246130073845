import {Button, IconButton, Tooltip} from "@mui/material";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({colors}) => ({
    iconButton: {
        "&:hover": {},
    },
    text: {
        color: colors.black,
    },
}));

export const AdminIconBarItem = ({
                                     onClick,
                                     Icon,
                                     text,
                                     Custom=null,
                                     tooltip = "",
                                     tooltipPosition = "top",
                                     disabled = false,
                                     classname = "",
                                 }) => {
    const classes = useStyles();

    const withToolTip = (content) => (
        <Tooltip title={tooltip} placement={tooltipPosition}>
            {content}
        </Tooltip>
    );
    const content = text ? (
        <Button
            className={`${classes.iconButton} ${classname}`}
            onClick={onClick}
            disabled={disabled}
            sx={{justifyContent: "flex-start", textTransform: "capitalize"}}
        >
            {Icon && <Icon sx={{fill: "white"}} size="small"/>}
            {text && <span className={classes.text}>{text}</span>}
            {Custom}
        </Button>
    ) : (
        <IconButton
            className={`${classes.iconButton} ${classname}`}
            onClick={onClick}
            disabled={disabled}
        >
            {Icon && <Icon sx={{fill: "white"}} size="small"/>}
            {Custom}
        </IconButton>
    );

    return disabled ? content : withToolTip(content);
};
