import {ContentCopy} from "@mui/icons-material";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ colors }) => ({
  copyButton: {
    backgroundColor: colors.adminBlue,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    width: "24px",
    height: "24px",
    "&:hover": { color: `${colors.white}aa` },
    cursor: "pointer",
    position: "absolute",
    bottom: "-12px",
    right: "30px",
    zIndex: 10,
  },
}));

export const CopyBlockButton = ({block, onCopy}) => {
  const classes = useStyles();

  const handleCopy = () => onCopy(block)

  return (
    <div className={classes.copyButton}>
      <ContentCopy
        onClick={handleCopy}
        sx={{
          width: "14px",
        }}
      />
    </div>
  );
};
