import {Box, FormControl, FormControlLabel, Radio, TextField} from "@mui/material";
import React from "react";
import {EditAlternative} from "./EditAlternative";
import {EditAlternativeCorrectAnswer} from "./EditAlternativeCorrectAnswer";


const alternativesLabel = {
    a: "Alternative A",
    b: "Alternative B",
    c: "Alternative C",
}


export const EditAlternatives = ({sectionId, containerId, blockId, alternatives, answer, updateAlternative, updateProperty, contrastApproved}) => {
    return (
        <Box
            component="form"
            sx={{
                "& .MuiTextField-root": {
                    m: 1,
                    width: "25ch"
                },
            }}
        >
            {alternatives.map(alternative => (
                <EditAlternative
                    key={alternative.alternative}
                    label={alternativesLabel[alternative.alternative]}
                    value={alternative.text || ""}
                    onChange={updateAlternative}
                    alternativeLetter={alternative.alternative}
                    contrastApproved={contrastApproved}
                >
                    <EditAlternativeCorrectAnswer
                        checked={answer === alternative.alternative}
                        onChange={updateProperty}
                        value={alternative.alternative}
                        namePostfix={`${sectionId}-${containerId}-${blockId}`}
                        contrastApproved={contrastApproved}
                    />
                </EditAlternative>
            ))}
        </Box>
    )
}