import {Button} from "@mui/material";
import {Close} from "@mui/icons-material";
import {theme} from "@ekultur/vm";
import {useScrollytellTranslation} from "../Admin/scrollytellContext";

export const ReadMoreCloseButton = ({onClick}) => {
    const t = useScrollytellTranslation()
    return (
        <Button
            startIcon={<Close />}
            variant={"contained"}
            onClick={onClick}
            sx={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                border: "none",
                outline: "none",
                borderRadius: "999px",
                backgroundColor: "#FFFFFF",
                color: theme.colors.black,
                '&:hover': {
                    cursor: "pointer",
                    filter: "brightness(110%)",
                    backgroundColor: "#FFFFFF"
                },
                padding: "12px 20px 12px 16px",
                gap: "4px",
                zIndex: 1301
            }}
        >
            {t('close', 'Lukk')}
        </Button>
    )
}