import React, {forwardRef} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ colors }) => ({
  adminIconBar: {},
}))

export const AdminIconBar = forwardRef(({ className="", children }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={`${classes.adminIconBar} ${className}`}>
      {children}
    </div>
  );
});

