import React, {useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";
import {convertToRaw} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {FormatAlignCenter, FormatAlignLeft, FormatAlignRight,} from "@mui/icons-material";
import {InlineOptions} from "./InlineOptions";
import {RichTextColorPicker} from "./RichTextColorPicker";
import {useScrollyTellProps, useScrollytellTranslation} from "../../Admin/scrollytellContext";
import {convertRawToEditorState, fontStyleMap} from "./helpers";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {FontSizeSelector} from "./FontSizeSelector";

const useStyles = createUseStyles(({colors}) => ({
    richTextEditor: {
        "--text-xs": "10px",
        "--text-sm": "12px",
        "--text-standard": "16px",
        "--text-lg": "18px",
        "--text-xl": "20px",
        "--text-2xl": "24px",
        "--text-3xl": "28px",
        "--text-4xl": "30px",
        "--text-5xl": "32px",
        "--text-6xl": "38px",
        "--text-7xl": "48px",
        "--text-8xl": "64px",
        "--text-9xl": "80px",
        "@media (min-width: 768px)": {
            "--text-xs": "12px",
            "--text-sm": "14px",
            "--text-lg": "20px",
            "--text-xl": "22px",
            "--text-4xl": "32px",
            "--text-5xl": "38px",
            "--text-6xl": "44px",
            "--text-7xl": "56px",
            "--text-8xl": "72px",
            "--text-9xl": "96px",
        },
        "@media (min-width: 1280px)": {
            "--text-3xl": "30px",
            "--text-4xl": "36px",
            "--text-5xl": "40px",
            "--text-6xl": "56px",
            "--text-7xl": "64px",
            "--text-8xl": "80px",
            "--text-9xl": "112px",
        },
        "@media (min-width: 1536px)": {
            "--text-4xl": "38px",
            "--text-5xl": "48px",
            "--text-6xl": "60px",
            "--text-7xl": "72px",
            "--text-8xl": "96px",
            "--text-9xl": "128px",
        },
        fontSize: "var(--text-standard)",
        overflow: "hidden",
        "& .DraftEditor-root": {
            "& .DraftEditor-editorContainer": {
                display: ({isEmpty}) => (isEmpty ? "none" : "block"),
            },
            "& .public-DraftEditorPlaceholder-root": {
                position: "relative",
                display: ({isEmpty}) => (isEmpty ? "block" : "none"),
                "&.public-DraftEditorPlaceholder-hasFocus": {
                    display: "none",
                },
                "&.public-DraftEditorPlaceholder-hasFocus + .DraftEditor-editorContainer":
                    {
                        display: "block",
                    },
            },
            "& .public-DraftEditorPlaceholder-inner": {
                opacity: 0.7,
                color: "black",
            },
        },
        "& .public-DraftEditor-content": {
            wordBreak: "break-word",
            "& .public-DraftStyleDefault-block": {margin: 0},
            margin: "0 auto",
        },
        "& .public-DraftStyleDefault-ltr": {
            textAlign: "inherit",
        },
        fontFamily: ({defaultFont}) => defaultFont,
    },
    wrapper: {
        margin: 0,
        padding: 0,
        position: "relative",
    },
    toolBar: {
        margin: "0 !important",
        padding: "8px !important",
        position: ({toolbarSticky}) => (toolbarSticky ? "sticky" : "absolute"),
        top: ({toolbarSticky}) => (toolbarSticky ? "0" : "-66px"),
        left: ({toolbarSticky}) => (toolbarSticky ? "0" : "-3px"),
        borderRadius: "4px",
        color: "#00000099 !important",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        width: "max-content",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
        zIndex: 10,
        "& *": {
            color: "#00000099 !important",
        },

        "& .rdw-text-align-wrapper": {
            margin: 0,
        },
        "& .rdw-inline-wrapper": {
            margin: 0,
        },
        "& .rdw-fontfamily-wrapper": {
            order: 3,
            marginBottom: 0,
        },

        "& .rdw-remove-wrapper": {
            marginBottom: 0,
            order: 6,
        },
        "& .rdw-dropdown-wrapper": {
            margin: 0,
            border: "none",
            height: "40px",
            "& .rdw-fontfamily-placeholder": {
                maxWidth: "80px",
            },
            "& .rdw-dropdown-carettoopen": {
                top: "50%",
                transform: "translateY(-50%)",
                borderTop: "6px solid rgba(0,0,0,0.6)",
            },
            "& .rdw-dropdown-carettoclose": {
                top: "50%",
                transform: "translateY(-50%)",
                borderBottom: "6px solid rgba(0,0,0,0.6)",
            },
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.04)",
                borderRadius: "10px",
                boxShadow: "none",
            },
        },
        "& .rdw-option-wrapper": {
            border: "none",
            padding: "10px",
            margin: 0,
            minWidth: "20px",
            borderRadius: "50%",
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.04)",
                boxShadow: "none",
            },
            "& img": {
                height: "18px",
            },
        },
        "& .rdw-option-active": {
            backgroundColor: `${colors.black}33`,
            boxShadow: "none",
        },
    },
    textAlign: {
        order: 2,
    },
}));

export const RichTextField = ({
                                  value,
                                  showToolbar = false,
                                  onChange,
                                  placeholder,
                                  className = "",
                                  readOnly = false,
                              }) => {
    const t = useScrollytellTranslation();
    const editorRef = useRef(null);
    const [editorState, setEditorState] = useState(
        convertRawToEditorState(value)
    );
    const {story} = useScrollyTellProps()
    const [toolbarSticky, setToolbarSticky] = useState(false);
    const classes = useStyles({
        placeholder,
        toolbarSticky,
        isEmpty: !editorState.getCurrentContent().hasText(),
        defaultFont: story?.settings?.defaultFont || "Work Sans"
    });

    useEffect(() => {
        setEditorState(convertRawToEditorState(value));
    }, [value]);

    useEffect(() => {
        if (editorRef.current) {
            const contentHeight =
                document.getElementById("scrollytell-content").offsetHeight - 300;
            const editorHeight = editorRef.current.wrapper.offsetHeight;
            if (editorHeight >= contentHeight) {
                setToolbarSticky(true);
            } else {
                setToolbarSticky(false);
            }
        }
    }, [editorState]);

    const getToolbarByType = () => ({
        options: ["textAlign", "fontFamily", "colorPicker", "remove"],
        textAlign: {
            options: ["left", "center", "right"],
            className: classes.textAlign,
            left: {
                component: <FormatAlignLeft sx={{color: "#00000099"}}/>,
            },
            center: {
                component: <FormatAlignCenter sx={{color: "#00000099"}}/>,
            },
            right: {
                component: <FormatAlignRight sx={{color: "#00000099"}}/>,
            },
        },
        fontFamily: {
            dropdownClassName: classes.fontFamily,
            className: classes.fontFamily,
            options: story.settings.availableFonts.sort((a, b) => a.localeCompare(b)),
        },
        colorPicker: {
            component: RichTextColorPicker,
            colors: {
                colors: [
                    {hex: "#000000", label: t("richTextEditor.colorBlack", "Svart")},
                    {hex: "#ffffff", label: t("richTextEditor.colorWhite", "Hvit")},
                ],
                bgColors: [
                    {
                        hex: "transparent",
                        label: t("richTextEditor.bgcolorTransparent", "Gjennomsiktig"),
                    },
                    {hex: "#000000", label: t("richTextEditor.bgcolorBlack", "Svart")},
                    {hex: "#ffffff", label: t("richTextEditor.bgcolorWhite", "Hvit")},
                ],
            },
        },
        remove: {className: classes.removeButton},
    });

    const customToolbarButton = [<InlineOptions/>, <FontSizeSelector/>];

    const handleOnChange = (editorState) => {
        const contentState = editorState.getCurrentContent();
        setEditorState(editorState);
        onChange(convertToRaw(contentState));
    };

    return (
        <Editor
            ref={editorRef}
            placeholder={placeholder}
            toolbarHidden={!showToolbar}
            readOnly={readOnly}
            editorState={editorState}
            toolbarClassName={classes.toolBar}
            wrapperClassName={classes.wrapper}
            editorClassName={`${classes.richTextEditor} ${className}`}
            onEditorStateChange={handleOnChange}
            toolbar={getToolbarByType()}
            toolbarCustomButtons={customToolbarButton}
            stripPastedStyles
            customStyleMap={fontStyleMap}
        />
    );
};
