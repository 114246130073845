import React from "react";
import {RichTextField} from "../common/richTextField/RichTextField";


export const EditTextBlock = ({isActiveBlock, text, placeholder, onUpdate, children}) => {

    const handleUpdate = rawText => {
        onUpdate("text", JSON.stringify(rawText))
    }

    return (
        <>
            <RichTextField
                showToolbar={isActiveBlock}
                value={text}
                onChange={handleUpdate}
                placeholder={placeholder || "Skriv din tekst her..."}
            />
            {children}
        </>
    );
};
