import React, { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { MoreVert, ThreeDRotation } from "@mui/icons-material";

import { AdminIconBar } from "../icon-bar/AdminIconBar";
import { Divider } from "@mui/material";
import { useScrollytellTranslation } from "../Admin/scrollytellContext";
import { OptionsMenu } from "./OptionsMenu";
import { ModelMenu } from "./ModelMenu";
import {AdminIconBarItem} from "../icon-bar/AdminIconBarItem";

const useStyles = createUseStyles({
  subMenu: {
    position: "absolute",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
    background: "#FFFFFF",
    width: "max-content",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    top: "-61px",
    left: "-2px",
    zIndex: "10",
  },
  subMenuItem: {
    "& svg": {
      fill: "#00000099",
    },

    "&:hover svg": {
      fill: "#3F51B5",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& svg": {
        fill: "#00000033",
      },
    },
  },
});

export const ThreeDMenu = ({
  block,
  onUpdateProperty,
  handleSetThreeDModel,
}) => {
  const t = useScrollytellTranslation();
  const threeDMenuRef = useRef();
  const [showModelMenu, setShowModelMenu] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const classes = useStyles();
  return (
    <>
      <AdminIconBar ref={threeDMenuRef} className={classes.subMenu}>
        <AdminIconBarItem
          Icon={MoreVert}
          onClick={() => setShowModelMenu(true)}
          tooltip={t(
            "threeDBlockModelOptionsTooltip",
            "Sett egenskaper for modell"
          )}
          classname={classes.subMenuItem}
          text={t("threeDBlockModelOptionsLabel", "Modell")}
        />
        <AdminIconBarItem
          Icon={MoreVert}
          onClick={() => setShowOptionsMenu(true)}
          tooltip={t(
            "threeDBlockThreeDOptionsTooltip",
            "Instillinger for 3D visning"
          )}
          classname={classes.subMenuItem}
          text={t("threeDBlockThreeDOptionsLabel", "Instillinger")}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <AdminIconBarItem
          Icon={ThreeDRotation}
          onClick={() => handleSetThreeDModel()}
          tooltip={t("threeDBlockShowSetModelTooltip", "Velg 3D modell")}
          classname={classes.subMenuItem}
        />
      </AdminIconBar>
      {showModelMenu && (
        <ModelMenu
          scene={block?.scene}
          onChange={(scene) => onUpdateProperty("scene", scene)}
          onClose={() => setShowModelMenu(false)}
        />
      )}
      {showOptionsMenu && (
        <OptionsMenu
          block={block}
          onChange={onUpdateProperty}
          onClose={() => setShowOptionsMenu(false)}
        />
      )}
    </>
  );
};
