import {useScrollytellTranslation} from "./scrollytellContext";
import {IconButton, Tooltip} from "@mui/material";
import {BookmarkAdded, BookmarkRemove} from "@mui/icons-material";

export const ToggleNavVisibility = ({value, onToggle}) => {
    const t = useScrollytellTranslation()

    const tooltip = value ? t("hideNavTooltip", "Skjules fra innholdsfortegnelse") : t("showNavTooltip", "Vises i innholdsfortegnelse")

    const iconSx = {
        color: "white"
    }

    const icon = value ? <BookmarkRemove sx={iconSx} /> : <BookmarkAdded sx={iconSx} />

    const handleToggle = () => onToggle(!value)

    return (
        <Tooltip
            title={tooltip}
        >
            <IconButton
                onClick={handleToggle}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}