import React from "react";
import { createUseStyles } from "react-jss";
import { Sections } from "./Sections";
import { Style } from "@mui/icons-material";
import { Button, Drawer } from "@mui/material";
import {
  useScrollyTellProps,
  useScrollytellTranslation,
} from "./scrollytellContext";
import { adminTheme } from "../App/App";

const useStyles = createUseStyles({
  sidebar: {
    width: "240px",
    overflowX: "hidden",
    overflowY: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#757575",
    height: "100%",
  },
});

export const Sidebar = ({ sections, currentSection, isOpen }) => {
  const t = useScrollytellTranslation();
  const { onTriggerSettings } = useScrollyTellProps();
  const classes = useStyles();
  return (
    <Drawer
      anchor="left"
      hideBackdrop={true}
      variant="persistent"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        flexShrink: 0,
        width: !isOpen ? "0" : "240px",
        transition: "width 150ms ease-in",
        [`& .MuiDrawer-paper`]: {
          position: "initial",
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      open={isOpen}
      transitionDuration={150}
    >
      <div className={classes.sidebar}>
        <Button
          onClick={() => onTriggerSettings()}
          sx={{
            backgroundColor: adminTheme.colors.primaryColor,
            color: "white",
            margin: "20px 0",
          }}
        >
          <Style />
          {t("sidebarOptionButtonText", "Fonter og farger")}
        </Button>
        <Sections sections={sections} currentSection={currentSection} />
      </div>
    </Drawer>
  );
};
