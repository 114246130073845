import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useDrag } from "react-dnd";
import {
  SET_SHOW_GRID_VIEW,
  useScrollytellDispatch,
} from "../Admin/scrollytellContext";
import { Tooltip } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";

const useStyles = createUseStyles(({ colors }) => ({
  dragButton: {
    cursor: "pointer",
    backgroundColor: colors.adminBlue,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    height: "auto",
    "&:hover": { color: `${colors.white}aa` },
    position: "absolute",
  },
  dragHandle: {
    cursor: "ew-resize",
  },
  start: {
    top: "calc(50% - 12px)",
    left: "-12px",
  },
  end: {
    top: "calc(50% - 12px)",
    right: "-12px",
  },
}));

export const DragButton = ({
  side = "start",
  containerId,
  title,
  startColumn,
  endColumn,
  onIsDraggingChange = () => {},
}) => {
  const dispatch = useScrollytellDispatch();

  const [{ isDragging }, ref] = useDrag(
    () => ({
      type: `${side}Column`,
      item: { containerId, prevValue: { startColumn, endColumn } },
      end: () => {
        dispatch({ type: SET_SHOW_GRID_VIEW, showGrid: false });
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  const classes = useStyles();

  useEffect(() => {
    if (isDragging) {
      dispatch({ type: SET_SHOW_GRID_VIEW, showGrid: true });
    }
    onIsDraggingChange(isDragging);
  }, [isDragging, onIsDraggingChange, dispatch]);

  return (
    <Tooltip
      className={`${classes.dragButton} ${
        side === "start" ? classes.start : classes.end
      }`}
      title={title}
    >
      <div ref={ref} className={classes.dragHandle}>
        <DragIndicator style={{ backgroundColor: isDragging ? "green" : "" }} />
      </div>
    </Tooltip>
  );
};
